<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Ajouter à un portefeuille</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="selectedList"
                  :items="lists"
                  autofocus
                  color="white"
                  item-text="label"
                  return-object
                  label="Nom du portefeuille"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small>mdi-briefcase-outline</v-icon>

                        &nbsp;&nbsp;{{ data.item.label }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Portefeuille
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="selectedList !== undefined">
              <v-col cols="4">
                <v-btn-toggle v-model="transaction.type" shaped mandatory>
                  <v-btn color="red accent-3" value="BUY"> Buy </v-btn>
                  <v-btn color="green accent-3" value="SELL"> Sell </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  type="date"
                  v-model="transaction.date"
                  label="Date"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="transaction.quantity"
                  label="Quantité"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="transaction.unitPrice"
                  label="Prix unitaire"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="transaction.fees"
                  label="Commission"
                ></v-text-field
              ></v-col> </v-row
            ><v-row>
              <v-col
                cols="12"
                sm="12"
                class="red darken-1 text-center"
                v-if="errMessage"
                ><span class="white--text"
                  >Error : {{ errMessage }}.</span
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="show = false"
          :disabled="syncLoading"
          >Annuler</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :disabled="syncLoading || !selectedList"
          ref="confirmButton"
          @click="save()"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddStockToPortfolioDialog",
  props: ["value", "stock"],

  data: () => ({
    errMessage: null,
    lists: [],
    selectedList: undefined,
    syncLoading: false,
    transaction: {
      type: "BUY",
      date: undefined,
      quantity: undefined,
      unitPrice: undefined,
      fees: undefined,
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    save: function () {
      if (this.transaction.date === undefined) {
        this.errMessage = "Date obligatoire !";
        return;
      }
      if (this.transaction.quantity === undefined) {
        this.errMessage = "Quantité obligatoire !";
        return;
      }
      if (this.transaction.unitPrice === undefined) {
        this.errMessage = "Prix unitaire obligatoire !";
        return;
      }

      this.syncLoading = true;
      this.transaction.equityUuid = this.stock.id;

      // @apiNote TransactionAPI
      this.$http
        .post(
          "lists/" + this.selectedList.id + "/transactions",
          this.transaction
        )
        .then(
          () => {
            this.$emit("updateLists", "PORTFOLIO", this.selectedList.label);
            this.syncLoading = false;
            this.show = false;
          },
          (error) => {
            console.error("ERROR : ", error.response.data);
            this.errMessage = error.response.data;
            this.syncLoading = false;
          }
        );
    },
    getLists() {
      // @apiNote ListAPI
      this.$http.get("lists").then((response) => {
        this.lists = response.data.filter(
          (oneList) => !oneList.trackerPortfolio
        );
      });
    },
  },
  mounted() {
    let now = new Date();
    this.transaction.date = now.toISOString().split("T")[0];
    this.getLists();
  },
};
</script>
