<template>
  <v-chart
    autoresize
    :init-options="initOptions"
    :option="options"
    :loading="loading"
    class="chart"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
]);

export default {
  name: "BarGraph",
  props: ["dataset", "currentPrice", "currency"],
  provide: {
    [THEME_KEY]: "dark",
  },
  computed: {},
  watch: {
    dataset: {
      handler() {
        this.loading = true;
        this.options.dataset.source = this.getDataset();
      },
      deep: true,
    },
  },
  data: function () {
    return {
      loading: true,
      loadingOptions: {
        text: "Loading...",
        color: "red",
        maskColor: "rgba(0, 0, 0, 0)",
      },
      rawHistory: [],
      initOptions: {
        renderer: "canvas",
      },
      options: {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let result = params[0].data.yield + " => <b>";
            result +=
              this.$options.filters.toFloat(params[0].data.iv) + this.currency;
            result += "</b>";

            return result;
          },
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}" + this.currency,
            },
          },
        ],

        series: [
          {
            animationDelay: 500,
            type: "bar",
            label: {
              show: true,
              rotate: 90,
              formatter: (params) => {
                return (
                  this.$options.filters.toFloat(params.data.iv) + this.currency
                );
              },
            },
            itemStyle: {
              color: (seriesIdx) => {
                return seriesIdx.value.color;
              },
            },
          },
        ],
        dataset: {
          dimensions: ["yield", "iv"],
          source: [],
        },
      },
    };
  },
  methods: {
    getDataset() {
      let ivPerYield = this.dataset.reduce((curRes, oneVal) => {
        let color = this.currentPrice
          ? oneVal.iv < this.currentPrice
            ? "red"
            : "green"
          : "red";

        let onePoint = {
          yield: this.$options.filters.toFloat(oneVal.yield, 100, "%", 0),
          iv: oneVal.iv,
          color,
        };
        curRes.push(onePoint);
        return curRes;
      }, []);

      // console.log("bargraph ivPerYield: ", ivPerYield);
      this.loading = false;

      return ivPerYield;
    },
  },
  mounted() {
    this.options.dataset.source = this.getDataset();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>