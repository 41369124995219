<template>
  <v-container>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      outlined
      timeout="2000"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col cols="6">
        <v-list v-if="integrationData">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Investing </v-list-item-title>
              <v-list-item-subtitle>
                {{ integrationData.investingId }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a
                  :href="integrationData.investingUrl"
                  style="text-decoration: none"
                  target="_blank"
                >
                  {{ integrationData.investingUrl }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <span :class="integrationData.yticker ? '' : 'red px-3'">
                  Yahoo
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <editable-field
                  v-model="newYTicker"
                  @input="updateIntegration('yticker', newYTicker)"
                ></editable-field>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a
                  :href="integrationData.yahooUrl"
                  style="text-decoration: none"
                  target="_blank"
                >
                  {{ integrationData.yahooUrl }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- Zone bourse ------------------------------------------------------------------------>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <span :class="integrationData.zoneBourseId ? '' : 'red px-3'">
                  Zonebourse
                </span>
                <v-btn
                  v-if="!syncInProgress"
                  icon
                  color="blue lighten-2"
                  @click="scrapZoneBourseId"
                  title="Get zonebourseId auto-magically"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
                <v-btn icon v-if="syncInProgress" color="blue lighten-2">
                  <v-progress-circular
                    indeterminate
                    :width="2"
                    :size="20"
                  ></v-progress-circular>
                </v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                <editable-field
                  v-model="newZoneBourseId"
                  @input="updateIntegration('zoneBourseId', newZoneBourseId)"
                ></editable-field>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a
                  :href="integrationData.zoneBourseUrl"
                  style="text-decoration: none"
                  target="_blank"
                >
                  {{ integrationData.zoneBourseUrl }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <span :class="integrationData.isin ? '' : 'red px-3'">
                  ISIN
                </span>
                <v-btn
                  icon
                  v-if="!syncInProgress"
                  color="blue lighten-2"
                  @click="scrapIsin"
                  :disabled="!integrationData.zoneBourseId"
                  title="Get ISIN auto-magically. Require zoneBourseId !"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
                <v-btn icon v-if="syncInProgress" color="blue lighten-2">
                  <v-progress-circular
                    indeterminate
                    :width="2"
                    :size="20"
                  ></v-progress-circular>
                </v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                <editable-field
                  v-model="newIsin"
                  @input="updateIntegration('isin', newIsin)"
                ></editable-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <span :class="integrationData.morningstarId ? '' : 'red px-3'">
                  Morningstar
                </span>
                <v-btn
                  v-if="!syncInProgress"
                  icon
                  color="blue lighten-2"
                  @click="scrapMorningstarId"
                  :disabled="!integrationData.isin"
                  title="Get morningstarId auto-magically. Require a valid isin"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
                <v-btn icon v-if="syncInProgress" color="blue lighten-2">
                  <v-progress-circular
                    indeterminate
                    :width="2"
                    :size="20"
                  ></v-progress-circular>
                </v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                <editable-field
                  v-model="newMorningstarId"
                  @input="updateIntegration('morningstarId', newMorningstarId)"
                ></editable-field>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a
                  :href="integrationData.morningstarUrl"
                  style="text-decoration: none"
                  target="_blank"
                >
                  {{ integrationData.morningstarUrl }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <span :class="integrationData.tradingViewId ? '' : 'red px-3'">
                  Tradingview
                </span>
                <v-btn
                  v-if="!syncInProgress"
                  icon
                  color="blue lighten-2"
                  @click="scrapTradingViewId"
                  :disabled="!integrationData.isin"
                  title="Get tradingViewId auto-magically. Require a valid isin"
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
                <v-btn icon v-if="syncInProgress" color="blue lighten-2">
                  <v-progress-circular
                    indeterminate
                    :width="2"
                    :size="20"
                  ></v-progress-circular>
                </v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                <editable-field
                  v-model="newTradingViewId"
                  @input="updateIntegration('tradingViewId', newTradingViewId)"
                ></editable-field>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a
                  :href="integrationData.tradingViewUrl"
                  style="text-decoration: none"
                  target="_blank"
                >
                  {{ integrationData.tradingViewUrl }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6" v-if="stock">
        <v-list>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Mise à jour automatique </v-list-item-title>
              <v-list-item-subtitle>
                <v-switch
                  dense
                  v-model="integrationEnabled"
                  hide-details
                  @change="updateIntegrationEnabled"
                  :label="integrationEnabled ? `Activé` : `Désactivé`"
                ></v-switch>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                Current ratios last update
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ stock.currentRatiosUpdatedAt | toDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                Last fundamental data update
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ stock.fundamentalDataUpdatedAt | toDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                Last fundamental data check
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ stock.fundamentalDataCheckedAt | toDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditableField from "@/components/commons/EditableField.vue";

export default {
  name: "IntegrationTab",
  props: ["uuid", "refresh"],
  watch: {
    uuid: function () {
      this.initTabForCurrentStock();
    },
    refresh: function () {
      this.initTabForCurrentStock();
    },
  },
  data: function () {
    return {
      integrationData: null,
      integrationEnabled: true,
      showSnackbar: false,
      snackbarText: undefined,
      snackbarColor: "success",
      syncInProgress: false,
      newYTicker: null,
      newZoneBourseId: null,
      newMorningstarId: null,
      newTradingViewId: null,
      newIsin: null,
      stock: null,
    };
  },
  computed: {},
  methods: {
    initTabForCurrentStock() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid).then((response) => {
        this.stock = response.data;
        this.integrationEnabled = this.stock.integrationEnabled;
        document.title = this.stock.name;
      });
      // @apiNote IntegrationAPI
      this.$http.get("integrations/" + this.uuid).then((response) => {
        this.integrationData = response.data;
        this.newYTicker = this.integrationData.yticker;
        this.newZoneBourseId = this.integrationData.zoneBourseId;
        this.newMorningstarId = this.integrationData.morningstarId;
        this.newTradingViewId = this.integrationData.tradingViewId;
        this.newIsin = this.integrationData.isin;
      });
    },
    scrapZoneBourseId() {
      this.syncInProgress = true;
      // @apiNote IntegrationAPI
      this.$http
        .post("integrations/" + this.uuid + "/scrap-zonebourse-id")
        .then(() => {
          this.httpSuccess("Recuperation avec succes du zoneBourseId");
        }, this.httpError)
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    scrapMorningstarId() {
      this.syncInProgress = true;

      // @apiNote IntegrationAPI
      this.$http
        .post("integrations/" + this.uuid + "/scrap-morningstar-id")
        .then(() => {
          this.httpSuccess("Recuperation avec succes du morningstarId");
        }, this.httpError)
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    scrapTradingViewId() {
      this.syncInProgress = true;

      // @apiNote IntegrationAPI
      this.$http
        .post("integrations/" + this.uuid + "/scrap-tradingview-id")
        .then(() => {
          this.httpSuccess("Recuperation avec succes du tradingViewId");
        }, this.httpError)
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    scrapIsin() {
      this.syncInProgress = true;

      // @apiNote IntegrationAPI
      this.$http
        .post("integrations/" + this.uuid + "/scrap-isin")
        .then(() => {
          this.httpSuccess("Recuperation avec succes de l'ISIN");
        }, this.httpError)
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    updateIntegrationEnabled() {
      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.uuid + "/integration-enabled", {
          newValue: this.integrationEnabled,
        })
        .then(() => {
          this.httpSuccess("Mise à jour de l'activation automatique");
        }, this.httpError)
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    updateIntegration: function (key, value) {
      console.log("updateIntegration: ", key, value);

      if (this.integrationData[key] === value) {
        console.log("Nothing to save, no change");
        return;
      }

      this.syncInProgress = true;

      // @apiNote IntegrationAPI
      this.$http
        .post("integrations/" + this.uuid, {
          key,
          value,
        })
        .then(
          () => {
            this.snackbarText =
              "Integration de " + key + " mise à jour avec succès !";
            this.showSnackbar = true;
            this.snackbarColor = "success";
            this.initTabForCurrentStock();
            this.$emit("reloadEquity");
          },
          (error) => {
            console.log("Error updating integration : ", error);
            this.snackbarText = error.response.data;
            this.showSnackbar = true;
            this.snackbarColor = "error";
          }
        )
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    httpError: function (error) {
      console.log("Error on API call : ", error);
      this.snackbarText = error.response.data;
      this.showSnackbar = true;
      this.snackbarColor = "error";
    },
    httpSuccess: function (message) {
      this.snackbarText = message;
      this.showSnackbar = true;
      this.snackbarColor = "success";
      this.initTabForCurrentStock();
      this.$emit("reloadEquity");
    },
  },
  mounted() {
    this.initTabForCurrentStock();
  },
  components: { EditableField },
};
</script>
<style lang="scss" scoped>
</style>

