<template>
  <div
    @focusout="
      valueLocal = $event.target.value;
      edit = false;
      $emit('input', valueLocal);
    "
    @mouseover="divHover = true"
    @mouseleave="divHover = false"
  >
    <v-text-field
      v-if="edit"
      :value="valueLocal"
      autofocus
      dense
      @keyup.enter.native="
        valueLocal = $event.target.value;
        edit = false;
        $emit('input', valueLocal);
      "
      v-focus=""
    ></v-text-field>
    <div v-else @click="edit = true">
      {{ valueLocal }}&nbsp;
      <v-icon small v-if="divHover"> mdi-pencil </v-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditableField",
  props: ["value"],
  data() {
    return {
      edit: false,
      valueLocal: this.value,
      divHover: false,
    };
  },
  watch: {
    value: function () {
      this.valueLocal = this.value;
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>