<template>
  <v-container>
    <v-row
      ><v-col><h1>Liste des portfolios</h1> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <portfolio-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import PortfolioTable from "@/components/portfolio-list/PortfolioTable.vue";

export default {
  name: "PortfolioList",
  watch: {},
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {},
  filters: {},
  components: { PortfolioTable },
};
</script>
