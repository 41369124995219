<template>
  <v-container v-if="curList">
    <v-row>
      <v-col>
        <h1>
          <v-icon large>mdi-binoculars</v-icon>&nbsp;&nbsp;{{ curList.label }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs
          v-model="curTab"
          background-color="transparent"
          grow
          color="white"
        >
          <v-tab href="#tab-1">Position courante</v-tab>
          <v-tab href="#tab-2"> Performance </v-tab>
          <v-tab href="#tab-3"> Qualité </v-tab>
        </v-tabs>
        <v-tabs-items v-model="curTab">
          <v-tab-item value="tab-1">
            <v-data-table
              v-if="curList"
              dense
              :headers="headers"
              :items="dataTableRows"
              :items-per-page="-1"
              :hide-default-footer="true"
              sort-by="name"
              class="elevation-1"
            >
              <!-- Name column -->
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  style="text-decoration: none"
                  :to="{
                    name: 'StockDetail',
                    params: { uuid: item.stockUuid },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>

              <!-- Portfolios column -->
              <template v-slot:[`item.portfolios`]="{ item }">
                <router-link
                  v-for="(onepf, i) in item.portfolios"
                  :key="i"
                  :to="{
                    name: 'PortfolioDetail',
                    params: { uuid: onepf.id },
                  }"
                  :title="onepf.label"
                >
                  <v-icon> mdi-briefcase-outline </v-icon>
                </router-link>
              </template>

              <!-- Fundamental Quote column -->
              <template v-slot:[`item.lastFundamentalScore.score`]="{ item }">
                <div
                  :class="
                    getDecreasingClass(item.lastFundamentalScore.score, 50, 60)
                  "
                >
                  {{ item.lastFundamentalScore.score | toFloat(1, " %", 0) }}
                </div>
              </template>

              <!-- Valuation Quote column -->
              <template
                v-slot:[`item.myCustomIndicators.valuationScore`]="{ item }"
              >
                <div
                  :class="
                    getDecreasingClass(
                      item.myCustomIndicators.valuationScore,
                      3,
                      4
                    )
                  "
                >
                  {{ item.myCustomIndicators.valuationScore }}/6
                </div>
              </template>

              <!-- Price column -->
              <template v-slot:[`item.lastPrice.price`]="{ item }">
                <v-tooltip bottom v-if="item.lastPrice">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      item.lastPrice.price | toFloat(1, " " + item.currency)
                    }}</span>
                  </template>
                  <span>{{ item.lastPrice.date | toDate }}</span>
                </v-tooltip>
              </template>

              <!-- Intrinsic value column -->
              <template
                v-slot:[`item.myCustomIndicators.intrinsicValue`]="{ item }"
              >
                {{
                  item.myCustomIndicators.intrinsicValue
                    | toFloat(1, " " + item.currency)
                }}
              </template>
              <template v-slot:[`item.diffIV`]="{ item }">
                <span :class="getClass(item.diffIV)">
                  {{ item.diffIV | toFloat(100, " %") }}
                </span>
              </template>
              <template v-slot:[`item.nextEarningDate`]="{ item }">
                {{ item.nextEarningDate | toDate }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  small
                  color="red"
                  icon
                  :loading="syncLoading"
                  :disabled="syncLoading"
                  @click="deleteStockFromWatchlist(item.stockUuid)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <!-- ------------------------------------------------------------------ Performance TAB -->
            <common-performance-tab
              :equityUuidList="equityUuidList"
              :indexEquities="curList.indexEquities"
              :portfolio="false"
            ></common-performance-tab>
          </v-tab-item>
          <!-- ------------------------------------------------------------------ Qualité TAB -->
          <v-tab-item value="tab-3">
            <quality-tab :equityUuidList="equityUuidList"></quality-tab>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import CommonPerformanceTab from "@/components/commons/PerformanceTab.vue";
import QualityTab from "@/components/watchlist-detail/QualityTab.vue";

export default {
  name: "WatclistDetail",
  props: ["uuid"],

  data: () => ({
    curList: undefined,
    syncLoading: false,
    curTab: undefined,
    headers: [
      { text: "Ticker", value: "yticker" },
      { text: "Name", value: "name" },
      { text: "PFs", value: "portfolios", sortable: false },
      { text: "Secteur", value: "sector" },
      { text: "Prix", value: "lastPrice.price", align: "right" },
      { text: "PER", value: "currentRatios.peTTM", align: "right" },
      {
        text: "Fundamental",
        value: "lastFundamentalScore.score",
        align: "center",
        class: "orange darken-2",
      },
      {
        text: "Valuation",
        value: "myCustomIndicators.valuationScore",
        align: "center",
        class: "blue darken-2",
      },
      {
        text: "IV",
        value: "myCustomIndicators.intrinsicValue",
        align: "right",
        class: "success",
      },
      {
        text: "Safety",
        value: "diffIV",
        align: "right",
        class: "success",
      },
      {
        text: "Next pub",
        value: "nextEarningDate",
        align: "right",
      },
      { text: "Actions", value: "actions" },
    ],
    refreshRows: 0,
  }),
  computed: {
    equityUuidList() {
      if (!this.curList || !this.curList.stocks) {
        return [];
      }
      return this.curList.stocks.map((oneStock) => oneStock.stockUuid);
    },
    dataTableRows() {
      let result = [...this.curList.stocks];
      this.refreshRows; // hack to force rows recalulation

      result.forEach((oneStock) => {
        oneStock.diffIV = this.getDiffIV(oneStock);
      });

      return result;
    },
  },
  methods: {
    /**
     * Return CSS class to apply depending of value
     * Value of error MUST be below Value of Warning
     */
    getDecreasingClass(val, val1, val2, class1 = "red", class2 = "orange") {
      return val < val1 ? class1 : val < val2 ? class2 : "";
    },

    getClass: function (value) {
      if (value > 0) {
        return "success--text text-subtitle-2";
      } else if (value < 0) {
        return "error--text text-subtitle-2";
      } else {
        return "text-subtitle-2";
      }
    },
    getDiffIV(stock) {
      if (
        !stock.lastPrice ||
        !stock.lastPrice.price ||
        !stock.myCustomIndicators.intrinsicValue
      ) {
        return undefined;
      }
      let iv = stock.myCustomIndicators.intrinsicValue;
      let result = iv / stock.lastPrice.price - 1;
      return result;
    },
    getData() {
      // @apiNote WatchlistAPI
      this.$http.get("watchlists/" + this.uuid).then((response) => {
        this.curList = response.data;
        document.title = "WL: " + this.curList.label;

        let promises = [];

        this.curList.stocks.forEach((oneStock) => {
          promises.push(
            // @apiNote ListAPI
            this.$http
              .get("lists/by-stock/" + oneStock.stockUuid)
              .then((response) => {
                oneStock.portfolios = response.data;
              })
          );
        });
        Promise.all(promises).then(() => {
          this.refreshRows++; // hack to force rows recalulation
        });
      });
    },

    deleteStockFromWatchlist(stockUuid) {
      this.syncLoading = true;
      // @apiNote WatchlistAPI
      this.$http
        .delete("watchlists/" + this.uuid + "/stocks/" + stockUuid)
        .then(() => {
          this.syncLoading = false;
          this.getData();
        });
    },
  },
  mounted() {
    this.getData();
  },
  filters: {},
  components: {
    CommonPerformanceTab,
    QualityTab,
  },
};
</script>
<style lang="scss">
.v-tabs-items {
  padding-top: 15px;
}
</style>
