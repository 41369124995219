<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <!-- ------------------------------------------------------------ EVENTS -->
        <h2>Events</h2>
        <v-data-table
          v-if="uuid"
          dense
          :headers="eventHeaders"
          :items="eventTableRows"
          :items-per-page="-1"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <!-- Date column -->
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | toDate }}
          </template>
          <!-- Type column -->
          <template v-slot:[`item.type`]="{ item }">
            <span :class="getEventTypeBg(item.type)">
              {{ item.type }}
            </span>
          </template>
          <!-- Message column -->
          <template v-slot:[`item.message`]="{ item }">
            <span v-html="item.message"> </span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <!-- ------------------------------------------------------------ POSITIONS -->
        <h2>Positions</h2>
        <v-simple-table dense class="mb-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Portfolio</th>
                <th class="text-left">Qté</th>
                <th class="text-left">PRU</th>
                <th class="text-left">Invest</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="position in positions" :key="position.id">
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="{
                      name: 'PortfolioDetail',
                      params: { uuid: position.portfolioId },
                    }"
                  >
                    {{ position.portfolioName }}
                  </router-link>
                </td>
                <td>{{ position.position.quantity | toFloat }}</td>
                <td>
                  {{ position.position.ucp | toFloat(1, stock.currency) }}
                </td>
                <td>
                  {{
                    (position.position.quantity * position.position.ucp)
                      | toFloat(1, stock.currency)
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- ------------------------------------------------------------ TRANSACTIONS -->
        <h2>Transactions</h2>
        <v-data-table
          v-if="uuid"
          dense
          :headers="transactionHeaders"
          :items="transactionTableRows"
          :items-per-page="-1"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <!-- Date column -->
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | toDate }}
          </template>
          <!-- Type column -->
          <template v-slot:[`item.type`]="{ item }">
            <span :class="getTxTypeBg(item.type)">
              {{ item.type }}
            </span>
          </template>
          <!-- UC column -->
          <template v-slot:[`item.unitPrice`]="{ item }">
            {{ item.unitPrice | toFloat(1, " " + stock.currency) }}
          </template>
          <!-- Total column -->
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total | toFloat(1, " " + stock.currency) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HistoryTab",
  props: ["uuid", "refresh"],
  watch: {
    uuid: function () {
      this.initTabForCurrentStock();
    },
    refresh: function () {
      this.initTabForCurrentStock();
    },
  },
  data: function () {
    return {
      rawEvents: [],
      stock: null,
      rawTransactions: [],
      positions: [],
      eventHeaders: [
        { text: "Date", value: "date" },
        { text: "Type", value: "type" },
        { text: "Message", value: "message" },
      ],
      transactionHeaders: [
        { text: "Portfolio", value: "listName" },
        { text: "Date", value: "date" },
        { text: "Type", value: "type" },
        { text: "Qté", value: "quantity" },
        { text: "UC", value: "unitPrice", align: "right" },
        { text: "Total", value: "total", align: "right" },
      ],
    };
  },
  computed: {
    eventTableRows() {
      let result = [...this.rawEvents];
      return result;
    },
    transactionTableRows() {
      let result = [...this.rawTransactions];
      return result;
    },
  },
  methods: {
    loadEvents() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid + "/events").then((response) => {
        this.rawEvents = response.data;
      });
    },
    loadTransactions() {
      // @apiNote StockAPI
      this.$http
        .get("stocks/" + this.uuid + "/transactions")
        .then((response) => {
          // This API returns also watchlist related Tx => to filter
          this.rawTransactions = response.data.filter((oneTx) => oneTx.type);
        });
    },
    loadPositions() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid + "/positions").then((response) => {
        this.positions = response.data;
      });
    },
    getData() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid).then((response) => {
        this.stock = response.data;
        document.title = this.stock.name;
      });
    },
    initTabForCurrentStock() {
      this.getData();
      this.loadEvents();
      this.loadPositions();
      this.loadTransactions();
    },
    getEventTypeBg(type) {
      switch (type) {
        case "PRICE_BELOW_STOP_LOSS":
          return "orange";
        case "PRICE_ABOVE_TAKE_PROFIT":
          return "green";
        case "PRICE_CROSS_UP_SMA200":
          return "blue";
        case "EARNING_DATE_DAY":
          return "blue-grey";
        case "FUNDAMENTAL_DATA_UPDATED":
          return "purple";
        case "FUNDAMENTAL_SCORE_UPDATED":
          return "cyan";
        case "VALUATION_SCORE_UPDATED":
          return "brown";
        default:
          return "";
      }
    },
    getTxTypeBg(type) {
      switch (type) {
        case "DIVIDEND":
          return "blue";
        case "BUY":
          return "green";
        case "SELL":
          return "red";
        default:
          return "";
      }
    },
  },
  mounted() {
    this.initTabForCurrentStock();
  },
};
</script>
<style lang="scss" scoped>
</style>

