var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('v-card',[_c('v-card-title',[_c('stock-filters',{on:{"updateFilters":_vm.updateFilters}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.stocks,"items-per-page":20,"loading":_vm.syncLoading,"options":_vm.options,"server-items-length":_vm.totalCount,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, -1],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'StockDetail', params: { uuid: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.sector`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.sector)+"   "),_c('span',{staticClass:"caption text--disabled"},[_vm._v(_vm._s(item.industry))])]}},{key:`item.portfolios`,fn:function({ item }){return _vm._l((item.portfolios),function(onepf,i){return _c('router-link',{key:i,attrs:{"to":{
            name: 'PortfolioDetail',
            params: { uuid: onepf.id },
          },"title":onepf.label}},[_c('v-icon',[_vm._v(" mdi-briefcase-outline ")])],1)})}},{key:`item.watchlists`,fn:function({ item }){return _vm._l((item.watchlists),function(onewl,i){return _c('router-link',{key:i,attrs:{"to":{
            name: 'WatchlistDetail',
            params: { uuid: onewl.id },
          },"title":onewl.label}},[_c('v-icon',[_vm._v(" mdi-binoculars ")])],1)})}},{key:`item.dividendYield`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.dividendYield,100, "%"))+" ")]}},{key:`item.fundamentalScore`,fn:function({ item }){return [(item.fundamentalScore)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.fundamentalScore,1, " %", 0))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Last update on "+_vm._s(_vm._f("toDate")(item.fundamentalScoreLastUpdated))+" ")])]):_vm._e()]}},{key:`item.valuationScore`,fn:function({ item }){return [(item.valuationScore)?_c('span',[_vm._v(" "+_vm._s(item.valuationScore)+"/6 ")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary","icon":"","loading":_vm.syncLoading,"disabled":_vm.syncLoading},on:{"click":function($event){return _vm.syncFromCloud(item.id)}}},[_c('v-icon',[_vm._v("mdi-cloud-sync")])],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }