<template>
  <v-data-table
    dense
    :headers="tableHeaders"
    :items="dataTableRows"
    :items-per-page="-1"
    :hide-default-footer="true"
    :loading="isLoading"
    sort-by="name"
    class="elevation-1"
  >
    <template slot="body.prepend" v-if="indexLoaded">
      <tr
        class="blue-grey darken-4 color-bg"
        v-for="oneIndexUuid in indexEquities"
        :key="oneIndexUuid"
      >
        <td>
          {{ refIndexByUuid[oneIndexUuid].yticker }}
        </td>
        <td v-if="portfolio && portfolio.trackerPortfolio">
          {{ refIndexByUuid[oneIndexUuid].customName }}
        </td>
        <td class="text-subtitle">
          <strong>{{ refIndexByUuid[oneIndexUuid].name }}</strong>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td
          :class="
            getPrependClass(refIndexByUuid[oneIndexUuid].performance.perf1d)
          "
        >
          {{
            refIndexByUuid[oneIndexUuid].performance.perf1d | toFloat(100, "%")
          }}
        </td>
        <td
          :class="
            getPrependClass(refIndexByUuid[oneIndexUuid].performance.perf1w)
          "
        >
          {{
            refIndexByUuid[oneIndexUuid].performance.perf1w | toFloat(100, "%")
          }}
        </td>
        <td
          :class="
            getPrependClass(refIndexByUuid[oneIndexUuid].performance.perf1m)
          "
        >
          {{
            refIndexByUuid[oneIndexUuid].performance.perf1m | toFloat(100, "%")
          }}
        </td>
        <td
          :class="
            getPrependClass(refIndexByUuid[oneIndexUuid].performance.perf3m)
          "
        >
          {{
            refIndexByUuid[oneIndexUuid].performance.perf3m | toFloat(100, "%")
          }}
        </td>
        <td
          :class="
            getPrependClass(refIndexByUuid[oneIndexUuid].performance.perfYtd)
          "
        >
          {{
            refIndexByUuid[oneIndexUuid].performance.perfYtd | toFloat(100, "%")
          }}
        </td>
      </tr>
      <tr class="blue-grey darken-3 color-bg" v-if="portfolio">
        <td></td>
        <td v-if="portfolio.trackerPortfolio"></td>
        <td class="text-h6">
          <strong>{{ portfolio.label }}</strong>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td :class="getPrependClass(portfolio.performance.perf1D)">
          {{ portfolio.performance.perf1D | toFloat(100, "%") }}
        </td>
        <td :class="getPrependClass(portfolio.performance.perf1W)">
          {{ portfolio.performance.perf1W | toFloat(100, "%") }}
        </td>
        <td :class="getPrependClass(portfolio.performance.perf1M)">
          {{ portfolio.performance.perf1M | toFloat(100, "%") }}
        </td>
        <td :class="getPrependClass(portfolio.performance.perf3M)">
          {{ portfolio.performance.perf3M | toFloat(100, "%") }}
        </td>
        <td :class="getPrependClass(portfolio.performance.perfYTD)">
          {{ portfolio.performance.perfYTD | toFloat(100, "%") }}
        </td>
      </tr>
    </template>
    <!-- ********************************************************** -->
    <!-- Name column -->
    <template v-slot:[`item.name`]="{ item }">
      <router-link
        v-if="!item.tracker"
        style="text-decoration: none"
        :title="item.name"
        :to="{
          name: 'StockDetail',
          params: { uuid: item.id },
        }"
      >
        {{ item.name }}
      </router-link>
      <router-link
        v-if="item.tracker"
        style="text-decoration: none"
        :title="item.name"
        :to="{
          name: 'TrackerDetail',
          params: { uuid: item.id },
        }"
      >
        {{ item.name }}
      </router-link>
    </template>

    <!-- ********************************************************** -->
    <!-- lastClosePrice column -->
    <template v-slot:[`item.performance.lastClosePrice`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{
            item.performance.lastClosePrice
              | toFloat(1, " " + getCurrencySymbol(item))
          }}</span>
        </template>
        <span>{{ item.performance.lastCloseDate | toDate }}</span>
      </v-tooltip>
    </template>

    <!-- ********************************************************** -->
    <!-- minMax52w column -->
    <template v-slot:[`item.performance.minMax52w`]="{ item }">
      <span v-if="item.performance.min52w">
        {{
          item.performance.min52w | toFloat(1, " " + getCurrencySymbol(item))
        }}
        -
        {{
          item.performance.max52w | toFloat(1, " " + getCurrencySymbol(item))
        }}
      </span>
    </template>

    <!-- ********************************************************** -->
    <!-- diffMax52w column -->
    <template v-slot:[`item.performance.diffMax52w`]="{ item }">
      <span :class="getDiffMaxClass(item.performance.diffMax52w)">
        {{ item.performance.diffMax52w | toFloat(100, "%") }}
      </span>
    </template>
    <!-- ********************************************************** -->
    <!-- perf1d column -->
    <template v-slot:[`item.performance.perf1d`]="{ item }">
      <span :class="getClass(item.performance.perf1d)">
        {{ item.performance.perf1d | toFloat(100, "%") }}
      </span>
    </template>
    <!-- ********************************************************** -->
    <!-- perf1w column -->
    <template v-slot:[`item.performance.perf1w`]="{ item }">
      <span :class="getClass(item.performance.perf1w)">
        {{ item.performance.perf1w | toFloat(100, "%") }}
      </span>
    </template>
    <!-- ********************************************************** -->
    <!-- perf1m column -->
    <template v-slot:[`item.performance.perf1m`]="{ item }">
      <span :class="getClass(item.performance.perf1m)">
        {{ item.performance.perf1m | toFloat(100, "%") }}
      </span>
    </template>
    <!-- ********************************************************** -->
    <!-- perf3m column -->
    <template v-slot:[`item.performance.perf3m`]="{ item }">
      <span :class="getClass(item.performance.perf3m)">
        {{ item.performance.perf3m | toFloat(100, "%") }}
      </span>
    </template>
    <!-- ********************************************************** -->
    <!-- perfYtd column -->
    <template v-slot:[`item.performance.perfYtd`]="{ item }">
      <span :class="getClass(item.performance.perfYtd)">
        {{ item.performance.perfYtd | toFloat(100, "%") }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "PerformanceTab",
  props: [
    "equityUuidList", // Liste des equities a afficher
    "portfolio", // Object portfolio si c'est le cas
    "indexEquities", // UUids des trackers de reference
  ],
  watch: {},
  data: function () {
    return {
      refreshTable: 0,
      equityByUuid: {},
      isLoading: true,
      indexLoaded: false,
      refIndexByUuid: {},
    };
  },
  computed: {
    tableHeaders() {
      let headers = [{ text: "Ticker", value: "yticker" }];
      if (this.portfolio.trackerPortfolio) {
        headers.push({ text: "Custom", value: "customName" });
      }
      headers = headers.concat([
        {
          text: "Name",
          value: "name",
          class: "text-truncate",
          cellClass: "text-truncate",
        },
        {
          text: "lastClose",
          value: "performance.lastClosePrice",
          align: "right",
        },
        {
          text: "min/max 52W",
          value: "performance.minMax52w",
          align: "right",
          class: "blue",
          sortable: false,
        },
        {
          text: "diff max 52W",
          value: "performance.diffMax52w",
          align: "right",
          class: "blue",
        },
        {
          text: "perf 1D",
          value: "performance.perf1d",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "perf 1W",
          value: "performance.perf1w",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "perf 1M",
          value: "performance.perf1m",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "perf 3M",
          value: "performance.perf3m",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "perf YTD",
          value: "performance.perfYtd",
          align: "right",
          class: "orange darken-2",
        },
      ]);
      return headers;
    },

    dataTableRows() {
      if (this.isLoading) {
        return [];
      }

      let result = Object.values(this.equityByUuid);

      this.refreshTable;

      result.forEach((oneEquity) => {
        if (
          oneEquity.performance &&
          oneEquity.performance.max52w &&
          oneEquity.performance.min52w
        ) {
          let interval =
            oneEquity.performance.max52w - oneEquity.performance.min52w;
          let diffMax =
            oneEquity.performance.max52w - oneEquity.performance.lastClosePrice;
          oneEquity.performance.diffMax52w = diffMax / interval;
        }
      });

      return result;
    },
  },
  methods: {
    getCurrencySymbol(equity) {
      if (equity.currency === "EUR") {
        return "€";
      } else if (equity.currency === "USD") {
        return "$";
      }
      return equity.currency;
    },
    getPercentage(oldVal, newVal) {
      if (!oldVal || !newVal) {
        return null;
      }
      return (newVal - oldVal) / oldVal;
    },

    getClass: function (value) {
      return this.getColorClass(value) + " text-subtitle-2";
    },
    getDiffMaxClass: function (value) {
      let clazz =
        value < 0.05 ? "error--text" : value < 0.15 ? "orange--text" : "";
      return clazz;
    },
    getPrependClass: function (value) {
      return this.getColorClass(value) + " text-h6 text-right";
    },
    getColorClass: function (value) {
      if (value > 0) {
        return "success--text";
      } else if (value < 0) {
        return "error--text";
      } else {
        return "";
      }
    },
    // ----------------------------------------------------------------
    // Call API
    getData: function () {
      this.isLoading = true;
      var p = Promise.resolve();

      if (this.equityUuidList) {
        let urltoRequest = "stocks/by-batch";
        if (this.portfolio && this.portfolio.trackerPortfolio) {
          urltoRequest = "trackers/by-batch";
        }
        p = p.then(() => {
          // @apinote StockBatchAPI/TrackerBatchAPI
          return this.$http
            .post(urltoRequest, this.equityUuidList)
            .then((response) => {
              this.equityByUuid = response.data;
            });
        });
        p = p.then(() => {
          // @apinote StockBatchAPI/TrackerBatchAPI
          return this.$http
            .post(urltoRequest + "/performance", this.equityUuidList)
            .then((response) => {
              this.equityUuidList.forEach((oneEquity) => {
                this.equityByUuid[oneEquity].performance =
                  response.data[oneEquity];
              });
            });
        });
      }
      if (this.indexEquities) {
        p = p.then(() => {
          // @apinote TrackerBatchAPI
          return this.$http
            .post("trackers/by-batch", this.indexEquities)
            .then((response) => {
              this.refIndexByUuid = response.data;
            });
        });
        p = p.then(() => {
          // @apinote TrackerBatchAPI
          return this.$http
            .post("trackers/by-batch/performance", this.indexEquities)
            .then((response) => {
              this.indexEquities.forEach((oneEquity) => {
                this.refIndexByUuid[oneEquity].performance =
                  response.data[oneEquity];
              });
            });
        });
      }
      p.then(() => {
        this.isLoading = false;
        this.refreshTable++;
        this.indexLoaded = true;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
