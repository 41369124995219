<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Ajouter une transaction</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" align="center">
                <v-btn-toggle
                  v-model="transaction.type"
                  dense
                  mandatory
                  color="orange"
                >
                  <v-btn value="BUY">Achat</v-btn>
                  <v-btn value="SELL">Vente</v-btn>
                  <v-btn value="DIVIDEND">Dividende</v-btn>
                  <v-btn value="CASH" v-if="list.cashManagementEnabled"
                    >Cash</v-btn
                  >
                  <v-btn value="FEE" v-if="list.cashManagementEnabled"
                    >Frais</v-btn
                  >
                </v-btn-toggle>
              </v-col>
            </v-row>

            <!-- BUY / SELL -->
            <v-row
              v-if="transaction.type === 'BUY' || transaction.type === 'SELL'"
            >
              <!-- Select stock/tracker -->
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedEquity"
                  :items="equities"
                  color="white"
                  return-object
                  item-text="name"
                  clearable
                  :filter="filterEquity"
                  :label="
                    list.trackerPortfolio === true
                      ? 'Tracker concerné'
                      : 'Action concernée'
                  "
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="data.item.isin">
                          {{ data.item.isin }} /
                        </span>
                        {{ data.item.yticker }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  type="date"
                  v-model="transaction.date"
                  label="Date"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="transaction.quantity"
                  label="Quantité"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="transaction.unitPrice"
                  label="Prix unitaire"
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  type="number"
                  v-model="transaction.fees"
                  label="Commission"
                ></v-text-field
              ></v-col>
            </v-row>

            <!-- DIVIDEND -->
            <v-row v-if="transaction.type === 'DIVIDEND'">
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedEquity"
                  :items="equities"
                  color="white"
                  return-object
                  item-text="name"
                  clearable
                  :label="
                    list.trackerPortfolio === true
                      ? 'Tracker concerné'
                      : 'Action concernée'
                  "
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.isin }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete></v-col
              >
              <v-col cols="6">
                <v-text-field
                  type="date"
                  v-model="transaction.date"
                  label="Date"
                ></v-text-field
              ></v-col>
              <v-col cols="6">
                <v-text-field
                  type="number"
                  v-model="transaction.total"
                  label="Montant"
                ></v-text-field
              ></v-col>
            </v-row>

            <!-- CASH -->
            <v-row
              v-if="transaction.type === 'CASH' || transaction.type === 'FEE'"
            >
              <v-col cols="6">
                <v-text-field
                  type="date"
                  v-model="transaction.date"
                  label="Date"
                ></v-text-field
              ></v-col>
              <v-col cols="6">
                <v-text-field
                  type="number"
                  v-model="transaction.total"
                  label="Montant"
                ></v-text-field
              ></v-col>
            </v-row>

            <!-- Error line -->
            <v-row>
              <v-col
                cols="12"
                sm="12"
                class="red darken-1 text-center"
                v-if="errMessage"
                ><span class="white--text"
                  >Error : {{ errMessage }}</span
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <!-- Buttons line -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="show = false"
          :disabled="syncLoading"
          >Annuler</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :disabled="syncLoading"
          ref="confirmButton"
          @click="save()"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddSingleTransactionDialog",
  props: ["value", "list"],

  data: function () {
    return {
      errMessage: null,
      equities: [],
      selectedEquity: undefined,
      syncLoading: false,
      transaction: {
        type: "BUY",
        date: undefined,
        quantity: undefined,
        unitPrice: undefined,
        fees: undefined,
        total: undefined,
        equityUuid: undefined,
      },
    };
  },
  watch: {
    value: function (val) {
      // Load stock list on dialog opening
      if (val) {
        if (this.list.trackerPortfolio === false) {
          this.getStocks();
        } else {
          this.getTrackers();
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) {
          // Fermeture de la dialog
          this.resetDialogSettings();
        }
        this.$emit("input", value);
      },
    },
  },
  methods: {
    filterEquity(item, queryText, itemText) {
      // console.log("filterEquity : ", item, queryText, itemText);
      if (
        itemText &&
        queryText &&
        itemText.toUpperCase().includes(queryText.toUpperCase())
      ) {
        return true;
      }
      if (
        item &&
        item.yticker &&
        queryText &&
        item.yticker.toUpperCase().includes(queryText.toUpperCase())
      ) {
        return true;
      }
      if (
        item &&
        item.isin &&
        queryText &&
        item.isin.toUpperCase().includes(queryText.toUpperCase())
      ) {
        return true;
      }
      return false;
    },
    resetDialogSettings() {
      let now = new Date();

      this.transaction = {
        type: "BUY",
        date: now.toISOString().split("T")[0],
        quantity: undefined,
        unitPrice: undefined,
        fees: undefined,
        total: undefined,
        equityUuid: undefined,
      };
      this.selectedEquity = undefined;
      this.errMessage = undefined;
    },
    save: function () {
      console.log("save : ", this.transaction);

      if (this.empty(this.transaction.date)) {
        this.errMessage = "Date obligatoire !";
        return;
      }
      if (["BUY", "SELL", "DIVIDEND"].includes(this.transaction.type)) {
        if (this.empty(this.selectedEquity)) {
          this.errMessage = this.list.trackerPortfolio
            ? "Tracker obligatoire !"
            : "Action obligatoire !";
          return;
        } else {
          this.transaction.equityUuid = this.selectedEquity.id;
        }
      }

      if (["BUY", "SELL"].includes(this.transaction.type)) {
        if (this.empty(this.transaction.quantity)) {
          this.errMessage = "Quantité obligatoire !";
          return;
        }
        if (this.empty(this.transaction.unitPrice)) {
          this.errMessage = "Prix unitaire obligatoire !";
          return;
        }
      }
      if (["CASH", "FEE", "DIVIDEND"].includes(this.transaction.type)) {
        if (this.empty(this.transaction.total)) {
          this.errMessage = "Montant obligatoire !";
          return;
        }
      }

      if (this.empty(this.transaction.fees)) {
        this.transaction.fees = 0;
      }

      this.syncLoading = true;
      // @apinote TransactionAPI
      this.$http
        .post("lists/" + this.list.id + "/transactions", this.transaction)
        .then(
          () => {
            this.syncLoading = false;
            this.show = false;
            this.$emit("updateTransactionList");
          },
          (error) => {
            console.error("ERROR : ", error.response.data);
            this.errMessage = error.response.data;
            this.syncLoading = false;
          }
        );
    },
    empty(value) {
      if (
        value === undefined ||
        (value instanceof String && value.trim() === "")
      ) {
        return true;
      }
      return false;
    },
    getStocks() {
      // @apinote StockAPI
      this.$http.get("stocks").then((response) => {
        this.equities = response.data;
      });
    },
    getTrackers() {
      // @apinote TrackerAPI
      this.$http.get("trackers").then((response) => {
        this.equities = response.data;
      });
    },
  },
  mounted() {
    this.resetDialogSettings();
  },
};
</script>
