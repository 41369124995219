import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import StockList from '../views/StockList.vue'
import PortfolioList from '../views/PortfolioList.vue'
import WatchlistList from '../views/WatchlistList.vue'
import StockDetail from '../views/StockDetail.vue'
import OverviewTab from '@/components/stock-detail/OverviewTab.vue'
import QualityTab from '@/components/stock-detail/QualityTab.vue'
import FinancialTab from '@/components/stock-detail/FinancialTab.vue'
import ValuationTab from '@/components/stock-detail/ValuationTab.vue'
import IntegrationTab from '@/components/stock-detail/IntegrationTab.vue'
import HistoryTab from '@/components/stock-detail/HistoryTab.vue'
import PortfolioDetail from '../views/PortfolioDetail.vue'
import WatchlistDetail from '../views/WatchlistDetail.vue'
import ManagementPage from '../views/ManagementPage.vue'
import TrackerList from '../views/TrackerList.vue'
import TrackerDetail from '../views/TrackerDetail.vue'
import EventList from '../views/EventList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/stocks/:uuid',
    component: StockDetail,
    props: true,
    children: [
      {
        path: "",
        component: OverviewTab,
        name: 'StockDetail',
        props: true
      },
      {
        path: "quality",
        component: QualityTab,
        name: 'QualityTab',
        props: true
      }, {
        path: "financial",
        component: FinancialTab,
        name: 'FinancialTab',
        props: true
      }, {
        path: "valuation",
        component: ValuationTab,
        name: 'ValuationTab',
        props: true
      }, {
        path: "history",
        component: HistoryTab,
        name: 'HistoryTab',
        props: true
      }, {
        path: "integration",
        component: IntegrationTab,
        name: 'IntegrationTab',
        props: true
      }
    ]
  },
  {
    path: '/stocks',
    name: 'StockList',
    component: StockList
  },
  {
    path: '/trackers',
    name: 'TrackerList',
    component: TrackerList
  },
  {
    path: '/trackers/:uuid',
    name: 'TrackerDetail',
    component: TrackerDetail,
    props: true
  },
  {
    path: '/portfolios',
    name: 'PortfolioList',
    component: PortfolioList
  },
  {
    path: '/watchlists',
    name: 'WatchlistList',
    component: WatchlistList
  },
  {
    path: '/watchlists/:uuid',
    name: 'WatchlistDetail',
    component: WatchlistDetail,
    props: true
  },
  {
    path: '/portfolios/:uuid',
    name: 'PortfolioDetail',
    component: PortfolioDetail,
    props: true
  },
  {
    path: '/management',
    name: 'ManagementPage',
    component: ManagementPage,
    props: true
  },
  {
    path: '/events',
    name: 'EventList',
    component: EventList,
    props: true
  },
  {
    path: '/about',
    name: 'AboutPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = "Stock Screener App";
  // console.log('router to:', to);
  next();
})

export default router
