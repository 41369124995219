<template>
  <div class="home">
    <v-card>
      <v-card-title>
        <stock-filters @updateFilters="updateFilters"></stock-filters>
      </v-card-title>

      <!-- ----------------------------------------------------------------------- -->
      <!-- Data table -->
      <!-- ----------------------------------------------------------------------- -->
      <v-data-table
        dense
        :headers="headers"
        :items="stocks"
        :items-per-page="20"
        :loading="syncLoading"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, -1],
        }"
        class="elevation-1"
      >
        <!-- name column ------------------------------------- -->
        <template v-slot:[`item.name`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="{ name: 'StockDetail', params: { uuid: item.id } }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- sector column ------------------------------------- -->
        <template v-slot:[`item.sector`]="{ item }">
          {{ item.sector }}&nbsp;&nbsp;
          <span class="caption text--disabled">{{ item.industry }}</span>
        </template>

        <!-- portfolios column ------------------------------------- -->
        <template v-slot:[`item.portfolios`]="{ item }">
          <router-link
            v-for="(onepf, i) in item.portfolios"
            :key="i"
            :to="{
              name: 'PortfolioDetail',
              params: { uuid: onepf.id },
            }"
            :title="onepf.label"
          >
            <v-icon> mdi-briefcase-outline </v-icon>
          </router-link>
        </template>

        <!-- Watchlists column ------------------------------------- -->
        <template v-slot:[`item.watchlists`]="{ item }">
          <router-link
            v-for="(onewl, i) in item.watchlists"
            :key="i"
            :to="{
              name: 'WatchlistDetail',
              params: { uuid: onewl.id },
            }"
            :title="onewl.label"
          >
            <v-icon> mdi-binoculars </v-icon>
          </router-link>
        </template>

        <!-- dividendYield column ------------------------------------- -->
        <template v-slot:[`item.dividendYield`]="{ item }">
          {{ item.dividendYield | toFloat(100, "%") }}
        </template>

        <!-- fundamentalScore column ------------------------------------- -->
        <template v-slot:[`item.fundamentalScore`]="{ item }">
          <v-tooltip bottom v-if="item.fundamentalScore">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.fundamentalScore | toFloat(1, " %", 0) }}
              </span>
            </template>
            <span>
              Last update on {{ item.fundamentalScoreLastUpdated | toDate }}
            </span>
          </v-tooltip>
        </template>

        <!-- valuationScore column ------------------------------------- -->
        <template v-slot:[`item.valuationScore`]="{ item }">
          <span v-if="item.valuationScore"> {{ item.valuationScore }}/6 </span>
        </template>

        <!-- actions column ------------------------------------- -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            color="primary"
            icon
            :loading="syncLoading"
            :disabled="syncLoading"
            @click="syncFromCloud(item.id)"
          >
            <v-icon>mdi-cloud-sync</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import StockFilters from "@/components/stock-list/StockFilters.vue";

export default {
  name: "StockList",
  watch: {
    options: {
      // handler(newV, oldV) {
      handler() {
        // console.log("watch options OLD: ", oldV);
        // console.log("watch options NEW: ", newV);
        this.getData();
      },
      deep: true,
    },
  },
  data: () => ({
    search: undefined,
    headers: [
      { text: "Name", value: "name" },
      { text: "Sector", value: "sector" },
      { text: "PER", value: "peTTM" },
      { text: "Yield", value: "dividendYield" },
      { text: "Quality", value: "fundamentalScore" },
      { text: "Val score", value: "valuationScore" },
      { text: "Last Pub", value: "lastEarningDate" },
      { text: "Next Pub", value: "nextEarningDate" },
      { text: "PF", value: "portfolios", sortable: false },
      { text: "WL", value: "watchlists", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    syncLoading: false,
    totalCount: 0,
    options: {},
    refreshRows: 0,
    stocks: [],
    sectors: [],
    selectedSector: null,
    selectedIndustry: null,
    selectedPeriod: null,
    selectedQualityScore: null,
  }),
  computed: {},
  methods: {
    updateFilters(newFilters) {
      // console.log("updateFilters: ", newFilters);
      this.selectedSector = newFilters.sector.value
        ? newFilters.sector.value
        : null;
      this.selectedIndustry = newFilters.industry.value
        ? newFilters.industry.value
        : null;
      this.selectedPeriod = newFilters.earnings.value
        ? newFilters.earnings.value
        : null;
      this.selectedQualityScore = newFilters.quality.value
        ? newFilters.quality.value
        : null;
      this.search = newFilters.query.value ? newFilters.query.value : null;
      this.getData();
    },
    getData() {
      this.syncLoading = true;

      // console.log("getData options: ", this.options);

      let queryObj = this.getSearchRequestObj();

      // @apiNote StockAPI
      this.$http.post("stocks/search", queryObj).then((response) => {
        this.totalCount = response.data.stockCount;
        this.stocks = response.data.stocks;

        let stockIds = this.stocks.map((oneS) => oneS.id);
        // @apiNote ListAPI
        this.$http.post("lists/by-stocks", stockIds).then((response) => {
          this.stocks.forEach((oneStock) => {
            oneStock.portfolios = response.data[oneStock.id];
          });
          // @apiNote WatchlistAPI
          this.$http.post("watchlists/by-stocks", stockIds).then((response) => {
            this.stocks.forEach((oneStock) => {
              oneStock.watchlists = response.data[oneStock.id];
            });
            this.syncLoading = false;
          });
        });
      });
    },
    getSearchRequestObj() {
      let queryObj = {};
      if (this.search) {
        queryObj.query = this.search;
      }
      if (this.selectedSector) {
        queryObj.sector = this.selectedSector;
      }
      if (this.selectedIndustry) {
        queryObj.industry = this.selectedIndustry;
      }
      if (this.selectedPeriod) {
        queryObj.period = this.selectedPeriod;
      }
      if (this.selectedQualityScore) {
        queryObj.quality = this.selectedQualityScore;
      }
      if (this.options.page) {
        queryObj.pageIdx = this.options.page;
      }
      if (this.options.itemsPerPage) {
        if (this.options.itemsPerPage == -1) {
          queryObj.pageSize = this.totalCount;
        } else {
          queryObj.pageSize = this.options.itemsPerPage;
        }
      }
      if (this.options.sortBy && this.options.sortBy.length > 0) {
        queryObj.sortField = this.options.sortBy[0];
      }
      if (this.options.sortDesc && this.options.sortDesc.length > 0) {
        queryObj.descDirection = this.options.sortDesc[0];
      }
      return queryObj;
    },
    syncFromCloud(uuid) {
      this.syncLoading = true;
      // @apiNote IntegrationAPI
      this.$http.get("integrations/" + uuid + "/refresh").then(() => {
        this.getData();
      });
    },
  },

  mounted() {
    // @apiNote ManagementAPI
    this.$http.get("management/sectors").then((response) => {
      this.sectors = response.data;
    });
    // this.getData();
  },
  components: { StockFilters },
};
</script>
