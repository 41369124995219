<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Opérations de maintenance quotidiennes</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" align="center">
        <v-btn
          color="warning"
          small
          @click="updateAllPrices"
          :loading="loading"
        >
          Update daily prices in WL &amp; PF
        </v-btn>
      </v-col>

      <v-col cols="3" align="center">
        <v-btn
          color="purple"
          small
          @click="updatePortfolioPerformance"
          :loading="loading"
        >
          Update portfolio performance
        </v-btn>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn
          color="primary"
          small
          @click="updateFundamentalData"
          :loading="loading"
        >
          Update fundamental data in WL &amp; PF
        </v-btn>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn
          color="indigo"
          small
          @click="checkEarningDates"
          :loading="loading"
        >
          Vérification des dates de résultats
        </v-btn>
      </v-col>

      <v-col cols="3" align="center">
        <v-btn
          color="deep-purple"
          small
          @click="checkPortfolio"
          :loading="loading"
        >
          Check portfolio
        </v-btn>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn color="grey" small @click="checkWatchlist" :loading="loading">
          Check watchlist
        </v-btn>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn color="cyan" small @click="updateIV" :loading="loading">
          Update IV
        </v-btn>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn
          color="green"
          small
          @click="sendNotifications"
          :loading="loading"
        >
          Send existing notifications
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Autres opérations</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" align="center">
        <v-btn color="pink" small @click="createFakeEvent">
          Create fake notif event
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackbar" color="success" outlined timeout="2000">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  name: "ManagementPage",
  watch: {},
  data: () => ({
    showSnackbar: false,
    snackbarText: undefined,
    loading: false,
  }),
  computed: {},
  methods: {
    updateAllPrices() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/update-all-prices").then(() => {
        this.snackbarText = "Les prix ont bien été mis à jour !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    updateFundamentalData() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/update-fundamental-data").then(() => {
        this.snackbarText =
          "Les données financières ont bien été mises à jour !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },

    updatePortfolioPerformance() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/update-portfolio-performance").then(() => {
        this.snackbarText =
          "Les performances des portoflios ont bien été mises à jour !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    checkEarningDates() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/check-earning-dates").then(() => {
        this.snackbarText = "Les publications de resultats ont été vérifiées !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    updateIV() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/update-iv").then(() => {
        this.snackbarText = "Les valeurs intrinsèques ont été mises à jour !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    checkPortfolio() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/check-portfolio").then(() => {
        this.snackbarText = "Les portfolios ont bien été vérifiées !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    checkWatchlist() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/check-watchlist").then(() => {
        this.snackbarText = "Les watchlists ont bien été vérifiées !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    sendNotifications() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/send-notifications").then(() => {
        this.snackbarText = "Les notifications sont envoyées !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
    createFakeEvent() {
      this.loading = true;
      // @apiNote ManagementAPI
      this.$http.post("management/fake-data").then(() => {
        this.snackbarText = "Les données sont injectées !";
        this.showSnackbar = true;
        this.loading = false;
      });
    },
  },
  mounted() {},
  filters: {},
};
</script>
