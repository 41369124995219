<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Importer des transactions</span>
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-if="currentStep === 1"
          show-size
          label="Fichier CSV des opérations sur DEGIRO"
          v-model="csvFileToUpload"
        ></v-file-input>

        <!-- Invalid equities -->
        <span v-if="currentStep === 2">
          <h3 class="red--text">
            Equity inconnues à ajouter avant de pouvoir les importer:
          </h3>
          <v-list>
            <v-list-item v-for="(item, i) in importResponse.invalid" :key="i">
              <v-list-item-title
                >{{ item.name }} ({{ item.isin }})</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </span>

        <!-- Valid equities -->
        <span v-if="currentStep === 3">
          <h3>Cocher les equities à importer dans ce portfolio:</h3>
          <v-list dense subheader v-if="validStocks && validStocks.length > 0">
            <v-subheader>Actions</v-subheader>
            <v-list-item-group v-model="selectedStocks" multiple>
              <v-list-item
                v-for="(item, i) in validStocks"
                :key="i"
                :value="item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }} ({{ item.isin }})
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list
            dense
            subheader
            v-if="validTrackers && validTrackers.length > 0"
          >
            <v-subheader>Trackers</v-subheader>
            <v-list-item-group v-model="selectedTrackers" multiple>
              <v-list-item
                v-for="(item, i) in validTrackers"
                :key="i"
                :value="item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }} ({{ item.isin }})
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </span>

        <!-- Error line -->
        <v-row v-if="errMessage">
          <v-col cols="12" sm="12" class="red darken-1 text-center"
            ><span class="white--text">Error : {{ errMessage }}</span></v-col
          >
        </v-row>

        <!-- Final line -->
        <v-row v-if="currentStep === 4">
          <v-col>
            <h3>
              {{ importedTransactions.length }} transaction(s) importée(s) avec
              succès.
            </h3>
            <v-list dense>
              <v-list-item v-for="(item, i) in importedTransactions" :key="i">
                {{ item.date | toDate }}
                - {{ item.type }} - {{ equitiesByUuid[item.equityUuid].name }}
                <span v-if="item.type !== 'DIVIDEND'">
                  &nbsp;- {{ item.quantity }}@{{ item.unitPrice }}
                </span>
                <span v-if="item.type === 'DIVIDEND'">
                  &nbsp;- {{ item.total }}
                </span>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Buttons line -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="show = false"
          :disabled="syncLoading"
          v-if="currentStep !== 4"
          >Annuler</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="show = false"
          v-if="currentStep === 4"
          :disabled="syncLoading"
          >Fermer</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :disabled="syncLoading || !selectedEquities"
          v-if="currentStep === 3"
          ref="confirmButton"
          :loading="syncLoading"
          @click="save()"
        >
          Importer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImportTransactionsDialog",
  props: ["value", "list"],

  data: function () {
    return {
      errMessage: null,
      syncLoading: false,
      csvFileToUpload: undefined,
      importResponse: {},
      validStocks: [],
      validTrackers: [],
      selectedStocks: [],
      selectedTrackers: [],
      importedTransactions: undefined,
      // 1: input file displayed to select the file to import (no action)
      // 2: unknown equities displayed (no action)
      // 3: identified equities displayed to chose which to import (import action)
      // 4: final message to display imported transaction count
      currentStep: 1,
    };
  },
  watch: {
    csvFileToUpload: function (val) {
      if (val) {
        this.sendFileToAnalyze();
      }
    },
  },
  computed: {
    selectedEquities() {
      return this.selectedStocks.concat(this.selectedTrackers);
    },
    equitiesByUuid() {
      let result = {};
      if (this.importResponse && this.importResponse.valid) {
        result = this.importResponse.valid.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
      }
      return result;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) {
          // Fermeture de la dialog
          this.resetDialogSettings();
        }
        this.$emit("input", value);
      },
    },
  },
  methods: {
    resetDialogSettings() {
      this.importResponse = {};
      this.selectedStocks = [];
      this.selectedTrackers = [];
      this.csvFileToUpload = undefined;
      this.importedTransactions = undefined;
      this.currentStep = 1;
    },
    save() {
      // console.log("selectedEquities: ", this.selectedEquities);
      this.importResponse.selected = this.selectedEquities;
      this.syncLoading = true;
      // @apinote ListAPI
      this.$http
        .post("lists/" + this.list.id + "/import", this.importResponse)
        .then(
          (response) => {
            console.log("RESP: ", response);
            this.importedTransactions = response.data;
            this.syncLoading = false;
            this.currentStep = 4;
            this.$emit("updateTransactionList");
          },
          (error) => {
            console.log("ERR: ", error);
          }
        );
    },
    sendFileToAnalyze() {
      this.errMessage = undefined;

      let formData = new FormData();
      formData.append("file", this.csvFileToUpload);
      // @apinote ListAPI
      this.$http
        .post(
          "lists/" + this.list.id + "/import/check-transaction-file",
          formData
        )
        .then(
          (response) => {
            this.importResponse = response.data;
            if (
              this.importResponse.invalid &&
              this.importResponse.invalid.length > 0
            ) {
              this.currentStep = 2;
            } else {
              this.currentStep = 3;
            }
            this.validStocks = this.importResponse.valid.filter(
              (one) => !one.tracker
            );
            this.validTrackers = this.importResponse.valid.filter(
              (one) => one.tracker
            );
          },
          (error) => {
            this.errMessage = error.response.data;
          }
        );
    },
  },
  mounted() {},
};
</script>
