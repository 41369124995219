<template>
  <tbody>
    <line-growth-graph
      :title="graphTitle"
      :attributeValue="graphAttribute"
      :displayGrowth="graphDisplayGrowth"
      :periodType="dataPeriod"
      :dataset="graphDataset"
      v-model="showLineGrowthGraphDialog"
    >
    </line-growth-graph>
    <bar-graph
      :title="graphTitle"
      :attributeValue="graphAttribute"
      :periodType="dataPeriod"
      :dataset="graphDataset"
      :isPercentage="graphIsPercentage"
      v-model="showBarGraphDialog"
    >
    </bar-graph>
    <tr class="blue lighten-2">
      <td width="200"></td>
      <td width="120">Obj</td>
      <td v-for="date in dates" :key="date">
        {{ date | toDate }}
      </td>
    </tr>
    <!-- Revenue / sales -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="incStmtAttPerDateFn('revenue')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openGraphDialog(
              'Chiffre d\'affaire',
              'revenue',
              true,
              stock.incomeStatement
            )
          "
          >Revenue (M)
        </span>
      </template>
      <template v-slot:rowTarget></template>
    </data-per-date>

    <!-- Net income -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="incStmtAttPerDateFn('netIncome')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openGraphDialog(
              'Bénéfice',
              'netIncome',
              true,
              stock.incomeStatement
            )
          "
        >
          Net income (M)
        </span>
      </template>
      <template v-slot:rowTarget>> 0</template>
    </data-per-date>

    <!-- Gross margin -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('grossMargin')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'Marge brute',
              'grossMargin',
              stock.fundamentalIndicators
            )
          "
        >
          Gross margin
        </span>
      </template>
      <template v-slot:rowTarget> > 40% </template>
    </data-per-date>

    <!-- Operating margin -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('operatingMargin')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'Marge opérationnelle',
              'operatingMargin',
              stock.fundamentalIndicators
            )
          "
        >
          Operating margin
        </span>
      </template>
      <template v-slot:rowTarget>> 10%</template>
    </data-per-date>

    <!-- Net margin -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('netMargin')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'Marge nette',
              'netMargin',
              stock.fundamentalIndicators
            )
          "
        >
          Net margin
        </span>
      </template>
      <template v-slot:rowTarget>> 5%</template>
    </data-per-date>

    <!-- SGA ratio -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('sgaRatio')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'Capacity to attract new customers',
              'sgaRatio',
              stock.fundamentalIndicators
            )
          "
        >
          <tooltip-comment label="Capacity to attract new customers">
            Si > à 100% alors l'entreprise doit rogner sur sa marge pour attirer
            de nouveaux clients = compliqué dans la durée. (SGA expenses / Gross
            profit)
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget>&lt; 30%</template>
    </data-per-date>

    <!-- R&D ratio -------------------------------------------------------------------------------->
    <data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('rdRatio')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'R&amp;D cost',
              'rdRatio',
              stock.fundamentalIndicators
            )
          "
        >
          <tooltip-comment label="R&amp;D cost">
            Si > à 30% alors attention, il faudra connaître le secteur. (R&amp;D
            expenses / Gross profit)
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget>&lt; 30%</template>
    </data-per-date>
  </tbody>
</template>

<script>
import TooltipComment from "@/components/commons/TooltipComment.vue";
import DataPerDate from "@/components/stock-detail/financial/DataPerDate.vue";
import LineGrowthGraph from "@/components/stock-detail/financial/graphs/LineGrowthGraph.vue";
import BarGraph from "@/components/stock-detail/financial/graphs/BarGraph.vue";

export default {
  name: "FinancialTabIncomeStatement",
  props: ["stock", "dataPeriod"],
  watch: {},
  data: function () {
    return {
      showLineGrowthGraphDialog: false,
      showBarGraphDialog: false,
      graphAttribute: "revenue",
      graphDataset: [],
      graphTitle: "",
      graphIsPercentage: true,
      graphDisplayGrowth: false,
    };
  },
  computed: {
    dates() {
      return Object.keys(this.incomeStatement).sort().reverse();
    },
    incomeStatement() {
      let result = this.stock.incomeStatement
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
    fundamentalIndicators() {
      let result = this.stock.fundamentalIndicators
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
  },
  methods: {
    openGraphDialog(title, attribute, displayGrowth, dataset) {
      this.graphTitle = title;
      this.graphAttribute = attribute;
      this.graphDisplayGrowth = displayGrowth;
      this.graphDataset = dataset;
      this.showLineGrowthGraphDialog = true;
    },
    openBarGraphDialog(title, attribute, dataset) {
      this.graphTitle = title;
      this.graphAttribute = attribute;
      this.graphDataset = dataset;
      this.showBarGraphDialog = true;
    },
    fundAttPerDateFn(attribute) {
      return (date) => {
        return this.$options.filters.toFloat(
          this.fundamentalIndicators[date][attribute],
          100,
          "%"
        );
      };
    },
    incStmtAttPerDateFn(attribute) {
      return (date) => {
        return this.incomeStatement[date][attribute];
      };
    },
    curRatiosAttFn(attribute) {
      return () => {
        return this.$options.filters.toFloat(
          this.stock.currentRatios[attribute],
          100,
          "%"
        );
      };
    },
  },
  components: {
    TooltipComment,
    DataPerDate,
    LineGrowthGraph,
    BarGraph,
  },
};
</script>
<style lang="scss" scoped>
</style>
