<template>
  <v-data-table
    v-if="curList"
    dense
    :headers="tableHeaders"
    :items="dataTableRows"
    :items-per-page="-1"
    :hide-default-footer="true"
    :loading="isLoading"
    class="elevation-1"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <td></td>
        <td>
          <v-overlay :value="displayOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>

          <v-select
            dense
            v-model="typeFilter"
            :items="typeValues"
            clearable
          ></v-select>
        </td>
        <td>
          <v-autocomplete
            dense
            v-model="stockFilter"
            :items="stockValues"
            item-text="name"
            item-value="id"
            clearable
          ></v-autocomplete>
        </td>
        <td colspan="7"></td>
      </tr>
    </template>

    <!-- Name -->
    <template v-slot:[`item.name`]="{ item }">
      <router-link
        v-if="item.name && !curList.trackerPortfolio"
        style="text-decoration: none"
        :to="{
          name: 'StockDetail',
          params: { uuid: item.equityUuid },
        }"
      >
        {{ item.name }} ({{ item.yticker }})
      </router-link>
      <router-link
        v-if="item.name && curList.trackerPortfolio"
        style="text-decoration: none"
        :to="{
          name: 'TrackerDetail',
          params: { uuid: item.equityUuid },
        }"
      >
        {{ item.name }} ({{ item.yticker }})
      </router-link>
    </template>

    <!-- unitPrice -->
    <template v-slot:[`item.unitPrice`]="{ item }">
      {{ item.unitPrice | toFloat(1, " " + currencySymbol) }}
    </template>

    <!-- Fees -->
    <template v-slot:[`item.fees`]="{ item }">
      {{ item.fees | toFloat(1, " " + currencySymbol) }}
    </template>

    <!-- Cash -->
    <template v-slot:[`item.cash`]="{ item }">
      {{ item.cash | toFloat(1, " " + currencySymbol) }}
    </template>

    <!-- Invested -->
    <template v-slot:[`item.invested`]="{ item }">
      {{ (-1 * item.cash) | toFloat(1, " " + currencySymbol) }}
    </template>

    <!-- Total -->
    <template v-slot:[`item.total`]="{ item }">
      <span :class="item.total > 0 ? 'green--text' : 'red--text'">
        {{ item.total | toFloat(1, " " + currencySymbol) }}
      </span>
    </template>

    <!-- Date -->
    <template v-slot:[`item.date`]="{ item }">
      {{ item.date | toDate }}
    </template>

    <!-- Actions -->
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn small color="red" icon @click="deleteTransaction(item.id)">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "CarnetTab",
  props: ["curList"],
  watch: {},
  data: function () {
    return {
      isLoading: true,
      typeFilter: undefined,
      stockFilter: undefined,
      typeValues: ["BUY", "CASH", "DIVIDEND", "FEES", "SELL"],
      displayOverlay: false,
      transactions: [],
      equities: {},
    };
  },
  computed: {
    tableHeaders() {
      let headers = [
        { text: "Date", value: "date" },
        {
          text: "Type",
          value: "type",
          filter: (value) => {
            if (!this.typeFilter) return true;
            return value === this.typeFilter;
          },
        },
        {
          text: "Quoi",
          value: "name",
          filter: (value, search, item) => {
            if (!this.stockFilter) return true;
            return item.equityUuid === this.stockFilter;
          },
        },
        { text: "Nb", value: "quantity", align: "right" },
        { text: "PU", value: "unitPrice", align: "right" },
        { text: "Comm", value: "fees", align: "right" },
        { text: "Montant", value: "total", align: "right" },
      ];
      if (this.curList.cashManagementEnabled) {
        headers.push({ text: "Cash", value: "cash", align: "right" });
      } else {
        // headers.push({ text: "Investi", value: "invested", align: "right" });
      }
      headers.push({ text: "Actions", value: "actions" });

      return headers;
    },
    dataTableRows() {
      if (this.isLoading) {
        return [];
      }
      let result = [...this.transactions];

      result.forEach((oneTx) => {
        oneTx.name = this.equities[oneTx.equityUuid].name;
        oneTx.yticker = this.equities[oneTx.equityUuid].yticker;
      });

      return result;
    },
    stockValues: function () {
      if (this.isLoading) {
        return [];
      }
      let result = Object.values(this.equities).sort((a, b) => {
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
      });
      return result;
    },
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
  },
  methods: {
    deleteTransaction(id) {
      this.displayOverlay = true;
      // @apinote TransactionAPI
      this.$http
        .delete("lists/" + this.curList.id + "/transactions/" + id)
        .then(() => {
          this.displayOverlay = false;
          this.initTab();
          this.$emit("updateList");
        });
    },

    /** ************************************************************************
     * API calls
     */
    initTab() {
      // @apinote TransactionAPI
      this.$http
        .get("lists/" + this.curList.id + "/transactions")
        .then((response) => {
          this.transactions = response.data;

          this.transactions = response.data.filter(
            (oneTx) =>
              this.curList.cashManagementEnabled ||
              (!this.curList.cashManagementEnabled &&
                ["BUY", "SELL", "DIVIDEND"].includes(oneTx.type))
          );

          let equities = this.transactions.map((oneTx) => oneTx.equityUuid);
          let prefix = this.curList.trackerPortfolio ? "trackers" : "stocks";
          // @apinote StockBatchAPI/TrackerBatchAPI
          this.$http.post(prefix + "/by-batch", equities).then((response) => {
            this.equities = response.data;
            this.isLoading = false;
          });
        });
    },
  },

  mounted() {
    this.initTab();
  },
};
</script>
