var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.curList)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.dataTableRows,"items-per-page":-1,"hide-default-footer":true,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:`body.prepend`,fn:function(){return [_c('tr',[_c('td'),_c('td',[_c('v-overlay',{attrs:{"value":_vm.displayOverlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-select',{attrs:{"dense":"","items":_vm.typeValues,"clearable":""},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.stockValues,"item-text":"name","item-value":"id","clearable":""},model:{value:(_vm.stockFilter),callback:function ($$v) {_vm.stockFilter=$$v},expression:"stockFilter"}})],1),_c('td',{attrs:{"colspan":"7"}})])]},proxy:true},{key:`item.name`,fn:function({ item }){return [(item.name && !_vm.curList.trackerPortfolio)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'StockDetail',
        params: { uuid: item.equityUuid },
      }}},[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.yticker)+") ")]):_vm._e(),(item.name && _vm.curList.trackerPortfolio)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'TrackerDetail',
        params: { uuid: item.equityUuid },
      }}},[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.yticker)+") ")]):_vm._e()]}},{key:`item.unitPrice`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.unitPrice,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.fees`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.fees,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.cash`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.cash,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.invested`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")((-1 * item.cash),1, " " + _vm.currencySymbol))+" ")]}},{key:`item.total`,fn:function({ item }){return [_c('span',{class:item.total > 0 ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.total,1, " " + _vm.currencySymbol))+" ")])]}},{key:`item.date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.date))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"red","icon":""},on:{"click":function($event){return _vm.deleteTransaction(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }