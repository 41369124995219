<template>
  <v-chart
    :init-options="initOptions"
    :option="options"
    class="chart"
    :loading="loading"
    :loadingOptions="loadingOptions"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
]);

export default {
  name: "PortfolioDividendPerYearECharts",
  props: ["curList"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      loading: true,
      transactions: [],
      equities: {},
      loadingOptions: {
        text: "Loading...",
        color: "red",
        maskColor: "rgba(0, 0, 0, 0)",
      },
      rawHistory: [],
      initOptions: {
        renderer: "canvas",
      },
      options: {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Dividende par an",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },

          formatter: (params) => {
            let equities = Object.keys(params[0].data.equities)
              .sort()
              .map((oneE) => {
                return (
                  oneE +
                  " (" +
                  this.$options.filters.toFloat(params[0].data.equities[oneE]) +
                  ")"
                );
              });
            // console.log("params: ", params);
            return (
              "<b>Dividende:" +
              this.$options.filters.toFloat(params[0].data.dividend) +
              "</b><br/><i><ul><li>" +
              equities.join("</li><li>") +
              "</li></ul></i>"
            );
          },
        },

        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
          },
        ],
        dataset: {
          dimensions: ["yearStr", "dividend"],
          source: [],
        },
      },
    };
  },
  computed: {
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
  },
  methods: {
    calculateDataset() {
      let today = new Date();

      // Get dividend tx
      let dividendTxPerMonth = this.transactions

        .filter((oneTx) => new Date(oneTx.date) <= today)
        // Add monthStr attr
        .map((oneTx) => {
          let d = new Date(oneTx.date);
          oneTx.yearStr = d.toLocaleDateString("fr-FR", {
            year: "numeric",
          });
          return oneTx;
        })

        // Reduce tx array to month array
        .reduce((obj, oneTx) => {
          let equityName = this.equities[oneTx.equityUuid].name;
          let equities = {};
          equities[equityName] = oneTx.total;

          if (obj.length === 0) {
            obj.push({
              yearStr: oneTx.yearStr,
              dividend: oneTx.total,
              equities: equities,
            });
          } else {
            let lastEntry = obj[obj.length - 1];
            if (lastEntry.yearStr === oneTx.yearStr) {
              lastEntry.dividend += oneTx.total;
              equities = lastEntry.equities;
              if (equities[equityName]) {
                equities[equityName] += oneTx.total;
              } else {
                equities[equityName] = oneTx.total;
              }
            } else {
              obj.push({
                yearStr: oneTx.yearStr,
                dividend: oneTx.total,
                equities: equities,
              });
            }
          }
          return obj;
        }, []);
      this.loading = false;
      this.options.dataset.source = dividendTxPerMonth;
    },
    getEquities(equityIds) {
      let url = this.curList.trackerPortfolio ? "trackers" : "stocks";
      // @apiNote StockBatchAPI/TrackerBatchAPI
      this.$http.post(url + "/by-batch", equityIds).then((response) => {
        this.equities = response.data;
        this.calculateDataset();
      });
    },
    getData() {
      this.loading = true;
      // @apiNote TransactionAPI
      this.$http
        .get("lists/" + this.curList.id + "/transactions?type=DIVIDEND")
        .then((response) => {
          this.transactions = response.data;
          let equityIds = this.transactions.map(
            (oneLine) => oneLine.equityUuid
          );
          this.getEquities(equityIds);
        });
    },
  },
  mounted() {
    this.getData();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>