<template>
  <v-container>
    <edit-portfolio-dialog :dialogExt="dialogExt" :curList="curList" />

    <v-btn
      color="error"
      elevation="2"
      fab
      right
      bottom
      fixed
      @click.stop="handleClickOnPlus()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="lists"
      :items-per-page="-1"
      :hide-default-footer="true"
      class="elevation-1"
    >
      <!-- Label column -->
      <template v-slot:[`item.label`]="{ item }">
        <v-icon v-if="!item.trackerPortfolio"> mdi-alpha-s </v-icon>
        <v-icon v-if="item.trackerPortfolio">mdi-alpha-t</v-icon>
        <router-link
          style="text-decoration: none"
          :to="{ name: 'PortfolioDetail', params: { uuid: item.id } }"
        >
          {{ item.label }}
        </router-link>
      </template>

      <!-- LC Value-->
      <template v-slot:[`item.value`]="{ item }">
        {{ item.value | toFloat(1, " " + getCurrencySymbol(item)) }}
      </template>

      <!-- Invested-->
      <template v-slot:[`item.invested`]="{ item }">
        {{ item.invested | toFloat(1, " " + getCurrencySymbol(item)) }}
      </template>

      <!-- Performance-->
      <template v-slot:[`item.performance.perfToday`]="{ item }">
        <span
          v-if="item.performance"
          :class="getClass(item.performance.perfToday)"
        >
          {{ item.performance.perfToday | toFloat(100, " %") }}
        </span>
      </template>
      <template v-slot:[`item.performance.perf1D`]="{ item }">
        <span
          v-if="item.performance"
          :class="getClass(item.performance.perf1D)"
        >
          {{ item.performance.perf1D | toFloat(100, " %") }}
        </span>
      </template>
      <template v-slot:[`item.performance.perf1W`]="{ item }">
        <span
          v-if="item.performance"
          :class="getClass(item.performance.perf1W)"
        >
          {{ item.performance.perf1W | toFloat(100, " %") }}
        </span>
      </template>
      <template v-slot:[`item.performance.perf1M`]="{ item }">
        <span
          v-if="item.performance"
          :class="getClass(item.performance.perf1M)"
        >
          {{ item.performance.perf1M | toFloat(100, " %") }}
        </span>
      </template>
      <template v-slot:[`item.performance.perf3M`]="{ item }">
        <span
          v-if="item.performance"
          :class="getClass(item.performance.perf3M)"
        >
          {{ item.performance.perf3M | toFloat(100, " %") }}
        </span>
      </template>
      <template v-slot:[`item.performance.perfYTD`]="{ item }">
        <span
          v-if="item.performance"
          :class="getClass(item.performance.perfYTD)"
        >
          {{ item.performance.perfYTD | toFloat(100, " %") }}
        </span>
      </template>

      <!-- Actions -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          icon
          @click="handleClickOnPlus(item)"
          title="Modifier les paramètres"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          :loading="syncLoading"
          :disabled="syncLoading"
          small
          icon
          @click="refreshTodayPerformance(item.id)"
          title="Rafraichir la performance du jour"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          :loading="syncLoading"
          :disabled="syncLoading"
          small
          icon
          @click="updatePerformance(item.id)"
          title="Recalculer les indicateurs de performance"
        >
          <v-icon>mdi-poll</v-icon>
        </v-btn>

        <v-btn
          small
          color="red"
          icon
          :loading="syncLoading"
          :disabled="syncLoading"
          @click="deleteList(item.id)"
          title="Supprimer"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/**
 * Used to display portfolio list
 */

import EditPortfolioDialog from "@/components/portfolio-list/dialogs/EditPortfolioDialog.vue";

export default {
  name: "PortfolioTable",
  props: [],
  watch: {},
  data: function () {
    return {
      lists: [],
      syncLoading: false,

      dialogExt: false,
      curList: undefined,
    };
  },
  computed: {
    headers: function () {
      let result = [{ text: "Name", value: "label" }];
      let portfolioColumns = [
        {
          text: "LC Valo",
          value: "value",
          align: "right",
        },
        {
          text: "Invested",
          value: "invested",
          align: "right",
        },
        {
          text: "Perf Today",
          value: "performance.perfToday",
          align: "right",
        },
        {
          text: "Perf 1D",
          value: "performance.perf1D",
          align: "right",
        },
        {
          text: "Perf 1W",
          value: "performance.perf1W",
          align: "right",
        },
        {
          text: "Perf 1M",
          value: "performance.perf1M",
          align: "right",
        },
        {
          text: "Perf 3M",
          value: "performance.perf3M",
          align: "right",
        },
        {
          text: "Perf YTD",
          value: "performance.perfYTD",
          align: "right",
        },
      ];
      result = result.concat(portfolioColumns);
      result.push({ text: "Actions", value: "actions" });
      return result;
    },
  },
  methods: {
    getClass: function (value) {
      if (value > 0.0001) {
        return "success--text";
      } else if (value < -0.0001) {
        return "error--text";
      } else {
        return "";
      }
    },
    getCurrencySymbol(curList) {
      if (curList.currency === "EUR") {
        return "€";
      } else if (curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
    getData() {
      // @apiNote ListAPI
      this.$http.get("lists").then((response) => {
        this.lists = response.data;
        this.syncLoading = false;
        this.getSubData();
      });
    },
    getCurrentPosition(pfUuid) {
      // @apiNote ListAPI
      return this.$http
        .get("lists/" + pfUuid + "/current-position")
        .then((response) => {
          this.lists.forEach((oneList) => {
            if (oneList.id === pfUuid) {
              oneList.value = response.data.value;
              oneList.invested = response.data.invested;
            }
          });
        });
    },
    getCurrentPerformance(pfUuid) {
      // @apiNote ListAPI
      return this.$http
        .get("lists/" + pfUuid + "/current-performance")
        .then((response) => {
          this.lists.forEach((oneList) => {
            if (oneList.id === pfUuid) {
              oneList.performance.perfToday = response.data;
            }
          });
        });
    },
    getSubData() {
      var p = Promise.resolve(); // Q() in q

      this.lists.forEach(
        (oneList) => (p = p.then(() => this.getCurrentPosition(oneList.id)))
      );
      this.lists.forEach(
        (oneList) => (p = p.then(() => this.getCurrentPerformance(oneList.id)))
      );
      this.syncLoading = false;
    },
    refreshTodayPerformance(listId) {
      this.syncLoading = true;
      // @apiNote ListAPI
      this.$http.put("lists/" + listId + "/refresh-current-prices").then(() => {
        this.getCurrentPerformance(listId);
        this.syncLoading = false;
      });
    },
    getOneList(listId) {
      // @apiNote ListAPI
      this.$http.get("lists/" + listId).then((response) => {
        let curList = response.data;
        this.lists = this.lists.map((oneList) => {
          return oneList.id === curList.id ? curList : oneList;
        });
        this.syncLoading = false;
      });
    },
    deleteList(listId) {
      // @apiNote ListAPI
      this.$http.delete("lists/" + listId).then(() => {
        this.getData();
      });
    },
    updatePerformance(listId) {
      this.syncLoading = true;
      // @apiNote ListAPI
      this.$http.put("lists/" + listId + "/performance").then(() => {
        this.getData();
      });
    },

    handleClickOnPlus(stockList) {
      this.curList = stockList;
      this.dialogExt = true;
      setTimeout(() => {
        this.dialogExt = false;
      }, 1000);
    },
  },
  mounted() {
    this.getData();
  },
  filters: {},
  components: { EditPortfolioDialog },
};
</script>
