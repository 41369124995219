<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bordered
        overlap
        offset-x="15"
        offset-y="15"
        icon="mdi-plus"
        color="blue accent-3"
        dark
      >
        <v-btn color="blue" elevation="2" fab v-bind="attrs" v-on="on">
          <v-icon x-large>mdi-alpha-t</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="selectedTracker"
                  dense
                  :items="items"
                  :loading="isLoading"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  item-text="text"
                  item-value="yticker"
                  label="Nouveau tracker"
                  placeholder="Start typing to Search"
                  clearable
                  autofocus
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                class="red darken-1 text-center"
                v-if="errMessage"
                ><span class="white--text"
                  >Error adding tracker : {{ errMessage }}.</span
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
          :disabled="syncLoading"
          >Annuler</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :loading="syncLoading"
          text
          ref="confirmButton"
          @click="save()"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "AddTrackerDialog",
  props: ["filters"],

  data: () => ({
    selectedTracker: null,
    isLoading: false,
    search: null,
    entries: [],
    dialog: false,
    syncLoading: false,
    errMessage: null,
  }),
  computed: {
    items() {
      let result = this.entries.map((entry) => {
        entry.text = entry.yticker + " - " + entry.name;
        return entry;
      });
      return result;
    },
  },
  watch: {
    search: _.debounce(function (val) {
      // To avoid researching on complete ETF name when etf has been selected
      if (this.selectedTracker && val === this.selectedTracker.text) return;
      this.updateEntries(val);
    }, 1000),
  },
  methods: {
    updateEntries: function (val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      // @apinote ok
      this.$http.get("parsers/search?q=" + val + "&type=ETF").then(
        (searchResult) => {
          this.entries = searchResult.data.searchResults;
          this.isLoading = false;
        },
        (error) => {
          console.error("ERROR : ", error);
          this.errMessage = error;
          this.isLoading = false;
        }
      );
    },
    save: function () {
      this.syncLoading = true;
      console.log("newTracker : ", this.selectedTracker);

      // @apinote ok
      this.$http
        .post("trackers", {
          yTicker: this.selectedTracker.yticker,
        })
        .then(
          (newTracker) => {
            this.syncLoading = false;
            this.$router.push("/trackers/" + newTracker.data.id);
            this.dialog = false;
          },
          (error) => {
            console.error("ERROR : ", error);
            this.errMessage = error;
            this.syncLoading = false;
          }
        );
    },
  },
  mounted() {},
};
</script>
