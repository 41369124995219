<template>
  <tbody>
    <line-growth-graph
      :title="graphTitle"
      :attributeValue="graphAttribute"
      :displayGrowth="graphDisplayGrowth"
      :periodType="dataPeriod"
      :dataset="graphDataset"
      v-model="showLineGrowthGraphDialog"
    >
    </line-growth-graph>
    <bar-graph
      :title="graphTitle"
      :attributeValue="graphAttribute"
      :periodType="dataPeriod"
      :dataset="graphDataset"
      :isPercentage="graphIsPercentage"
      v-model="showBarGraphDialog"
    >
    </bar-graph>
    <tr class="blue lighten-2">
      <td width="200"></td>
      <td width="120">Obj</td>
      <td v-for="date in dates" :key="date">
        {{ date | toDate }}
      </td>
    </tr>
    <!-- CAPEX to net income ratio -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('capexRatio')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'CAPEX / Net income',
              'capexRatio',
              stock.fundamentalIndicators,
              (isPercentage = true)
            )
          "
        >
          CAPEX / Net income
        </span>
      </template>
      <template v-slot:rowTarget> &lt; 25% </template>
    </financial-tab-data-per-date>

    <!-- Rachat d'actions -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="cashFlowAttPerDateFn('stockIssuance')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'Stock issuance',
              'stockIssuance',
              stock.cashFlow,
              (isPercentage = false)
            )
          "
        >
          <tooltip-comment label="Stock issuance">
            Si &lt; 0, indique que l'entreprise a racheté de ses actions.
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget> &lt; 0 </template>
    </financial-tab-data-per-date>

    <!-- Free cash flow -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('fcf', (isPercentage = false))"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openLineGrowthGraphDialog(
              'Free Cash Flow',
              'fcf',
              true,
              stock.fundamentalIndicators
            )
          "
          >Free Cash Flow
        </span>
      </template>
      <template v-slot:rowTarget> &gt; 0 </template>
    </financial-tab-data-per-date>

    <!-- FCF per share -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('fcfPerShare', (isPercentage = false))"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'FCF per share',
              'fcfPerShare',
              stock.fundamentalIndicators,
              (isPercentage = false)
            )
          "
          >FCF per share
        </span>
      </template>
      <template v-slot:rowTarget> &gt; 0 </template>
    </financial-tab-data-per-date>
  </tbody>
</template>

<script>
import TooltipComment from "@/components/commons/TooltipComment.vue";
import FinancialTabDataPerDate from "@/components/stock-detail/financial/DataPerDate.vue";
import BarGraph from "@/components/stock-detail/financial/graphs/BarGraph.vue";
import LineGrowthGraph from "@/components/stock-detail/financial/graphs/LineGrowthGraph.vue";

export default {
  name: "FinancialTabBalanceSheet",
  props: ["stock", "dataPeriod"],
  watch: {},
  data: function () {
    return {
      showLineGrowthGraphDialog: false,
      showBarGraphDialog: false,
      graphAttribute: "revenue",
      graphDataset: [],
      graphTitle: "",
      graphIsPercentage: true,
      graphDisplayGrowth: false,
    };
  },
  computed: {
    dates() {
      return Object.keys(this.cashFlow).sort().reverse();
    },
    cashFlow() {
      let result = this.stock.cashFlow
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
    fundamentalIndicators() {
      let result = this.stock.fundamentalIndicators
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
  },
  methods: {
    openLineGrowthGraphDialog(title, attribute, displayGrowth, dataset) {
      this.graphTitle = title;
      this.graphAttribute = attribute;
      this.graphDisplayGrowth = displayGrowth;
      this.graphDataset = dataset;
      this.showLineGrowthGraphDialog = true;
    },
    openBarGraphDialog(title, attribute, dataset, isPercentage = true) {
      this.graphTitle = title;
      this.graphAttribute = attribute;
      this.graphDataset = dataset;
      this.graphIsPercentage = isPercentage;
      this.showBarGraphDialog = true;
    },
    fundAttPerDateFn(attribute, isPercentage = true) {
      return (date) => {
        return this.$options.filters.toFloat(
          this.fundamentalIndicators[date][attribute],
          isPercentage ? 100 : 1,
          isPercentage ? "%" : ""
        );
      };
    },
    fcfPerDateFn() {
      return (date) => {
        let fcf = this.fundamentalIndicators[date]["fcf"]
          ? this.fundamentalIndicators[date]["fcf"]
          : this.cashFlow[date]["freeCashFlow"];
        return this.$options.filters.toFloat(fcf);
      };
    },
    cashFlowAttPerDateFn(attribute) {
      return (date) => {
        return this.cashFlow[date][attribute];
      };
    },
  },
  components: {
    TooltipComment,
    FinancialTabDataPerDate,
    BarGraph,
    LineGrowthGraph,
  },
};
</script>
<style lang="scss" scoped>
</style>
