<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-chart
        autoresize
        :init-options="initOptions"
        :option="options"
        class="chart"
        :loading="loading"
        :loadingOptions="loadingOptions"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
]);

export default {
  name: "LineGrowthGraph",
  props: [
    "value",
    "dataset",
    "attributeValue",
    "displayGrowth",
    "title",
    "periodType",
  ],
  provide: {
    [THEME_KEY]: "dark",
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        this.options.dataset.source = this.getDataset();
        this.options.title.text = this.title;
      }
    },
  },
  data: function () {
    return {
      loading: true,
      yAxisLabelEnding: "",
      loadingOptions: {
        text: "Loading...",
        color: "red",
        maskColor: "rgba(0, 0, 0, 0)",
      },
      rawHistory: [],
      initOptions: {
        renderer: "canvas",
      },
      options: {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "default title",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let result = params[0].data.monthStr + "<br/><b>";
            result += this.$options.filters.toFloat(
              params[0].data.value,
              1,
              ""
            );
            result += "</b>";

            result +=
              this.displayGrowth && params[0].data.growth
                ? " (" +
                  this.$options.filters.toFloat(params[0].data.growth, 1, "%") +
                  ")"
                : "";
            return result;
          },
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
          },
        ],

        series: [
          {
            animation: false,
            type: "bar",
            yAxisIndex: 0,
          },
        ],
        dataset: {
          dimensions: ["monthStr", "value", "growth"],
          source: [],
        },
      },
    };
  },
  methods: {
    getDataset() {
      let valuePerDate = this.dataset
        .filter((oneVal) => oneVal.type === this.periodType)
        .sort((v1, v2) => (v1.date > v2.date ? 1 : -1))
        .reduce((curRes, oneVal) => {
          let d = new Date(oneVal.date);
          let onePoint = {
            monthStr: d.toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "short",
            }),
            value: oneVal[this.attributeValue],
          };
          curRes.push(onePoint);
          return curRes;
        }, []);

      if (this.displayGrowth) {
        for (var i = 1; i < valuePerDate.length; i++) {
          if (valuePerDate[i].value && valuePerDate[i - 1].value) {
            valuePerDate[i].growth =
              (100 * (valuePerDate[i].value - valuePerDate[i - 1].value)) /
              Math.abs(valuePerDate[i - 1].value);
          }
        }
      }

      console.log("valuePerDate: ", valuePerDate);
      this.loading = false;

      return valuePerDate;
    },
  },
  mounted() {
    //this.options.dataset.source = this.getDataset();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>