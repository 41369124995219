import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {
    theme: {
        dark: true,
        options: {
            customProperties: true,
        },
    }
}

export default new Vuetify(opts
);
