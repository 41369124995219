<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card v-if="holdingParameters">
      <v-card-title>
        <span class="headline">
          Stop loss sur "{{ equities[holdingParameters.equityUuid].name }}"
        </span>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-5">
          <!-- mode switcher -->
          <v-col cols="12">
            <v-switch
              v-model="newHoldingParameters.stopLossMode"
              label="Automatic stop-loss"
              color="red"
              true-value="auto"
              false-value="manual"
            >
            </v-switch>
          </v-col>
          <!-- Manual mode -->
          <v-col cols="12" v-if="newHoldingParameters.stopLossMode !== 'auto'">
            <v-text-field
              dense
              type="number"
              label="Manual stop-loss"
              v-model="newHoldingParameters.stopLoss"
            >
            </v-text-field>
          </v-col>
          <!-- Auto mode -->
          <v-col cols="6" v-if="newHoldingParameters.stopLossMode === 'auto'">
            <v-text-field
              dense
              label="Stop-loss ratio"
              type="number"
              hint="% of stop-loss below max price"
              persistent-hint
              v-model="newHoldingParameters.stopLossAutoRatio"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" v-if="newHoldingParameters.stopLossMode === 'auto'">
            <v-text-field
              dense
              label="Start date"
              type="date"
              hint="Starting date to consider to get max price"
              persistent-hint
              v-model="newHoldingParameters.stopLossAutoStartDate"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- Comment field -->
            <v-tiptap
              v-model="newHoldingParameters.stopLossComment"
              :dark="dark"
              label="Comment"
              :toolbar="[
                'bold',
                'italic',
                'underline',
                'strike',
                'color',
                '|',
                'h1',
                'h2',
                'h3',
                '|',
                'emoji',
              ]"
              dense="true"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="red darken-1 text-center" v-if="errMessage">
            <span class="white--text">
              Error setting stop loss : {{ errMessage }}.
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" plain @click="show = false"> Fermer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "ManageStopLossDialog",
  props: ["value", "holdingParameters", "portfolioId", "equities"],

  data: () => ({
    dark: true,
    isThereAnyUpdate: false,
    errMessage: null,
    newHoldingParameters: {
      stopLossMode: "manual",
      stopLoss: undefined,
      stopLossComment: undefined,
      stopLossAutoStartDate: new Date().toISOString().split("T")[0],
      stopLossAutoRatio: 15,
    },
  }),
  watch: {
    newHoldingParameters: {
      handler: _.debounce(function () {
        console.log("watch newHoldingParameters: ", this.newHoldingParameters);
        this.updateStopLoss();
      }, 1000),
      deep: true,
    },
    show: function () {
      if (this.show) {
        // Init de la structure holdingParameters
        this.initHoldingParameters();
      } else {
        // Si au moins une modif, reload du portfolio
        if (this.isThereAnyUpdate) {
          this.$emit("reloadPortfolio");
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    initHoldingParameters() {
      // console.log("initholdingParameters ", JSON.stringify(this.holdingParameters));
      // Re init holdingParameters to default values
      Object.assign(this.newHoldingParameters, {
        stopLossMode: "manual",
        stopLoss: undefined,
        stopLossComment: undefined,
        stopLossAutoStartDate: new Date().toISOString().split("T")[0],
        stopLossAutoRatio: 15,
      });
      // console.log("initholdingParameters2 ", JSON.stringify(this.newHoldingParameters));
      // Assign value from sellingTab
      Object.assign(this.newHoldingParameters, this.holdingParameters);
      // console.log("end initholdingParameters ", this.newHoldingParameters);
    },
    updateStopLoss: function () {
      this.errMessage = undefined;

      // Do not save anything if dialog is closed
      if (!this.show) {
        // console.log("Pas de sauvegarde quand la modale n'est pas ouverte !");
        return;
      }
      if (
        this.newHoldingParameters.stopLossMode === "auto" &&
        (!this.newHoldingParameters.stopLossAutoRatio ||
          !this.newHoldingParameters.stopLossAutoStartDate)
      ) {
        this.errMessage = "All fields are mandatory !";
        return;
      }
      this.isThereAnyUpdate = true;

      this.sendToBackend();
    },

    sendToBackend() {
      // @apinote ListAPI
      this.$http
        .post(
          "lists/" +
            this.portfolioId +
            "/stocks/" +
            this.holdingParameters.equityUuid +
            "/stop-loss",
          this.newHoldingParameters
        )
        .then(
          () => {},
          () => {
            // TODO: Manage error
          }
        );
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep .v-tiptap .v-label {
  color: var(--v-secondary-lighten5) !important;
}
// To get complete list of available variables https://github.com/vuetifyjs/vuetify/issues/11783
::v-deep .theme--dark.v-btn.v-btn--icon {
  color: var(--v-secondary-lighten3);
}
</style>
