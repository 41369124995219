<template>
  <v-container>
    <v-row dense>
      <v-col cols="6">
        <PortfolioHistoryECharts :curList="curList" />
      </v-col>
      <v-col cols="6">
        <portfolio-performance-e-charts :curList="curList" />
      </v-col>
      <v-col cols="6">
        <PortfolioPerfPerMonthECharts :curList="curList" />
      </v-col>
      <v-col cols="6">
        <PortfolioPerfPerYearECharts :curList="curList" />
      </v-col>
      <v-col cols="6">
        <portfolio-per-sector-e-charts
          v-if="!curList.trackerPortfolio"
          :curList="curList"
          :currentPosition="currentPosition"
        />
      </v-col>
      <v-col cols="6">
        <portfolio-per-industry-e-charts
          v-if="!curList.trackerPortfolio"
          :curList="curList"
          :currentPosition="currentPosition"
        />
      </v-col>
      <v-col cols="6">
        <portfolio-dividend-per-month-e-charts :curList="curList" />
      </v-col>
      <v-col cols="6">
        <portfolio-dividend-per-year-e-charts :curList="curList" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PortfolioPerfPerMonthECharts from "@/components/portfolio-detail/graphs/PortfolioPerfPerMonthECharts.vue";
import PortfolioPerfPerYearECharts from "@/components/portfolio-detail/graphs/PortfolioPerfPerYearECharts.vue";
import PortfolioHistoryECharts from "@/components/portfolio-detail/graphs/PortfolioHistoryECharts.vue";
import PortfolioPerformanceECharts from "@/components/portfolio-detail/graphs/PortfolioPerformanceECharts.vue";
import PortfolioPerSectorECharts from "@/components/portfolio-detail/graphs/PortfolioPerSectorECharts.vue";
import PortfolioPerIndustryECharts from "@/components/portfolio-detail/graphs/PortfolioPerIndustryECharts.vue";
import PortfolioDividendPerMonthECharts from "@/components/portfolio-detail/graphs/PortfolioDividendPerMonthECharts.vue";
import PortfolioDividendPerYearECharts from "@/components/portfolio-detail/graphs/PortfolioDividendPerYearECharts.vue";

export default {
  name: "GraphTab",
  props: ["curList", "currentPosition"],
  data: function () {
    return {};
  },
  components: {
    PortfolioPerfPerMonthECharts,
    PortfolioPerfPerYearECharts,
    PortfolioHistoryECharts,
    PortfolioPerSectorECharts,
    PortfolioPerIndustryECharts,
    PortfolioDividendPerMonthECharts,
    PortfolioDividendPerYearECharts,
    PortfolioPerformanceECharts,
  },
};
</script>
<style scoped>
</style>