<template>
  <tbody>
    <line-growth-graph
      :title="graphTitle"
      :attributeValue="graphAttribute"
      :displayGrowth="graphDisplayGrowth"
      :periodType="dataPeriod"
      :dataset="graphDataset"
      v-model="showLineGrowthGraphDialog"
    >
    </line-growth-graph>
    <bar-graph
      :title="graphTitle"
      :attributeValue="graphAttribute"
      :periodType="dataPeriod"
      :dataset="graphDataset"
      :isPercentage="graphIsPercentage"
      v-model="showBarGraphDialog"
    >
    </bar-graph>
    <tr class="blue lighten-2">
      <td width="200"></td>
      <td width="120">Obj</td>
      <td v-for="date in dates" :key="date">
        {{ date | toDate }}
      </td>
    </tr>
    <!-- Cash -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="balSheetAttPerDateFn('cash')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openLineGrowthGraphDialog('Cash', 'cash', true, stock.balanceSheet)
          "
          >Cash
        </span>
      </template>
      <template v-slot:rowTarget>
        <v-icon small>mdi-arrow-top-right</v-icon>
      </template>
    </financial-tab-data-per-date>

    <!-- Inventory -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="balSheetAttPerDateFn('inventory')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openLineGrowthGraphDialog(
              'Inventaire',
              'inventory',
              true,
              stock.balanceSheet
            )
          "
        >
          <tooltip-comment label="Inventory">
            Le risque d'obscolescence des stocks est-il présent ? Les produits
            que vend l'entreprise sont-ils sujet à modification ?
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget> Stable </template>
    </financial-tab-data-per-date>

    <!-- LT debt to net income ratio -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('longTermDebtNetIncomeRatio', false)"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'LT Debt/Net income',
              'longTermDebtNetIncomeRatio',
              stock.fundamentalIndicators,
              (isPercentage = false)
            )
          "
        >
          LT Debt/Net income
        </span>
      </template>
      <template v-slot:rowTarget> &lt; 4 </template>
    </financial-tab-data-per-date>

    <!-- Debt to equity ratio -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('debtToEquityRatio')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'Total Debt/Equity',
              'debtToEquityRatio',
              stock.fundamentalIndicators,
              (isPercentage = true)
            )
          "
        >
          <tooltip-comment label="Total Debt/Equity">
            L'entreprise a-t-elle plus de dette que de capitaux propres ?
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget>&lt; 100%</template>
    </financial-tab-data-per-date>

    <!-- Return on equity -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('roe')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'ROE',
              'roe',
              stock.fundamentalIndicators,
              (isPercentage = true)
            )
          "
        >
          <tooltip-comment label="ROE">
            Si le % est élevé cela veut dire que l'entreprise fait un bon usage
            des bénéfices non distribués. (Résultat net / Capitaux propres)
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget>&gt; 15%</template>
    </financial-tab-data-per-date>

    <!-- Return on invested capital -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('roic')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openBarGraphDialog(
              'ROIC',
              'roic',
              stock.fundamentalIndicators,
              (isPercentage = true)
            )
          "
        >
          <tooltip-comment label="ROIC">
            Si le % est élevé cela veut dire que l'entreprise fait un bon usage
            des capitaux investis (Dette + Capitaux propres). ROIC = (NetIncome
            - Dividends) / (Debt + Equity - Cash)
          </tooltip-comment>
        </span>
      </template>
      <template v-slot:rowTarget>&gt; 10%</template>
    </financial-tab-data-per-date>

    <!-- Benefices non distribues -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="fundAttPerDateFn('retainedEarningGrowth')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openLineGrowthGraphDialog(
              'Retained earning',
              'retainedEarnings',
              true,
              stock.balanceSheet
            )
          "
          >Retained earning
        </span>
      </template>
      <template v-slot:rowTarget>
        <v-icon small>mdi-arrow-top-right</v-icon>
      </template>
    </financial-tab-data-per-date>

    <!-- Shares outstanding -------------------------------------------------------------------------------->
    <financial-tab-data-per-date
      :dates="dates"
      :dateDataFn="balSheetAttPerDateFn('sharesOutstanding')"
      :applyColorClass="false"
    >
      <template v-slot:rowTitle>
        <span
          @click="
            openLineGrowthGraphDialog(
              'Shares outstanding',
              'sharesOutstanding',
              true,
              stock.balanceSheet
            )
          "
          >Shares outstanding
        </span>
      </template>
      <template v-slot:rowTarget>
        <v-icon small>mdi-arrow-bottom-right</v-icon>
      </template>
    </financial-tab-data-per-date>
  </tbody>
</template>

<script>
import TooltipComment from "@/components/commons/TooltipComment.vue";
import FinancialTabDataPerDate from "@/components/stock-detail/financial/DataPerDate.vue";
import LineGrowthGraph from "@/components/stock-detail/financial/graphs/LineGrowthGraph.vue";
import BarGraph from "@/components/stock-detail/financial/graphs/BarGraph.vue";

export default {
  name: "FinancialTabBalanceSheet",
  props: ["stock", "dataPeriod"],
  watch: {},
  data: function () {
    return {
      showLineGrowthGraphDialog: false,
      showBarGraphDialog: false,
      graphAttribute: "revenue",
      graphDataset: [],
      graphTitle: "",
      graphIsPercentage: true,
      graphDisplayGrowth: false,
    };
  },
  computed: {
    dates() {
      return Object.keys(this.balanceSheet).sort().reverse();
    },
    balanceSheet() {
      let result = this.stock.balanceSheet
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
    fundamentalIndicators() {
      let result = this.stock.fundamentalIndicators
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
  },
  methods: {
    openLineGrowthGraphDialog(title, attribute, displayGrowth, dataset) {
      this.graphTitle = title;
      this.graphAttribute = attribute;
      this.graphDisplayGrowth = displayGrowth;
      this.graphDataset = dataset;
      this.showLineGrowthGraphDialog = true;
    },
    openBarGraphDialog(title, attribute, dataset, isPercentage = true) {
      this.graphTitle = title;
      this.graphAttribute = attribute;
      this.graphDataset = dataset;
      this.graphIsPercentage = isPercentage;
      this.showBarGraphDialog = true;
    },
    fundAttPerDateFn(attribute, isPercentage = true) {
      return (date) => {
        return this.$options.filters.toFloat(
          this.fundamentalIndicators[date][attribute],
          isPercentage ? 100 : 1,
          isPercentage ? "%" : ""
        );
      };
    },
    balSheetAttPerDateFn(attribute) {
      return (date) => {
        return this.balanceSheet[date][attribute];
      };
    },
    curRatiosAttFn(attribute) {
      return () => {
        return this.$options.filters.toFloat(
          this.stock.currentRatios[attribute],
          100,
          "%"
        );
      };
    },
  },
  components: {
    TooltipComment,
    FinancialTabDataPerDate,
    LineGrowthGraph,
    BarGraph,
  },
};
</script>
<style lang="scss" scoped>
</style>
