<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Ajouter à une watchlist</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="selectedList"
                  :items="lists"
                  color="white"
                  autofocus
                  item-text="label"
                  return-object
                  label="Nom de la liste"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small>mdi-binoculars</v-icon>&nbsp;&nbsp;{{
                          data.item.label
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Watchlist </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="12"
                class="red darken-1 text-center"
                v-if="errMessage"
                ><span class="white--text"
                  >Error : {{ errMessage }}.</span
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="show = false"
          :disabled="syncLoading"
          >Annuler</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :disabled="syncLoading || !selectedList"
          ref="confirmButton"
          @click="save()"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddStockToWatchlistDialog",
  props: ["value", "stock"],

  data: () => ({
    errMessage: null,
    lists: [],
    selectedList: undefined,
    syncLoading: false,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    save: function () {
      this.syncLoading = true;

      // @apiNote WatchlistAPI
      this.$http
        .post("watchlists/" + this.selectedList.id + "/stocks/" + this.stock.id)
        .then(
          () => {
            this.$emit("updateLists", "WATCHLIST", this.selectedList.label);
            this.syncLoading = false;
            this.show = false;
          },
          (error) => {
            console.error("ERROR : ", error.response.data);
            this.errMessage = error.response.data;
            this.syncLoading = false;
          }
        );
    },
    getWatchlists() {
      // @apiNote WatchlistAPI
      this.$http.get("watchlists").then((response) => {
        this.lists = response.data;
      });
    },
  },
  mounted() {
    this.getWatchlists();
  },
};
</script>
