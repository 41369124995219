var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"outlined":"","timeout":"2000"},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),(_vm.ivDto)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{on:{"click":function($event){_vm.showValuationScoreDialog = true}}},[_vm._v(" mdi-information-outline ")]),_c('valuation-score-dialog',{model:{value:(_vm.showValuationScoreDialog),callback:function ($$v) {_vm.showValuationScoreDialog=$$v},expression:"showValuationScoreDialog"}})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('h2',[_vm._v("Valuation indicators")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{class:_vm.getBetweenClass(
                    0,
                    _vm.historicalPE,
                    _vm.stock.currentRatios.peTTM,
                    'green px-3',
                    'red px-3'
                  ),attrs:{"title":"Inférieur à son PER historique"}},[_vm._v(" PER ")])]),_c('v-card-text',[_c('v-expansion-panels',{attrs:{"accordion":"","dark":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm.stock.currentRatios.peTTM)+" "),_c('span',{staticClass:"text-subtitle-1 grey--text",attrs:{"title":"Industry PE"}},[_vm._v(" ("+_vm._s(_vm.stock.currentRatios.peTTM_industry)+") ")])])])],1)],1),_c('v-expansion-panel-content',[_c('v-text-field',{attrs:{"label":"Historical PE (from ZB)","tpe":"number"},model:{value:(_vm.historicalPE),callback:function ($$v) {_vm.historicalPE=$$v},expression:"historicalPE"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{class:_vm.getBetweenClass(
                    0,
                    _vm.historicalEvToEbitda,
                    _vm.stock.myCustomIndicators.evToEbitda,
                    'green px-3',
                    'red px-3'
                  ),attrs:{"title":"Inférieur à son EV/EBITDA historique"}},[_vm._v(" EV / EBITDA ")])]),_c('v-card-text',[_c('v-expansion-panels',{attrs:{"accordion":"","dark":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm._f("toFloat")(_vm.stock.myCustomIndicators.evToEbitda))+" ")])])],1)],1),_c('v-expansion-panel-content',[_c('v-text-field',{attrs:{"label":"Historical EV to EBITDA (from ZB)","tpe":"number"},model:{value:(_vm.historicalEvToEbitda),callback:function ($$v) {_vm.historicalEvToEbitda=$$v},expression:"historicalEvToEbitda"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{class:_vm.getBetweenClass(
                    0,
                    2,
                    _vm.stock.myCustomIndicators.peg,
                    'green px-3',
                    'red px-3'
                  ),attrs:{"title":"Entre 0 et 2"}},[_vm._v(" PEG ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm._f("toFloat")(_vm.stock.myCustomIndicators.peg))+" ")])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{class:_vm.getBetweenClass(
                    0,
                    2,
                    _vm.stock.currentRatios.priceToBookMRQ,
                    'green px-3',
                    'red px-3'
                  ),attrs:{"title":"< à 2"}},[_vm._v(" P/B ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm._f("toFloat")(_vm.stock.currentRatios.priceToBookMRQ))+" "),_c('span',{staticClass:"text-subtitle-1 grey--text",attrs:{"title":"Industry PE"}},[_vm._v(" ("+_vm._s(_vm.stock.currentRatios.priceToBookMRQ_industry)+") ")])])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{class:_vm.getBetweenClass(
                    0,
                    7,
                    _vm.stock.currentRatios.priceToCashFlowMRQ,
                    'green px-3',
                    'red px-3'
                  ),attrs:{"title":"< à 7"}},[_vm._v(" P/CF ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 text-center"},[_vm._v(" "+_vm._s(_vm._f("toFloat")(_vm.stock.currentRatios.priceToCashFlowMRQ))+" "),_c('span',{staticClass:"text-subtitle-1 grey--text",attrs:{"title":"Industry PE"}},[_vm._v(" ("+_vm._s(_vm.stock.currentRatios.priceToCashFlowMRQ_industry)+") ")])])])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Calculette de valeur intrinsèque")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('iv-calculator',{attrs:{"uuid":_vm.uuid,"currentPrice":_vm.stock.lastPrice.price,"currency":_vm.stock.currency}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Valeur intrinsèque de MorningStar")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Dernière valeur "),(_vm.ivDto.intrinsicValueLastUpdate)?_c('span',[_vm._v(" mise à jour le "+_vm._s(_vm._f("toDate")(_vm.ivDto.intrinsicValueLastUpdate))+" ")]):_vm._e(),(!_vm.syncInProgress)?_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","color":"blue lighten-2","title":"Forcer la mise à jour"},on:{"click":_vm.refreshIntrinsicValue}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1):_vm._e(),(_vm.syncInProgress)?_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","color":"blue lighten-2"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":2,"size":20}})],1):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("toFloat")(_vm.ivDto.intrinsicValue,1, " €"))+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-chip',{class:_vm.getClassComplete(
                0.000001,
                _vm.ivDto.diffIntrinsicValue,
                'success ma-2',
                'error -ma-2'
              ),attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm._f("toFloat")(_vm.ivDto.diffIntrinsicValue,100, " %"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-tiptap',{attrs:{"dark":_vm.dark,"toolbar":[
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'color',
                      '|',
                      'h1',
                      'h2',
                      'h3',
                      '|',
                      'emoji',
                    ],"dense":"true"},model:{value:(_vm.valuationComment),callback:function ($$v) {_vm.valuationComment=$$v},expression:"valuationComment"}})],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }