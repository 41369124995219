<!--
Explications sur la note de valorisation
-->
<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Note de valorisation</span>
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item>PER courant &lt; PER historique</v-list-item>
          <v-list-item>EV/EBITDA courant &lt; EV/EBITDA historique</v-list-item>
          <v-list-item>PEG &lt; 2</v-list-item>
          <v-list-item>P/B &lt; 2</v-list-item>
          <v-list-item>P/CF &lt; 7</v-list-item>
          <v-list-item>Prix courant &lt; valeur intrinsèque</v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" plain @click="show = false"> Fermer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value"],

  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
</style>