<template>
  <span>
    <v-btn icon v-if="!displayUp" @click="displayUp = true">
      <v-icon>mdi-chevron-down</v-icon>
    </v-btn>
    <v-btn icon v-if="displayUp" @click="displayUp = false">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </span>
</template>
<script>
export default {
  name: "UpDownChevron",
  props: ["value"],
  computed: {
    displayUp: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>