<template>
  <div v-if="curList">
    <add-single-transaction-dialog
      @updateTransactionList="getData"
      :list="curList"
      v-model="showAddSingleTransactionDialog"
    />
    <import-transactions-dialog
      @updateTransactionList="getData"
      :list="curList"
      v-model="showImportTransactionsDialog"
    />

    <v-speed-dial
      v-model="speedDialFab"
      bottom
      right
      fixed
      open-on-hover
      transition="scale-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="speedDialFab" color="red accent-3" dark fab>
          <v-icon> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showAddSingleTransactionDialog = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="green"
        @click="showImportTransactionsDialog = true"
      >
        <v-icon>mdi-cloud-upload-outline</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row>
      <v-col cols="4">
        <h1>
          <v-icon large>mdi-briefcase-outline</v-icon>&nbsp;&nbsp;{{
            curList.label
          }}
          &nbsp;
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </h1>
      </v-col>
      <v-col cols="3">
        <v-list outlined>
          <v-list-item two-line v-if="!isLoading">
            <v-list-item-content>
              <v-list-item-subtitle>
                Invested
                <span v-if="curList.cashManagementEnabled"> / Cash </span>
              </v-list-item-subtitle>
              <v-list-item-title class="text-h6">
                {{
                  currentPosition.invested | toFloat(1, " " + currencySymbol)
                }}
                <span v-if="curList.cashManagementEnabled">
                  /
                  {{ currentPosition.cash | toFloat(1, "  " + currencySymbol) }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="3">
        <v-list outlined>
          <v-list-item two-line v-if="!isLoading">
            <v-list-item-content>
              <v-list-item-subtitle>
                Valorisation LastClose
              </v-list-item-subtitle>

              <v-list-item-title :class="getClass(plusMoinsValLC)">
                {{ currentPosition.value | toFloat(1, " " + currencySymbol) }}
                /
                <span>
                  {{ plusMoinsValLC | toFloat(1, " " + currencySymbol) }}

                  <span class="text-caption">
                    ({{ plusMoinsPercentageLC | toFloat(100, " %") }})
                  </span>
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="2">
        <v-list outlined>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>+/- value today</v-list-item-subtitle>
              <v-list-item-title
                :class="getClass(plusMoinsValCurrent)"
                v-if="currentPrices"
              >
                {{ plusMoinsValCurrent | toFloat(1, " " + currencySymbol) }} /
                {{ plusMoinsPercentageCurrent | toFloat(100, " %") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs
          v-model="curTab"
          background-color="transparent"
          grow
          color="white"
        >
          <v-tab href="#tab-1">Position courante</v-tab>
          <v-tab href="#tab-7"> Performance </v-tab>
          <v-tab href="#tab-3" v-if="!curList.trackerPortfolio">
            Conditions de vente
          </v-tab>
          <v-tab href="#tab-4"> Graphiques </v-tab>
          <v-tab href="#tab-2">Carnet</v-tab>
          <v-tab href="#tab-5" v-if="curList.allocationManagementEnabled"
            >Allocation</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="curTab">
          <v-tab-item value="tab-1">
            <current-position-tab
              :curList="curList"
              :currentPrices="currentPrices"
              :currentPosition="currentPosition"
            ></current-position-tab>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <carnet-tab :curList="curList" @updateList="getData"></carnet-tab>
          </v-tab-item>
          <v-tab-item value="tab-3" v-if="!curList.trackerPortfolio">
            <selling-tab
              @reloadPortfolio="getData"
              :curList="curList"
            ></selling-tab>
          </v-tab-item>
          <v-tab-item value="tab-4">
            <graph-tab
              :curList="curList"
              :currentPosition="currentPosition"
            ></graph-tab>
          </v-tab-item>
          <v-tab-item value="tab-5">
            <allocation-tab
              :curList="curList"
              :currentPosition="currentPosition"
            ></allocation-tab>
          </v-tab-item>

          <v-tab-item value="tab-7">
            <common-performance-tab
              :portfolio="curList"
              :equityUuidList="equityUuidList"
              :indexEquities="curList.indexEquities"
            ></common-performance-tab>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import AllocationTab from "@/components/portfolio-detail/AllocationTab.vue";
import CurrentPositionTab from "@/components/portfolio-detail/CurrentPositionTab.vue";
import CarnetTab from "@/components/portfolio-detail/CarnetTab.vue";
import SellingTab from "@/components/portfolio-detail/SellingTab.vue";
import GraphTab from "@/components/portfolio-detail/GraphTab.vue";
import CommonPerformanceTab from "@/components/commons/PerformanceTab.vue";
import AddSingleTransactionDialog from "@/components/portfolio-detail/dialogs/AddSingleTransactionDialog.vue";
import ImportTransactionsDialog from "@/components/portfolio-detail/dialogs/ImportTransactionsDialog.vue";

export default {
  name: "PortfolioDetail",
  props: ["uuid"],

  data: () => ({
    id: undefined,
    curList: undefined,
    currentPrices: null,
    currentPosition: null,
    curTab: undefined,
    isLoading: true,
    speedDialFab: false,
    showAddSingleTransactionDialog: false,
    showImportTransactionsDialog: false,
  }),
  watch: {},
  computed: {
    pricesByUuid: function () {
      let result = this.curList.prices.reduce((obj, item) => {
        obj[item.equityUuid] = item;
        return obj;
      }, {});
      return result;
    },
    equityUuidList() {
      if (!this.currentPosition) {
        return [];
      }
      return this.currentPosition.lines.map((oneLine) => oneLine.equityUuid);
    },
    currentValo() {
      if (!this.currentPrices) {
        return undefined;
      }
      let result = 0;
      if (this.currentPosition) {
        // Sum Current valo of each line
        result = this.currentPosition.lines.reduce((previous, current) => {
          let newResult = previous + this.getCurrentValue(current);
          return newResult;
        }, 0);
        if (this.curList.cashManagementEnabled) {
          // Add remaining cash
          result += this.currentPosition.cash;
        }
      }
      return result;
    },

    plusMoinsValLC() {
      return this.currentPosition.value - this.currentPosition.invested;
    },
    plusMoinsValCurrent() {
      return this.currentValo - this.currentPosition.value;
    },
    plusMoinsPercentageLC() {
      return this.currentPosition.invested !== 0
        ? this.plusMoinsValLC / this.currentPosition.invested
        : 0;
    },
    plusMoinsPercentageCurrent() {
      return this.currentPosition.value !== 0
        ? this.plusMoinsValCurrent / this.currentPosition.value
        : 0;
    },
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
  },
  methods: {
    // ******************************* Utility methods
    getClass: function (value) {
      let result = "text-h6";
      if (value.toFixed(2) > 0) {
        result += " success--text";
      } else if (value.toFixed(2) < 0) {
        result += " error--text";
      }
      return result;
    },
    // Last close value of specified position
    getLCValue: function (positionLine) {
      let lcVal =
        positionLine.quantity *
        this.pricesByUuid[positionLine.equityUuid].lastClosePrice;
      return lcVal;
    },
    // Current value of specified position
    getCurrentValue: function (positionLine) {
      let currentVal =
        positionLine.quantity *
        this.currentPrices[positionLine.equityUuid].price;
      return currentVal;
    },
    // ******************************* API methods
    getData() {
      this.isLoading = true;
      var p = Promise.resolve();

      p = p.then(() => {
        // @apiNote ListAPI
        return this.$http.get("lists/" + this.uuid).then((response) => {
          this.curList = response.data;
          document.title = "PF: " + this.curList.label;
        });
      });

      p = p.then(() => {
        // @apiNote ListAPI
        return this.$http
          .get("lists/" + this.uuid + "/current-position")
          .then((response) => {
            this.currentPosition = response.data;
          });
      });

      p = p.then(() => {
        // @apiNote ListAPI
        return this.$http
          .put("lists/" + this.uuid + "/refresh-current-prices")
          .then((response) => {
            this.currentPrices = response.data;
          });
      });

      p.then(() => {
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    CurrentPositionTab,
    CarnetTab,
    AddSingleTransactionDialog,
    SellingTab,
    GraphTab,
    AllocationTab,
    ImportTransactionsDialog,
    CommonPerformanceTab,
  },
};
</script>
<style lang="scss">
.v-tabs-items {
  padding-top: 15px;
}
</style>