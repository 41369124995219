var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"outlined":"","timeout":"2000"},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-card-title',[_c('v-row',[_c('v-col',[(_vm.totalTargetAttribution !== 100)?_c('h5',{staticClass:"red--text"},[_vm._v(" Attention: somme des allocations différentes de 100% ("+_vm._s(_vm.totalTargetAttribution)+"%). ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Somme à allouer","dense":"","hide-details":""},model:{value:(_vm.sumToAllocate),callback:function ($$v) {_vm.sumToAllocate=$$v},expression:"sumToAllocate"}})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.sumAllocated && _vm.sumAllocated != 0)?_c('h5',{staticClass:"green--text"},[_vm._v(" Somme allouée: "+_vm._s(_vm._f("toFloat")(_vm.sumAllocated,1, _vm.currencySymbol))+". ")]):_vm._e()])],1)],1),(_vm.curList)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.dataTableRows,"items-per-page":-1,"hide-default-footer":true,"sort-by":"name"},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [(!_vm.curList.trackerPortfolio)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'StockDetail',
          params: { uuid: item.equityUuid },
        }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(_vm.curList.trackerPortfolio)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'TrackerDetail',
          params: { uuid: item.equityUuid },
        }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:`item.invest`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.invest,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.lastCloseValue`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("toFloat")(item.lastCloseValue,1, " " + _vm.currencySymbol)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDate")(item.lastCloseDate)))])])]}},{key:`item.lastCloseValo`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.lastCloseValo,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.lastClosePMPercent`,fn:function({ item }){return [_c('span',{class:_vm.getClass(item.lastClosePMPercent)},[_vm._v(_vm._s(_vm._f("toFloat")(item.lastClosePMPercent,100, " %")))])]}},{key:`item.lastCloseAllocation`,fn:function({ item }){return [_c('span',{class:item.targetAllocation
            ? _vm.getClass(100 * item.lastCloseAllocation, item.targetAllocation)
            : ''},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.lastCloseAllocation,100, " %"))+" ")])]}},{key:`item.targetAllocation`,fn:function({ item }){return [(!_vm.allocationEditing || _vm.allocationEditing !== item.id)?_c('span',[_c('span',{staticClass:"float-left"},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.targetAllocation,1, "%"))+" ")]),_c('a',{staticClass:"float-right",on:{"click":function($event){_vm.allocationEditing = item.id;
            _vm.newAllocation = item.targetAllocation;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]):_vm._e(),(_vm.allocationEditing && _vm.allocationEditing === item.id)?_c('span',[_c('v-text-field',{attrs:{"single-line":"","dense":"","height":"20","type":"number"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.updateTargetAllocation(item)}}},[_c('v-icon',{attrs:{"x-small":"","color":"green lighten-1"}},[_vm._v(" mdi-check-circle ")])],1),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.allocationEditing = undefined;
                _vm.newAllocation = undefined;}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-close-circle")])],1)]},proxy:true}],null,true),model:{value:(_vm.newAllocation),callback:function ($$v) {_vm.newAllocation=$$v},expression:"newAllocation"}})],1):_vm._e()]}},{key:`item.toBuyCount`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.toBuyCount))])]}}],null,true)},[_c('span',[_vm._v(" Target valo: "+_vm._s(_vm._f("toFloat")(item.newTargetValo,1, _vm.currencySymbol))),_c('br'),_vm._v(" Diff valo: "+_vm._s(_vm._f("toFloat")((item.newTargetValo - item.lastCloseValo),1, _vm.currencySymbol))),_c('br'),_vm._v(" Target count: "+_vm._s(_vm._f("toFloat")(item.newTargetEquityCount))),_c('br'),_vm._v(" Target float part: "+_vm._s(_vm._f("toFloat")(item.toBuyCountFloatPart))),_c('br')])])]}}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }