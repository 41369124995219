<template>
  <div class="home">
    <v-card>
      <v-card-title>
        <v-chip class="ma-2" color="secondary">
          {{ totalCount }} trackers
        </v-chip>
        <!-- Display only in portfolio ? -->
        <v-btn class="mx-2" depressed fab @click="clickOnPortfolioFilter">
          <v-icon
            :class="
              displayOnlyInPortoflio
                ? 'amber--text text-h3'
                : 'grey--text text-h3'
            "
          >
            mdi-briefcase-outline
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <!-- ----------------------------------------------------------------------- -->
        <!-- Search field -->
        <!-- ----------------------------------------------------------------------- -->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- ----------------------------------------------------------------------- -->
      <!-- Data table -->
      <!-- ----------------------------------------------------------------------- -->
      <v-data-table
        :headers="headers"
        :items="dataToDisplay"
        :items-per-page="-1"
        :search="search"
        :hide-default-footer="true"
        :custom-filter="customFilter"
        class="elevation-1"
      >
        <template v-slot:[`item.longName`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="{ name: 'TrackerDetail', params: { uuid: item.id } }"
          >
            {{ item.longName }}
          </router-link>
          <i class="grey--text text-caption"> {{ item.yticker }} </i>
        </template>

        <template v-slot:[`item.inPortfolio`]="{ item }">
          <router-link
            v-for="onePortfolio in getListsByType(item, 'PORTFOLIO')"
            :key="onePortfolio.id"
            style="text-decoration: none"
            :to="{ name: 'PortfolioDetail', params: { uuid: onePortfolio.id } }"
          >
            <v-icon :title="onePortfolio.label"> mdi-briefcase-outline </v-icon>
          </router-link>
        </template>
        <template v-slot:[`item.inWatchlist`]="{ item }">
          <router-link
            v-for="oneWatchlist in item.watchlists"
            :key="oneWatchlist.id"
            style="text-decoration: none"
            :to="{ name: 'WatchlistDetail', params: { uuid: oneWatchlist.id } }"
          >
            <v-icon :title="oneWatchlist.label"> mdi-binoculars </v-icon>
          </router-link>
        </template>

        <template v-slot:[`item.annualCost`]="{ item }">
          {{ item.annualCost | toFloat(100, " %") }}<br />
        </template>
        <template v-slot:[`item.capitalization`]="{ item }">
          {{ item.capitalization | toFloat(1, " M" + currencySymbol(item))
          }}<br />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "TrackerList",
  watch: {},
  data: () => ({
    search: "",
    displayOnlyInPortoflio: false,
    headers: [
      { text: "Name", value: "longName" },
      { text: "Ann Cost", value: "annualCost", filterable: false },
      {
        text: "Cap",
        value: "capitalization",
        align: "right",
        filterable: false,
      },
      { text: "Actions", value: "actions", filterable: false },
    ],
    syncLoading: false,
    trackers: [],
  }),
  computed: {
    totalCount() {
      return this.dataToDisplay.length;
    },
    dataToDisplay() {
      let filteredArray = [...this.trackers];

      return filteredArray;
    },
  },
  methods: {
    customFilter(value, search, item) {
      // console.log("customerFilter : ", value, search, item);
      if (
        value &&
        search &&
        value.toUpperCase().includes(search.toUpperCase())
      ) {
        return true;
      }
      if (
        item &&
        item.yticker &&
        search &&
        item.yticker.toUpperCase().includes(search.toUpperCase())
      ) {
        return true;
      }
      if (
        item &&
        item.isin &&
        search &&
        item.isin.toUpperCase().includes(search.toUpperCase())
      ) {
        return true;
      }

      return false;
    },
    currencySymbol(equity) {
      if (equity.currency === "EUR") {
        return "€";
      } else if (equity.currency === "USD") {
        return "$";
      }
      return "???";
    },
    clickOnPortfolioFilter() {
      this.displayOnlyInWatchlist = false;
      this.displayOnlyInPortoflio = !this.displayOnlyInPortoflio;
    },
    getData() {
      // @apiNote TrackerAPI
      this.$http.get("trackers").then((response) => {
        this.trackers = response.data;
      });
    },
    getListsByType(oneStock, listType) {
      let listToKeep = oneStock.lists.filter((oneList) => {
        return oneList && oneList.type === listType;
      });
      return listToKeep;
    },
  },

  mounted() {
    this.getData();
  },
  components: {},
};
</script>
