<template>
  <v-data-table
    v-if="curList"
    dense
    :headers="tableHeaders"
    :items="dataTableRows"
    :items-per-page="-1"
    :hide-default-footer="true"
    :loading="isLoading"
    sort-by="name"
    class="elevation-1"
  >
    <!-- Name -------------------------------------- -->
    <template v-slot:[`item.name`]="{ item }">
      <router-link
        v-if="!curList.trackerPortfolio"
        style="text-decoration: none"
        :title="item.name"
        :to="{
          name: 'StockDetail',
          params: { uuid: item.equityUuid },
        }"
      >
        {{ item.name }}
      </router-link>
      <router-link
        v-if="curList.trackerPortfolio"
        style="text-decoration: none"
        :title="item.name"
        :to="{
          name: 'TrackerDetail',
          params: { uuid: item.equityUuid },
        }"
      >
        {{ item.name }}
      </router-link>
    </template>

    <!-- UCP -------------------------------------- -->
    <template v-slot:[`item.ucp`]="{ item }">
      {{ item.ucp | toFloat(1, " " + currencySymbol) }}
    </template>

    <!-- Quantity -------------------------------------- -->
    <template v-slot:[`item.quantity`]="{ item }">
      <span v-if="Number.isInteger(item.quantity)">{{ item.quantity }}</span>
      <span v-if="!Number.isInteger(item.quantity)">
        {{ item.quantity | toFloat }}
      </span>
    </template>
    <template v-slot:[`item.invest`]="{ item }">
      {{ item.invest | toFloat(1, " " + currencySymbol) }}
    </template>

    <template v-slot:[`item.lastCloseValue`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{
            item.lastCloseValue | toFloat(1, " " + currencySymbol)
          }}</span>
        </template>
        <span>{{ item.lastCloseDate | toDate }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.lastCloseDiff`]="{ item }">
      <span :class="getClass(item.lastCloseDiff)">{{
        item.lastCloseDiff | toFloat(100, " %")
      }}</span>
    </template>
    <template v-slot:[`item.lastClosePMValue`]="{ item }">
      <span :class="getClass(item.lastClosePMValue)">{{
        item.lastClosePMValue | toFloat(1, " " + currencySymbol)
      }}</span>
    </template>

    <template v-slot:[`item.nextEarningDate`]="{ item }">
      {{ item.nextEarningDate | toDate }}
    </template>
    <template v-slot:[`item.lastClosePMPercent`]="{ item }">
      <span :class="getClass(item.lastClosePMPercent)">{{
        item.lastClosePMPercent | toFloat(100, " %")
      }}</span>
    </template>
    <template v-slot:[`item.lastCloseAllocation`]="{ item }">
      {{ item.lastCloseAllocation | toFloat(100, " %") }}
    </template>
    <template v-slot:[`item.currentValue`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{
            item.currentValue | toFloat(1, " " + currencySymbol)
          }}</span>
        </template>
        <span>{{ item.lastPriceUpdate | toDateTime }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.diffLastClose`]="{ item }">
      <span :class="getClass(item.diffLastClose)">{{
        item.diffLastClose | toFloat(100, " %")
      }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "CurrentPositionTab",
  props: ["curList", "currentPrices", "currentPosition"],
  watch: {
    currentPosition: function (val) {
      if (val && val.lines) {
        this.getData();
      }
    },
  },
  data: function () {
    return {
      isLoading: true,
      equities: {},
      latestDailyPrices: {},
    };
  },
  computed: {
    tableHeaders() {
      let headers = [{ text: "Ticker", value: "yticker" }];
      if (this.curList.trackerPortfolio) {
        headers.push({ text: "Custom", value: "customName" });
      }
      headers = headers.concat([
        {
          text: "Name",
          value: "name",
          class: "text-truncate",
          cellClass: "text-truncate",
        },
        { text: "Quantité", value: "quantity", align: "right" },
        {
          text: "PRU",
          value: "ucp",
          align: "right",
          class: "blue",
        },
        { text: "Invest", value: "invest", align: "right", class: "blue" },
        {
          text: "LC VU",
          value: "lastCloseValue",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC Diff %",
          value: "lastCloseDiff",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC +/- val",
          value: "lastClosePMValue",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC +/- %",
          value: "lastClosePMPercent",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC alloc",
          value: "lastCloseAllocation",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "Curr VU",
          value: "currentValue",
          align: "right",
          class: "success",
        },
        {
          text: "Diff / LC",
          value: "diffLastClose",
          align: "right",
          class: "success",
        },
      ]);
      if (!this.curList.trackerPortfolio) {
        headers.push({
          text: "Next pub",
          value: "nextEarningDate",
          align: "right",
        });
      }

      return headers;
    },
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
    totalLCValue: function () {
      let result = this.currentPosition.lines.reduce((previous, current) => {
        let newResult = previous + this.getLCValue(current);
        return newResult;
      }, 0);
      return result;
    },

    dataTableRows() {
      if (this.isLoading) {
        return [];
      }
      let result = [...this.currentPosition.lines];

      result.forEach((positionLine) => {
        positionLine.name = this.equities[positionLine.equityUuid].name;
        positionLine.customName =
          this.equities[positionLine.equityUuid].customName;
        positionLine.equityUuid = this.equities[positionLine.equityUuid].id;
        positionLine.isin = this.equities[positionLine.equityUuid].isin;
        positionLine.yticker = this.equities[positionLine.equityUuid].yticker;
        positionLine.invest = this.getInvestedValue(positionLine);

        positionLine.lastCloseDate =
          this.latestDailyPrices[positionLine.equityUuid][0].date;
        positionLine.lastCloseValue =
          this.latestDailyPrices[positionLine.equityUuid][0].price;

        positionLine.lastClosePMValue = this.getLCPlusMinusValue(positionLine);

        positionLine.nextEarningDate =
          this.equities[positionLine.equityUuid].nextEarningDate;

        positionLine.lastCloseDiff = this.getLastClosePerf(positionLine);
        positionLine.lastClosePMPercent =
          this.getLCPlusMinusPercent(positionLine);
        positionLine.lastCloseAllocation = this.getLCAllocation(positionLine);

        positionLine.currentValue = this.currentPrices
          ? this.currentPrices[positionLine.equityUuid].price
          : null;
        positionLine.lastPriceUpdate = this.currentPrices
          ? this.currentPrices[positionLine.equityUuid].date
          : null;

        positionLine.diffLastClose = this.getDiffLastClose(positionLine);
      });

      return result;
    },
  },
  methods: {
    getInvestedValue(item) {
      return Math.round(item.ucp * item.quantity * 100) / 100;
    },
    getClass: function (value) {
      if (value > 0) {
        return "success--text text-subtitle-2";
      } else if (value < 0) {
        return "error--text text-subtitle-2";
      } else {
        return "text-subtitle-2";
      }
    },
    // Last close value of specified position
    getLCValue: function (positionLine) {
      let lcVal =
        positionLine.quantity *
        this.latestDailyPrices[positionLine.equityUuid][0].price;
      return lcVal;
    },
    // Last close plus/minus value of specified position
    getLCPlusMinusValue: function (positionLine) {
      let buyVal = positionLine.quantity * positionLine.ucp;
      let lcVal = this.getLCValue(positionLine);
      return lcVal - buyVal;
    },
    // Last close plus/minus percentage of specified position
    getLCPlusMinusPercent: function (positionLine) {
      let buyVal = positionLine.quantity * positionLine.ucp;
      return this.getLCPlusMinusValue(positionLine) / buyVal;
    },
    // Last close allocation percentage of specified position
    getLCAllocation: function (positionLine) {
      return this.getLCValue(positionLine) / this.totalLCValue;
    },
    // Diff in percentage between lastClose and current
    getDiffLastClose: function (positionLine) {
      if (!this.currentPrices) {
        return undefined;
      }
      let result =
        this.currentPrices[positionLine.equityUuid].price -
        this.latestDailyPrices[positionLine.equityUuid][0].price;

      result =
        result / this.latestDailyPrices[positionLine.equityUuid][0].price;

      if (Math.round(result * 10000) / 10000 === 0) {
        return 0;
      }

      return result;
    },
    // Diff in percentage between lastClose and lastLastClose
    getLastClosePerf: function (positionLine) {
      let result =
        this.latestDailyPrices[positionLine.equityUuid][0].price -
        this.latestDailyPrices[positionLine.equityUuid][1].price;
      result =
        result / this.latestDailyPrices[positionLine.equityUuid][1].price;
      if (Math.round(result * 10000) / 10000 === 0) {
        return 0;
      }
      return result;
    },
    // ******************************* API methods
    getData() {
      this.isLoading = true;
      var p = Promise.resolve();

      let equityIds = this.currentPosition.lines.map(
        (oneLine) => oneLine.equityUuid
      );
      let urlPrefix = this.curList.trackerPortfolio ? "trackers" : "stocks";

      p = p.then(() => {
        // @apinote StockBatchAPI/TrackerBatchAPI
        return this.$http
          .post(urlPrefix + "/by-batch", equityIds)
          .then((response) => {
            this.equities = response.data;
          });
      });
      p = p.then(() => {
        // @apinote StockBatchAPI/TrackerBatchAPI
        return this.$http
          .post(urlPrefix + "/by-batch/latest-daily-prices?count=2", equityIds)
          .then((response) => {
            this.latestDailyPrices = response.data;
          });
      });

      p.then(() => {
        this.isLoading = false;
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
td.text-truncate,
th.text-truncate {
  max-width: 300px;
}
</style>