<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Liste des watchlist</h1>
        <edit-watchlist-dialog :dialogExt="dialogExt" :curList="curList" />
        <v-btn
          color="error"
          elevation="2"
          fab
          right
          bottom
          fixed
          @click.stop="handleClickOnPlus()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-spacer> </v-spacer>
      <v-col cols="3">
        <!-- ----------------------------------------------------------------------- -->
        <!-- Search field -->
        <!-- ----------------------------------------------------------------------- -->
        <v-text-field
          v-model="query"
          append-icon="mdi-magnify"
          label="Recherche"
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" v-for="oneWL in watchlistsToDisplay" :key="oneWL.id">
        <v-card>
          <v-card-text>
            <router-link
              style="text-decoration: none"
              :to="{ name: 'WatchlistDetail', params: { uuid: oneWL.id } }"
            >
              {{ oneWL.label }}
            </router-link>
            ({{ oneWL.stocks.length }})
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              icon
              @click="handleClickOnPlus(oneWL)"
              title="Modifier les paramètres"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              small
              color="red"
              icon
              :loading="syncLoading"
              :disabled="syncLoading"
              @click="deleteWatchlist(oneWL.id)"
              title="Supprimer"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn></v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import EditWatchlistDialog from "@/components/dialogs/EditWatchlistDialog.vue";

export default {
  name: "WatchlistList",
  data: function () {
    return {
      lists: [],
      syncLoading: false,
      dialogExt: false,
      curList: undefined,
      query: undefined,
    };
  },
  watch: {},
  computed: {
    watchlistsToDisplay: function () {
      let result = [...this.lists];

      if (this.query && this.query.length > 1) {
        result = result.filter((one) =>
          one.label.toUpperCase().includes(this.query.toUpperCase())
        );
      }

      return result;
    },
  },
  methods: {
    handleClickOnPlus(stockList) {
      this.curList = stockList;
      this.dialogExt = true;
      setTimeout(() => {
        this.dialogExt = false;
      }, 1000);
    },
    deleteWatchlist(listId) {
      // @apiNote WatchlistAPI
      this.$http.delete("watchlists/" + listId).then(() => {
        this.getData();
      });
    },
    getData() {
      // @apiNote WatchlistAPI
      this.$http.get("watchlists").then((response) => {
        this.lists = response.data;
        this.syncLoading = false;
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: { EditWatchlistDialog },
};
</script>