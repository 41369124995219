<template>
  <v-tooltip right max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"> {{ label }}</span>
    </template>
    <span> <slot></slot> </span>
  </v-tooltip>
</template>
<script>
export default {
  name: "TooltipComment",
  props: ["label"],
  data() {
    return {};
  },
};
</script>