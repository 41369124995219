<template>
  <v-container>
    <v-row
      ><v-col><h1>Liste des évènements</h1> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="events"
          :items-per-page="20"
          :loading="syncLoading"
          :options.sync="options"
          :server-items-length="totalCount"
          show-expand
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, -1],
          }"
        >
          <!-- Top filter line -->
          <template v-slot:[`body.prepend`]>
            <tr>
              <td></td>
              <td>
                <v-select
                  dense
                  v-model="typeFilter"
                  :items="typeValues"
                  @change="getData"
                  clearable
                ></v-select>
              </td>
              <td>
                <v-autocomplete
                  dense
                  v-model="targetFilter"
                  :items="targetValues"
                  item-text="name"
                  item-value="id"
                  @change="getData"
                  clearable
                ></v-autocomplete>
              </td>
              <td colspan="2"></td>
            </tr>
          </template>

          <!-- Expansion line below each line -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <span v-html="item.message"></span>
            </td>
          </template>

          <!-- Date column -->
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | toDateTime }}
          </template>

          <!-- Type column -->
          <template v-slot:[`item.type`]="{ item }">
            <div :class="getTypeBg(item.type)">
              {{ item.type }}
            </div>
          </template>

          <!-- Name column -->
          <template v-slot:[`item.target.name`]="{ item }">
            <router-link
              v-if="item.target.type === 'STOCK'"
              style="text-decoration: none"
              :to="{ name: 'StockDetail', params: { uuid: item.target.id } }"
            >
              {{ item.target.name }}
            </router-link>
            <span v-if="item.target.type !== 'STOCK'">
              {{ item.target.name }}
            </span>
          </template>

          <!-- Actions column -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              small
              color="red"
              icon
              :loading="syncLoading"
              :disabled="syncLoading"
              @click="deleteEvent(item.id)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "EventList",
  data: function () {
    return {
      events: [],
      syncLoading: false,
      options: { sortBy: ["date"], sortDesc: [true] },
      totalCount: 0,
      targetValues: [],
      typeValues: [],
      typeFilter: undefined,
      targetFilter: undefined,
      headers: [
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Target",
          value: "target.name",
        },
        {
          text: "Actions",
          value: "actions",
          groupable: false,
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getTypeBg(type) {
      switch (type) {
        case "PRICE_BELOW_STOP_LOSS":
          return "orange";
        case "PRICE_ABOVE_TAKE_PROFIT":
          return "green";
        case "PRICE_CROSS_UP_SMA200":
          return "blue";
        case "EARNING_DATE_DAY":
          return "blue-grey";
        case "FUNDAMENTAL_DATA_UPDATED":
          return "purple";
        case "FUNDAMENTAL_SCORE_UPDATED":
          return "cyan";
        case "VALUATION_SCORE_UPDATED":
          return "brown";
        default:
          return "";
      }
    },
    getData() {
      if (this.syncLoading) {
        return;
      }
      this.syncLoading = true;

      let queryString = "";
      queryString += this.options.page ? "&pi=" + this.options.page : "";
      queryString += this.typeFilter ? "&event=" + this.typeFilter : "";
      queryString += this.targetFilter ? "&uuid=" + this.targetFilter : "";
      if (this.options.itemsPerPage) {
        if (this.options.itemsPerPage == -1) {
          queryString += "&pc=" + this.totalCount;
        } else {
          queryString += "&pc=" + this.options.itemsPerPage;
        }
      }

      queryString +=
        this.options.sortBy && this.options.sortBy.length > 0
          ? "&sort=" + this.options.sortBy[0]
          : "";
      queryString +=
        this.options.sortDesc && this.options.sortDesc.length > 0
          ? "&dir=" + this.options.sortDesc[0]
          : "";

      // @apiNode EventAPI
      this.$http.get("events/search?" + queryString).then((response) => {
        this.totalCount = response.data.count;
        this.events = response.data.events;

        this.syncLoading = false;
      });
    },
    getTargetList() {
      // @apiNode StockAPI
      this.$http.get("stocks/light").then((response) => {
        this.targetValues = response.data;
      });
    },
    getTypeList() {
      // @apiNode ManagementAPI
      this.$http.get("management/events").then((response) => {
        this.typeValues = response.data;
      });
    },
    deleteEvent(eventId) {
      this.syncLoading = true;
      // @apiNode EventAPI
      this.$http.delete("events/" + eventId).then(
        () => {
          this.getData();
        },
        (error) => {
          console.log("Error deleting an event : ", error);
          this.syncLoading = false;
        }
      );
    },
  },

  mounted() {
    this.getData();
    this.getTargetList();
    this.getTypeList();
  },
  components: {},
};
</script>
