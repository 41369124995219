<template>
  <tr>
    <td>
      <slot name="rowTitle"></slot>
    </td>
    <td>
      <slot name="rowTarget"></slot>
    </td>

    <td
      v-for="(date, index) in dates"
      :key="date"
      :class="
        isMore
          ? cssClassMoreThan(
              dateDataFn(date),
              isBgOnFirstDate && index === 0 ? true : false
            )
          : cssClassLessThan(
              dateDataFn(date),
              isBgOnFirstDate && index === 0 ? true : false
            )
      "
    >
      {{ dateDataFn(date) }}
    </td>
  </tr>
</template>
<script>
export default {
  name: "FinancialTabDataPerDate",
  props: {
    dates: {}, // Date list to loop on
    dateDataFn: {}, // Function to call on each date to get value to display
    lastDataFn: {}, // Function to call to get last/TTM value
    industryDataFn: {}, // Function to call to get industry value
    fiveYearDataFn: {}, // Function to call to get 5Y average value
    redThreshold: {}, // Value that triggers red color (value not to overcome)
    orangeThreshold: {}, // Value that triggers orange color (warning value)
    isBgOnFirstDate: {
      // Indicate whether first date value should be background colored or font colored
      default: false,
    },
    isBgLast: {
      // Indicate whether last/TTM value should be background colored or font colored
      default: true,
    },
    isMore: { default: true }, // Indicate whether value should be considered increasing or decreasing
    applyColorClass: { default: true }, // Indicate whether cell should be colored
  },
  methods: {
    cssClassMoreThan(value, isBg = false) {
      let cssClass = "data-cell";
      if (!value) {
        return cssClass;
      }
      let rawValue = isNaN(value)
        ? parseFloat(value.replace("%", "").replace(" ", "").replace(",", "."))
        : value;
      if (!this.applyColorClass) {
        return cssClass;
      }
      if (this.redThreshold && rawValue < this.redThreshold) {
        cssClass += isBg ? " red color-bg" : " red--text lighten-3";
      } else if (this.orangeThreshold && rawValue < this.orangeThreshold) {
        cssClass += isBg ? " orange color-bg" : " orange--text lighten-1";
      } else {
        cssClass += isBg ? " green color-bg" : " green--text lighten-2";
      }
      return cssClass;
    },
    cssClassLessThan(value, isBg = false) {
      let cssClass = "data-cell";
      if (!value) {
        return cssClass;
      }
      let rawValue = isNaN(value)
        ? parseFloat(value.replace("%", "").replace(" ", "").replace(",", "."))
        : value;
      if (!this.applyColorClass) {
        return cssClass;
      }
      if (this.redThreshold && rawValue > this.redThreshold) {
        cssClass += isBg ? " red color-bg" : " red--text lighten-3";
      } else if (this.orangeThreshold && rawValue > this.orangeThreshold) {
        cssClass += isBg ? " orange color-bg" : " orange--text lighten-1";
      } else {
        cssClass += isBg ? " green color-bg" : " green--text lighten-2";
      }
      return cssClass;
    },
  },
};
</script>
<style lang="scss" scoped>
.last {
  color: #616161;
  font-variant-position: super;
}
.color-bg .industry {
  color: #555555;
  font-size: 0.8em;
}
.industry {
  color: #999999;
  font-size: 0.8em;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.data-cell {
  padding-right: 8px;
  padding-left: 8px;
}
</style>