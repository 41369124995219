<template>
  <div class="home" v-if="stock">
    <add-stock-to-portfolio-dialog
      v-model="showAddToListDialog"
      :stock="stock"
      @updateLists="updateListDone"
    >
    </add-stock-to-portfolio-dialog>
    <add-stock-to-watchlist-dialog
      v-model="showAddStockToWatchlistDialog"
      :stock="stock"
      @updateLists="updateListDone"
    >
    </add-stock-to-watchlist-dialog>
    <ManageStockLinksDialog
      v-model="showManageStockLinksDialog"
      :stock="stock"
      @reloadEquity="getData"
    >
    </ManageStockLinksDialog>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      outlined
      :multi-line="multiline"
      timeout="5000"
    >
      <h3>{{ snackbarText }}</h3>
      <div v-for="(line, i) in snackbarLines" :key="i">
        {{ line }}
      </div>
    </v-snackbar>

    <v-container>
      <v-row justify="space-between">
        <v-col>
          <!-- ------------------------------------------------------------------------------------ -->
          <!-- Title -->
          <!-- ------------------------------------------------------------------------------------ -->
          <h1>
            <v-icon v-if="stock.inPortfolio" class="amber--text text-h3"
              >mdi-star</v-icon
            >
            {{ stock.name }}
            <span
              class="grey--text--darken ml-10 text-h6"
              v-if="stock.lastPrice"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ stock.lastPrice.price | toFloat(1, stock.currency) }}
                  </span>
                </template>
                <span>
                  {{ stock.lastPrice.date | toDate }}<br />
                  Sma200:
                  {{ stock.lastPrice.sma200 | toFloat(1, stock.currency) }}
                  <br />
                  Macd 12 26 9:
                  {{ stock.lastPrice.macdSignal12_26_9 | toFloat }}
                  <br />
                  Macd 24 52 18:
                  {{ stock.lastPrice.macdSignal24_52_18 | toFloat }}
                  <br />
                  Macd 48 104 36:
                  {{ stock.lastPrice.macdSignal48_104_36 | toFloat }}
                </span>
              </v-tooltip>
            </span>
          </h1>
        </v-col>
        <v-col cols="4" align="right">
          <!-- ------------------------------------------------------------------------------------ -->
          <!-- Action buttons -->
          <!-- ------------------------------------------------------------------------------------ -->
          <v-btn @click="$router.push('/stocks')" title="Back to stock list">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            color="green darken-2"
            @click="showAddToListDialog = true"
            title="Add to portfolio"
          >
            <v-icon dark>mdi-briefcase-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            color="light-green lighten-1"
            @click="showAddStockToWatchlistDialog = true"
            title="Add to watchlist"
          >
            <v-icon dark> mdi-binoculars </v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            color="primary"
            @click="showManageStockLinksDialog = true"
            title="Manage links"
          >
            <v-icon dark>mdi-link-plus</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            color="primary"
            :loading="syncLoading"
            :disabled="syncLoading"
            title="Refresh integrated data"
            @click="syncFromCloud"
          >
            <v-icon dark>mdi-cloud-sync</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            color="error"
            :loading="syncLoading"
            :disabled="syncLoading"
            @click="deleteOne"
            title="Delete this stock"
          >
            <v-icon dark>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters justify="space-between">
        <v-col>
          <!-- ------------------------------------------------------------------------------------ -->
          <!-- Links to external websites -->
          <!-- ------------------------------------------------------------------------------------ -->
          <v-btn
            fab
            small
            target="_blank"
            :href="stock.investingUrl"
            title="Go to Investing"
            ><img src="@/assets/investing_logo.png"
          /></v-btn>
          &nbsp;
          <v-btn
            fab
            small
            target="_blank"
            title="Go to Yahoo"
            :href="'https://finance.yahoo.com/quote/' + stock.yticker"
            v-if="stock.yticker"
          >
            <img src="@/assets/yahoo_logo.png" />
          </v-btn>
          &nbsp;
          <v-btn
            fab
            small
            target="_blank"
            title="Go to Yahoo Chart"
            :href="'https://finance.yahoo.com/chart/' + stock.yticker"
            v-if="stock.yticker"
          >
            <v-icon class="deep-purple--text text--lighten-1">
              mdi-chart-line
            </v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            fab
            small
            target="_blank"
            title="Go to Morningstar"
            :href="
              'https://tools.morningstar.fr/fr/util/documentproxy.aspx?key=EquityQuant&secId=' +
              stock.morningstarId
            "
            v-if="stock.morningstarId"
          >
            <img src="@/assets/morningstar_logo.png" />
          </v-btn>
          &nbsp;
          <v-btn
            fab
            small
            target="_blank"
            title="Go to ZoneBourse"
            :href="
              'https://www.zonebourse.com/cours/action/' +
              stock.zoneBourseId +
              '/fondamentaux/'
            "
            v-if="stock.zoneBourseId"
          >
            <img src="@/assets/zonebourse.png" />
          </v-btn>
          &nbsp;
          <v-btn
            fab
            small
            target="_blank"
            title="Go to TradingView"
            :href="'https://www.tradingview.com/symbols/' + stock.tradingViewId"
            v-if="stock.tradingViewId"
          >
            <img src="@/assets/tradingview.png" />
          </v-btn>

          &nbsp;
          <span v-for="(oneLink, index) in stock.links" :key="index">
            <v-btn
              fab
              small
              target="_blank"
              :title="oneLink.name"
              :href="oneLink.url"
            >
              <v-icon v-if="oneLink.icon === 'default'"> mdi-link </v-icon>
              <img v-else :src="require(`../assets/` + oneLink.icon)" />
            </v-btn>
            &nbsp;
          </span>
        </v-col>
        <v-col align="right">
          <!-- ------------------------------------------------------------------------------------ -->
          <!-- Links to portfolios/watchlists -->
          <!-- ------------------------------------------------------------------------------------ -->
          <v-chip
            v-for="onePF in portfolios"
            color="green darken-2"
            text-color="green lighten-2"
            outlined
            class="ml-2"
            :key="onePF.id"
            :to="{
              name: 'PortfolioDetail',
              params: { uuid: onePF.id },
            }"
          >
            {{ onePF.label }}
          </v-chip>
          <v-chip
            v-for="oneWL in watchlists"
            color="light-green lighten-1"
            text-color="light-green lighten-3"
            close
            close-icon="mdi-close"
            @click:close="removeFromWatchlist(oneWL)"
            outlined
            class="ml-2"
            :key="oneWL.id"
            :to="{
              name: 'WatchlistDetail',
              params: { uuid: oneWL.id },
            }"
          >
            {{ oneWL.label }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>

    <!-- -------------------------------------------------------------------------------------------->
    <!-- Tabs --------------------------------------------------------------------------------------->
    <v-tabs grow v-model="activeTab" v-if="uuid">
      <v-tab :to="{ name: 'StockDetail' }" exact>Overview</v-tab>
      <v-tab
        :to="{
          name: 'QualityTab',
          params: { curStockName: stock.name },
        }"
        exact
      >
        Quality &nbsp;
        <v-chip v-if="stock.lastFundamentalScore" small>
          {{ stock.lastFundamentalScore.score | toFloat(1, " %", 0) }}
        </v-chip>
      </v-tab>
      <v-tab :to="{ name: 'FinancialTab' }" exact> Financial </v-tab>
      <v-tab :to="{ name: 'ValuationTab' }" exact>
        Valuation (<v-icon>
          mdi-numeric-{{ stock.myCustomIndicators.valuationScore }}-box
        </v-icon>
        /
        <v-icon> mdi-numeric-6 </v-icon>)
      </v-tab>
      <v-tab :to="{ name: 'HistoryTab' }" exact>Historique</v-tab>
      <v-tab :to="{ name: 'IntegrationTab' }" exact>Integration</v-tab>
    </v-tabs>
    <router-view @reloadEquity="getData" :refresh="refreshTab" />
  </div>
</template>

<script>
// @ is an alias to /src

import AddStockToPortfolioDialog from "@/components/stock-detail/dialogs/AddStockToPortfolioDialog.vue";
import AddStockToWatchlistDialog from "@/components/stock-detail/dialogs/AddStockToWatchlistDialog.vue";
import ManageStockLinksDialog from "@/components/stock-detail/ManageStockLinksDialog.vue";

export default {
  name: "StockDetail",
  props: ["uuid"],
  watch: {
    uuid: function () {
      this.getData();
    },
  },
  data: () => ({
    snackbarColor: "success",
    stock: undefined,
    activeTab: undefined,
    syncLoading: false,
    showAddToListDialog: false,
    showManageStockLinksDialog: false,
    showAddStockToWatchlistDialog: false,
    showSnackbar: false,
    snackbarText: undefined,
    snackbarLines: [],
    portfolios: [],
    watchlists: [],
    refreshTab: 0,
    multiline: true,
  }),
  methods: {
    getData() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid).then((response) => {
        this.stock = response.data;
        document.title = this.stock.name;

        this.getPortfolios();
      });
    },
    getPortfolios() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid + "/lists").then((response) => {
        this.portfolios = response.data;
        this.getWatchlists();
      });
    },
    getWatchlists() {
      // @apiNote WatchlistAPI
      this.$http
        .get("watchlists/by-stock/" + this.stock.id)
        .then((response) => {
          this.watchlists = response.data.map((oneList) => {
            oneList.hover = false;
            return oneList;
          });
        });
    },
    removeFromWatchlist(oneWL) {
      // @apiNote WatchlistAPI
      this.$http
        .delete("watchlists/" + oneWL.id + "/stocks/" + this.uuid)
        .then(() => {
          this.getWatchlists();
        });
    },
    syncFromCloud() {
      this.syncLoading = true;
      let fullSync = true;
      // @apiNote IntegrationAPI
      this.$http
        .get("integrations/" + this.uuid + "/refresh?full=" + fullSync)
        .then(
          (response) => {
            this.getData();
            this.syncLoading = false;
            this.snackbarText = "Data updated !";
            this.snackbarLines = response.data;
            this.snackbarColor = response.data.join(" ").includes("ERROR")
              ? "warning"
              : "success";
            this.showSnackbar = true;
            this.refreshTab++;
          },
          (error) => {
            console.log("Error updating data : ", error);
            this.syncLoading = false;
            this.snackbarText = error.response.data;
            this.snackbarColor = "error";
            this.showSnackbar = true;
          }
        );
    },

    deleteOne() {
      this.syncLoading = true;
      // @apiNote StockAPI
      this.$http.delete("stocks/" + this.uuid).then(
        () => {
          this.$router.push("/stocks");
          this.syncLoading = false;
        },
        (error) => {
          console.error("ERROR : ", error.response.data);
          this.snackbarText = error.response.data;
          this.showSnackbar = true;
          this.syncLoading = false;
          this.snackbarColor = "error";
        }
      );
    },
    updateListDone(listType, listLabel) {
      this.getPortfolios();
      this.snackbarText = this.stock.name + " a été ajouté ";
      if (listType === "PORTFOLIO") {
        this.snackbarText += "au portfolio ";
      } else {
        this.snackbarText += "à la watchlist ";
      }
      this.snackbarText += listLabel + ".";
      this.showSnackbar = true;
    },
  },
  filters: {},
  mounted() {
    this.getData();
  },
  components: {
    AddStockToPortfolioDialog,
    ManageStockLinksDialog,
    AddStockToWatchlistDialog,
  },
};
</script>
