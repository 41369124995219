var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('line-growth-graph',{attrs:{"title":_vm.graphTitle,"attributeValue":_vm.graphAttribute,"displayGrowth":_vm.graphDisplayGrowth,"periodType":_vm.dataPeriod,"dataset":_vm.graphDataset},model:{value:(_vm.showLineGrowthGraphDialog),callback:function ($$v) {_vm.showLineGrowthGraphDialog=$$v},expression:"showLineGrowthGraphDialog"}}),_c('bar-graph',{attrs:{"title":_vm.graphTitle,"attributeValue":_vm.graphAttribute,"periodType":_vm.dataPeriod,"dataset":_vm.graphDataset,"isPercentage":_vm.graphIsPercentage},model:{value:(_vm.showBarGraphDialog),callback:function ($$v) {_vm.showBarGraphDialog=$$v},expression:"showBarGraphDialog"}}),_c('tr',{staticClass:"blue lighten-2"},[_c('td',{attrs:{"width":"200"}}),_c('td',{attrs:{"width":"120"}},[_vm._v("Obj")]),_vm._l((_vm.dates),function(date){return _c('td',{key:date},[_vm._v(" "+_vm._s(_vm._f("toDate")(date))+" ")])})],2),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.balSheetAttPerDateFn('cash'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openLineGrowthGraphDialog('Cash', 'cash', true, _vm.stock.balanceSheet)}}},[_vm._v("Cash ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.balSheetAttPerDateFn('inventory'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openLineGrowthGraphDialog(
            'Inventaire',
            'inventory',
            true,
            _vm.stock.balanceSheet
          )}}},[_c('tooltip-comment',{attrs:{"label":"Inventory"}},[_vm._v(" Le risque d'obscolescence des stocks est-il présent ? Les produits que vend l'entreprise sont-ils sujet à modification ? ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" Stable ")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('longTermDebtNetIncomeRatio', false),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'LT Debt/Net income',
            'longTermDebtNetIncomeRatio',
            _vm.stock.fundamentalIndicators,
            (_vm.isPercentage = false)
          )}}},[_vm._v(" LT Debt/Net income ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" < 4 ")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('debtToEquityRatio'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'Total Debt/Equity',
            'debtToEquityRatio',
            _vm.stock.fundamentalIndicators,
            (_vm.isPercentage = true)
          )}}},[_c('tooltip-comment',{attrs:{"label":"Total Debt/Equity"}},[_vm._v(" L'entreprise a-t-elle plus de dette que de capitaux propres ? ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("< 100%")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('roe'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'ROE',
            'roe',
            _vm.stock.fundamentalIndicators,
            (_vm.isPercentage = true)
          )}}},[_c('tooltip-comment',{attrs:{"label":"ROE"}},[_vm._v(" Si le % est élevé cela veut dire que l'entreprise fait un bon usage des bénéfices non distribués. (Résultat net / Capitaux propres) ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("> 15%")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('roic'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'ROIC',
            'roic',
            _vm.stock.fundamentalIndicators,
            (_vm.isPercentage = true)
          )}}},[_c('tooltip-comment',{attrs:{"label":"ROIC"}},[_vm._v(" Si le % est élevé cela veut dire que l'entreprise fait un bon usage des capitaux investis (Dette + Capitaux propres). ROIC = (NetIncome - Dividends) / (Debt + Equity - Cash) ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("> 10%")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('retainedEarningGrowth'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openLineGrowthGraphDialog(
            'Retained earning',
            'retainedEarnings',
            true,
            _vm.stock.balanceSheet
          )}}},[_vm._v("Retained earning ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.balSheetAttPerDateFn('sharesOutstanding'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openLineGrowthGraphDialog(
            'Shares outstanding',
            'sharesOutstanding',
            true,
            _vm.stock.balanceSheet
          )}}},[_vm._v("Shares outstanding ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-bottom-right")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }