<template>
  <v-chart :option="options" class="chart" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
]);

export default {
  name: "PortfolioPerfPerYearECharts",
  props: ["curList"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      initOptions: {
        renderer: "canvas",
      },
      options: this.getChartOptions(),
    };
  },
  computed: {},
  methods: {
    getChartOptions() {
      return {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Performance par an",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            return (
              "" +
              params[0].data.yearStr +
              "<br/><b>" +
              this.$options.filters.toFloat(params[0].data.perf, 100, "%") +
              "</b>"
            );
          },
        },
        dataset: this.getDataset(),
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (param) => {
              return 100 * param + "%";
            },
          },
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              color: (seriesIdx) => {
                return seriesIdx.value.color;
              },
            },
          },
        ],
      };
    },

    getDataset() {
      let years = [];
      for (let oneYear in this.curList.performance.perfByYear) {
        years.push(oneYear);
      }
      years.sort();

      let result = [];
      for (let oneYear of years) {
        let d = new Date(oneYear);
        let yearStr = d.toLocaleDateString("fr-FR", {
          year: "numeric",
        });
        let oneData = {
          year: oneYear,
          yearStr: yearStr,
          perf: this.curList.performance.perfByYear[oneYear],
          color:
            this.curList.performance.perfByYear[oneYear] >= 0 ? "green" : "red",
        };

        result.push(oneData);
      }

      return {
        dimensions: ["yearStr", "perf"],
        source: result,
      };
    },
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>