<template>
  <v-row dense>
    <v-col cols="2">
      <v-row dense>
        <v-col cols="4">
          <v-badge
            :color="addWatchlistButtonEnabled ? 'green' : 'grey'"
            bordered
            icon="mdi-plus"
            overlap
            title="Créer une watchlist dynamique avec ces filtres"
          >
            <v-btn color="light-green" :disabled="!addWatchlistButtonEnabled">
              <v-icon> mdi-binoculars </v-icon>
            </v-btn>
          </v-badge>
        </v-col>
        <v-col cols="8">
          <v-select
            :items="toDisplayFilters"
            label="Ajouter un filtre"
            item-text="label"
            item-value="value"
            dense
            solo
            @change="addFilter"
            ref="selectedFilter"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer></v-spacer>
    <!-- Earning date selector -->
    <v-col cols="2" v-if="filters.earnings.show">
      <v-select
        v-model="filters.earnings.value"
        :items="periods"
        label="Publication des résultats"
        @change="updateFilterValues"
        dense
        prepend-icon="mdi-close-circle"
        @click:prepend="removeFilter('earnings')"
      ></v-select>
    </v-col>
    <!-- Quality score selector -->
    <v-col cols="2" v-if="filters.quality.show">
      <v-select
        v-model="filters.quality.value"
        :items="qualityThresholds"
        label="Score de qualité"
        @change="updateFilterValues"
        dense
        prepend-icon="mdi-close-circle"
        @click:prepend="removeFilter('quality')"
      ></v-select>
    </v-col>
    <!-- Sector selector -->
    <v-col cols="2" v-if="filters.sector.show">
      <v-autocomplete
        v-model="filters.sector.value"
        :items="sectors"
        item-text="sector"
        item-value="sector"
        clearable
        label="Secteur"
        dense
        @change="updateFilterValues"
        prepend-icon="mdi-close-circle"
        @click:prepend="removeFilter('sector')"
      >
      </v-autocomplete>
    </v-col>
    <!-- Industry selector -->
    <v-col cols="2" v-if="filters.industry.show">
      <v-autocomplete
        v-model="filters.industry.value"
        :items="industries[filters.sector.value]"
        clearable
        label="Industrie"
        dense
        @change="updateFilterValues"
        prepend-icon="mdi-close-circle"
        @click:prepend="removeFilter('industry')"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="2">
      <!-- ----------------------------------------------------------------------- -->
      <!-- Search field -->
      <!-- ----------------------------------------------------------------------- -->
      <v-text-field
        v-model="query"
        clearable
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";

export default {
  name: "StockFilters",

  data: () => {
    return {
      addWatchlistButtonEnabled: false,
      availableFilters: [
        { label: "Pub des résultats", value: "earnings" },
        { label: "Score de qualité", value: "quality" },
        { label: "Secteur", value: "sector" },
      ],
      filters: {
        sector: { show: false },
        industry: { show: false },
        earnings: { show: false },
        quality: { show: false },
        query: {},
      },
      selectedSector: null,
      sectors: [],
      industries: {},
      newFilter: null,
      query: "",
      selectedPeriod: null,
      periods: [
        { text: "30 derniers jours", value: "LAST_30_DAYS" },
        { text: "7 derniers jours", value: "LAST_7_DAYS" },
        { text: "Cette semaine", value: "THIS_WEEK" },
        { text: "7 prochains jours", value: "NEXT_7_DAYS" },
        { text: "30 prochains jours", value: "NEXT_30_DAYS" },
      ],
      qualityThresholds: [
        { text: "Plus de 90%", value: "MORE_THAN_90" },
        { text: "Plus de 75%", value: "MORE_THAN_75" },
        { text: "Plus de 50%", value: "MORE_THAN_50" },
      ],
    };
  },
  watch: {
    query: _.debounce(function () {
      if (this.query && this.query.length < 3) {
        return;
      }
      this.filters.query.value = this.query;
      this.updateFilterValues();
    }, 1000),
  },
  computed: {
    // On cache les filtres deja affiches
    toDisplayFilters() {
      let result = this.availableFilters.filter((oneFilter) => {
        return !this.filters[oneFilter.value].show;
      });
      return result;
    },
  },
  methods: {
    addFilter(newValue) {
      this.filters[newValue].show = true;
      this.$refs["selectedFilter"].reset();
      this.$refs["selectedFilter"].blur();
    },
    removeFilter(filter) {
      this.filters[filter].show = false;
      this.filters[filter].value = null;
      if (filter === "sector") {
        this.filters.industry.show = false;
        this.filters.industry.value = null;
      }
      this.setAddWLButtonState();
      this.$emit("updateFilters", this.filters);
    },
    updateFilterValues() {
      this.filters.industry.show = this.filters.sector.value ? true : false;
      this.setAddWLButtonState();
      this.$emit("updateFilters", this.filters);
    },
    setAddWLButtonState() {
      this.addWatchlistButtonEnabled = Object.keys(this.filters).reduce(
        (curResult, oneFilter) => {
          // key: the name of the object key
          curResult =
            curResult ||
            (this.filters[oneFilter].value &&
              this.filters[oneFilter].value.length > 0);
          return curResult;
        },
        false
      );
    },
    initData() {
      // @apiNote ManagementAPI
      this.$http.get("management/sectors").then((response) => {
        this.sectors = response.data;
        this.industries = response.data.reduce((curResult, oneSector) => {
          curResult[oneSector.sector] = oneSector.industries;
          return curResult;
        }, {});
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
