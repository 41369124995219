<template>
  <v-card v-if="equity">
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-card tile>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Custom name</v-list-item-subtitle>
                  <v-list-item-title>
                    <EditableField v-model="customName"></EditableField>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Summary</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ equity.longName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content
                  @mouseover="isinHover = true"
                  @mouseleave="isinHover = false"
                >
                  <v-list-item-subtitle>
                    <span :class="equity.isin ? '' : 'red px-3'"> ISIN </span>
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <editable-field
                      v-model="newIsin"
                      @input="updateIsin"
                    ></editable-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Annual cost</v-list-item-subtitle>
                  <v-list-item-title>{{
                    equity.annualCost | toFloat(100, " %")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle> Capitalisation </v-list-item-subtitle>
                  <v-list-item-title>
                    {{
                      equity.capitalization | toFloat(1, " M" + currencySymbol)
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-list>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Portfolios</v-list-item-subtitle>
                <v-list-item-title>
                  <v-list dense>
                    <v-list-item
                      dense
                      v-for="list in portfolios"
                      :key="list.id"
                    >
                      <router-link
                        style="text-decoration: none"
                        :to="{
                          name: 'PortfolioDetail',
                          params: { uuid: list.id },
                        }"
                      >
                        {{ list.label }}
                      </router-link>
                    </v-list-item>
                  </v-list></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6"
          ><!-- Comment field -->
          <v-tiptap
            v-model="equityComment"
            :dark="dark"
            :toolbar="[
              'bold',
              'italic',
              'underline',
              'strike',
              'color',
              '|',
              'h1',
              'h2',
              'h3',
              '|',
              'emoji',
            ]"
            dense="true"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import EditableField from "@/components/commons/EditableField.vue";

export default {
  name: "OverviewTab",
  props: ["equity"],
  components: { EditableField },

  watch: {
    equityComment: _.debounce(function () {
      this.updateEquityComment();
    }, 1000),
    customName: _.debounce(function () {
      this.updateCustomName();
    }, 1000),
    "equity.id": function () {
      this.newIsin = this.equity.isin;
    },
  },
  data: function () {
    return {
      portfolios: [],
      hover: false,
      equityComment: null,
      customName: null,
      dark: true,
      newIsin: this.equity.isin,
    };
  },
  computed: {
    currencySymbol() {
      if (this.equity.currency === "EUR") {
        return "€";
      } else if (this.equity.currency === "USD") {
        return "$";
      }
      return "???";
    },
  },
  methods: {
    updateIsin: function () {
      // Avoid to save if it doesn't change
      if (this.equity.isin === this.newIsin) {
        this.isinEdit = false;
        // console.log("Not necessary to send stock isin to backend");
        return;
      }
      // @apiNote TrackerAPI
      this.$http
        .post("trackers/" + this.equity.id + "/isin", {
          newIsin: this.newIsin,
        })
        .then(
          () => {
            this.snackbarText = "ISIN mis à jour avec succès !";
            this.showSnackbar = true;
            this.snackbarColor = "success";
            this.$emit("reloadEquity");
          },
          (error) => {
            console.log("Error updating ISIN : ", error);
            this.snackbarText = error.response.data;
            this.showSnackbar = true;
            this.snackbarColor = "error";
          }
        );
    },
    getLists() {
      // @apiNote TrackerAPI
      this.$http
        .get("trackers/" + this.equity.id + "/lists")
        .then((response) => {
          this.portfolios = response.data;
        });
    },

    updateEquityComment: function () {
      // Avoid to save if it doesn't change

      if (this.equity.comment === this.equityComment) {
        console.log("Not necessary to send equity comment to backend");
        return;
      }
      // @apiNote TrackerAPI
      this.$http
        .post("trackers/" + this.equity.id + "/comment", {
          newComment: this.equityComment,
        })
        .then(() => {
          this.$emit("reloadEquity");
          console.log("updateEquityComment : OK !", this.equityComment);
        });
    },
    updateCustomName: function () {
      // Avoid to save if it doesn't change
      if (this.equity.customName === this.customName) {
        console.log("Not necessary to send customName to backend");
        return;
      }
      // @apiNote TrackerAPI
      this.$http
        .post("trackers/" + this.equity.id + "/custom-name", {
          newCustomName: this.customName,
        })
        .then(() => {
          this.$emit("reloadEquity");
          console.log("updateCustomName : OK !", this.customName);
        });
    },
    initEquityComment() {
      this.equityComment = this.equity.comment ? this.equity.comment : "";
    },
    initCustomName() {
      this.customName = this.equity.customName ? this.equity.customName : "";
    },
  },

  mounted() {
    this.getLists();
    this.initEquityComment();
    this.initCustomName();
  },
};
</script>
<style lang="scss" scoped>
// To get complete list of available variables https://github.com/vuetifyjs/vuetify/issues/11783
::v-deep .theme--dark.v-btn.v-btn--icon {
  color: var(--v-secondary-lighten3);
}
</style>
