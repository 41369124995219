<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bordered
        overlap
        offset-x="15"
        offset-y="15"
        icon="mdi-plus"
        color="red accent-3"
        dark
      >
        <v-btn
          color="red"
          elevation="2"
          fab
          v-bind="attrs"
          v-on="on"
          title="Ajouter une nouvelle action"
        >
          <v-icon x-large>mdi-alpha-s</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Nouveau titre</span>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedStock"
          dense
          :items="entries"
          :loading="isLoading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          autofocus
          item-text="name"
          item-value="investingId"
          clearable
          return-object
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Tapez qqs caractères pour trouver une
                <strong>action</strong>
              </v-list-item-title>
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <span>{{ item.name }} - {{ item.exchange }}</span>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-avatar
              color="indigo"
              class="text-h5 font-weight-light white--text"
            >
              {{ item.name.charAt(0) }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.exchange }} ({{ item.flag }})
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
          :disabled="syncLoading"
          >Annuler</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :disabled="!selectedStock"
          :loading="syncLoading"
          ref="confirmButton"
          @click="save()"
          >Ajouter</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "AddNewStockDialog",
  props: [],

  data: () => ({
    investingUrl: "",
    dialog: false,
    syncLoading: false,
    errMessage: null,
    selectedStock: null,
    isLoading: false,
    search: null,
    entries: [],
  }),
  computed: {},
  watch: {
    search: _.debounce(function (val) {
      this.updateEntries(val);
    }, 1000),
  },
  methods: {
    updateEntries: function (val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      // @apinote ok
      this.$http.get("stocks/query?q=" + val).then(
        (searchResult) => {
          this.entries = searchResult.data;
          this.isLoading = false;
        },
        (error) => {
          console.error("ERROR : ", error);
          this.errMessage = error;
          this.isLoading = false;
        }
      );
    },
    save: function () {
      console.log("selectedStock: ", this.selectedStock);
      this.syncLoading = true;
      // @apinote ok
      this.$http.post("stocks", this.selectedStock).then(
        (newStock) => {
          // console.log("newStock : ", newStock);
          this.syncLoading = false;
          this.$router.push("/stocks/" + newStock.data);
          this.selectedStock = null;
          this.dialog = false;
        },
        (error) => {
          console.error("ERROR : ", error);
          this.errMessage = error.response.data;
          this.syncLoading = false;
        }
      );
    },
  },
  mounted() {},
};
</script>
