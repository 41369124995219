<template>
  <v-card outlined>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <!-- Input field : FCF per share -->
          <v-row dense>
            <v-col>
              <v-text-field
                title="test"
                v-model="fcfPerShare"
                label="FCF per share"
                dense
                @change="calculateIV"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-tooltip right max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon small>mdi-information-outline</v-icon></span
                  >
                </template>
                <span>
                  <span v-if="lastFcfPerShare">
                    Dernier FCF per share :
                    {{ lastFcfPerShare | toFloat(1, this.currency) }}<br />
                  </span>
                  <span v-if="averageFcfPerShare">
                    Moyenne des derniers FCF per share:
                    {{ averageFcfPerShare | toFloat(1, this.currency) }}
                  </span>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <!-- Input field : Growth rate -->
          <v-row dense>
            <v-col>
              <v-text-field
                title="test"
                v-model="estimatedGrowthRate"
                label="Growth rate"
                dense
                @change="calculateIV"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-tooltip right max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon small>mdi-information-outline</v-icon></span
                  >
                </template>
                <span v-if="lastFundamentalScore">
                  Croissance CA 5Y :
                  {{
                    lastFundamentalScore.details.SALE_GROWTH_5Y.value
                      | toFloat(100, "%")
                  }}<br />
                  Croissance ResNet 5Y :
                  {{
                    lastFundamentalScore.details.NET_INCOME_GROWTH_5Y.value
                      | toFloat(100, "%")
                  }}
                  <br />
                  Croissance FCF 5Y :
                  {{
                    lastFundamentalScore.details.FCF_GROWTH_AVG.value
                      | toFloat(100, "%")
                  }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <!-- Input field : Final multiple -->
          <v-row dense>
            <v-col>
              <v-text-field
                title="test"
                v-model="finalMultiple"
                label="Final multiple"
                dense
                @change="calculateIV"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-tooltip right max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon small>mdi-information-outline</v-icon></span
                  >
                </template>
                <span> Prendre une moyenne du PER et du PCF à gauche. </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="dcfIntrinsicValues">
          <bar-graph
            :dataset="dcfIntrinsicValues.intrinsicValues"
            :currentPrice="currentPrice"
            :currency="currency"
          ></bar-graph>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BarGraph from "@/components/stock-detail/valuation/graphs/BarGraph.vue";

export default {
  name: "IvCalculator",
  props: ["uuid", "currentPrice", "currency"],
  data: function () {
    return {
      fcfPerShare: null,
      averageFcfPerShare: null,
      lastFcfPerShare: null,
      estimatedGrowthRate: null,
      finalMultiple: null,
      dcfIntrinsicValues: null,
      lastFundamentalScore: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      // @apiNote StockAPI
      this.$http
        .get("stocks/" + this.uuid + "/dcf-parameters")
        .then((response) => {
          this.fcfPerShare = response.data.fcfPerShare;
          this.estimatedGrowthRate = response.data.estimatedGrowthRate * 100;
          this.finalMultiple = response.data.finalMultiple;
          this.calculateIV();
        });
      // @apiNote FundamentalIndicatorsAPI
      this.$http
        .get("stocks/" + this.uuid + "/fundamental-indicators")
        .then((response) => {
          let validFcfPerShare = response.data.filter(
            (one) => one.fcfPerShare && one.type === "ANNUAL"
          );
          this.lastFcfPerShare =
            validFcfPerShare.length > 0
              ? validFcfPerShare[0].fcfPerShare
              : null;
          this.averageFcfPerShare =
            validFcfPerShare.reduce(
              (total, next) => total + next.fcfPerShare,
              0
            ) / validFcfPerShare.length;
        });
      // @apiNote StockAPI
      this.$http
        .get("stocks/" + this.uuid + "/last-fundamental-score")
        .then((response) => {
          this.lastFundamentalScore = response.data;
        });
    },
    calculateIV() {
      if (!this.fcfPerShare || isNaN(this.fcfPerShare)) return;
      if (!this.estimatedGrowthRate || isNaN(this.estimatedGrowthRate)) return;
      if (!this.finalMultiple || isNaN(this.finalMultiple)) return;

      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.uuid + "/dcf-parameters", {
          fcfPerShare: this.fcfPerShare,
          estimatedGrowthRate: this.estimatedGrowthRate / 100,
          finalMultiple: this.finalMultiple,
        })
        .then((response) => {
          this.dcfIntrinsicValues = response.data;
        });
    },
  },

  mounted() {
    this.init();
  },
  components: {
    BarGraph,
  },
};
</script>

<style lang="scss">
</style>