<template>
  <!--
  <v-chart :option="options" @mouseover="handleMouseover" class="chart" />
  -->
  <v-chart :option="options" class="chart" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  DatasetComponent,
]);

export default {
  name: "PortfolioPerSectorECharts",
  props: ["curList", "currentPosition"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      initOptions: {
        renderer: "canvas",
      },
      options: this.getChartOptions(),
      isLoading: true,
      equities: {},
      lastDailyPrice: {},
    };
  },
  computed: {
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return this.curList.currency;
    },
  },
  methods: {
    handleMouseover(params) {
      console.log("Mouseover : ", params);
    },
    getChartOptions() {
      return {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Allocation par secteur",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          position: "right",
          textStyle: { overflow: "break", width: 100 },
          formatter: (params) => {
            return (
              "<b>" +
              params.data.name +
              "</b><br/>" +
              this.$options.filters.toFloat(
                params.data.value,
                1,
                this.currencySymbol
              ) +
              " (" +
              this.$options.filters.toFloat(params.data.percentage, 100, "%") +
              ")<br/><i><ul><li>" +
              params.data.equities.join("</li><li>") +
              "</li></ul></i>"
            );
          },
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "70%"],
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              show: true,
            },
          },
        ],
        dataset: {
          source: [],
        },
      };
    },

    calculateDataset() {
      let sectors = {};

      let total = 0;

      for (let oneLine of this.currentPosition.lines) {
        let equity = this.equities[oneLine.equityUuid];
        let lastPrice = this.lastDailyPrice[oneLine.equityUuid];
        if (!sectors[equity.sector]) {
          sectors[equity.sector] = {
            name: equity.sector,
            value: 0,
            equities: [],
          };
        }
        sectors[equity.sector].value += oneLine.quantity * lastPrice.price;
        sectors[equity.sector].equities.push(equity.name);
        total += oneLine.quantity * lastPrice.price;
      }

      let result = [];
      for (let sector in sectors) {
        sectors[sector].percentage = sectors[sector].value / total;
        result.push(sectors[sector]);
      }

      this.options.dataset.source = result;
      this.isLoading = false;
    },
    getData() {
      this.isLoading = true;
      var p = Promise.resolve();

      let equityIds = this.currentPosition.lines.map(
        (oneLine) => oneLine.equityUuid
      );

      p = p.then(() => {
        // @apiNote StockBatchAPI
        return this.$http
          .post("stocks/by-batch", equityIds)
          .then((response) => {
            this.equities = response.data;
          });
      });
      p = p.then(() => {
        // @apiNote StockBatchAPI
        return this.$http
          .post("stocks/by-batch/last-daily-price", equityIds)
          .then((response) => {
            this.lastDailyPrice = response.data;
          });
      });

      p.then(() => {
        this.calculateDataset();
      });
    },
  },
  mounted() {
    //this.options.dataset.source = this.getDataset();
    this.getData();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>