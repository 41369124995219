<template>
  <v-card>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      outlined
      timeout="2000"
    >
      {{ snackbarText }}
    </v-snackbar>

    <v-card-title>
      <v-row>
        <v-col>
          <h5 v-if="totalTargetAttribution !== 100" class="red--text">
            Attention: somme des allocations différentes de 100% ({{
              totalTargetAttribution
            }}%).
          </h5>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="sumToAllocate"
            label="Somme à allouer"
            dense
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <h5 v-if="sumAllocated && sumAllocated != 0" class="green--text">
            Somme allouée: {{ sumAllocated | toFloat(1, currencySymbol) }}.
          </h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      v-if="curList"
      dense
      :headers="tableHeaders"
      :items="dataTableRows"
      :items-per-page="-1"
      :hide-default-footer="true"
      sort-by="name"
      class="elevation-1"
    >
      <!-- Name -->
      <template v-slot:[`item.name`]="{ item }">
        <router-link
          v-if="!curList.trackerPortfolio"
          style="text-decoration: none"
          :to="{
            name: 'StockDetail',
            params: { uuid: item.equityUuid },
          }"
        >
          {{ item.name }}
        </router-link>
        <router-link
          v-if="curList.trackerPortfolio"
          style="text-decoration: none"
          :to="{
            name: 'TrackerDetail',
            params: { uuid: item.equityUuid },
          }"
        >
          {{ item.name }}
        </router-link>
      </template>

      <!-- Invested value -->
      <template v-slot:[`item.invest`]="{ item }">
        {{ item.invest | toFloat(1, " " + currencySymbol) }}
      </template>

      <!-- Last close value -->
      <template v-slot:[`item.lastCloseValue`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              item.lastCloseValue | toFloat(1, " " + currencySymbol)
            }}</span>
          </template>
          <span>{{ item.lastCloseDate | toDate }}</span>
        </v-tooltip>
      </template>

      <!-- Last close valo -->
      <template v-slot:[`item.lastCloseValo`]="{ item }">
        {{ item.lastCloseValo | toFloat(1, " " + currencySymbol) }}
      </template>

      <!-- Percent diff between invest and last close -->
      <template v-slot:[`item.lastClosePMPercent`]="{ item }">
        <span :class="getClass(item.lastClosePMPercent)">{{
          item.lastClosePMPercent | toFloat(100, " %")
        }}</span>
      </template>

      <!-- Last close allocation -->
      <template v-slot:[`item.lastCloseAllocation`]="{ item }">
        <span
          :class="
            item.targetAllocation
              ? getClass(100 * item.lastCloseAllocation, item.targetAllocation)
              : ''
          "
        >
          {{ item.lastCloseAllocation | toFloat(100, " %") }}
        </span>
      </template>

      <!-- Target allocation -->
      <template v-slot:[`item.targetAllocation`]="{ item }">
        <span v-if="!allocationEditing || allocationEditing !== item.id">
          <span class="float-left">
            {{ item.targetAllocation | toFloat(1, "%") }}
          </span>
          <!-- Icon to update target allocation conditions -->
          <a
            class="float-right"
            @click="
              allocationEditing = item.id;
              newAllocation = item.targetAllocation;
            "
          >
            <v-icon small>mdi-pencil</v-icon>
          </a>
        </span>
        <span v-if="allocationEditing && allocationEditing === item.id">
          <v-text-field
            single-line
            dense
            height="20"
            type="number"
            v-model="newAllocation"
          >
            <template v-slot:append-outer>
              <v-btn x-small icon @click="updateTargetAllocation(item)">
                <v-icon x-small color="green lighten-1">
                  mdi-check-circle
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                icon
                @click="
                  allocationEditing = undefined;
                  newAllocation = undefined;
                "
              >
                <v-icon x-small>mdi-close-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </span>
      </template>

      <!-- To buy count -->
      <template v-slot:[`item.toBuyCount`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.toBuyCount }}</span>
          </template>
          <span>
            Target valo:
            {{ item.newTargetValo | toFloat(1, currencySymbol) }}<br />
            Diff valo:
            {{
              (item.newTargetValo - item.lastCloseValo)
                | toFloat(1, currencySymbol)
            }}<br />
            Target count: {{ item.newTargetEquityCount | toFloat }}<br />
            Target float part: {{ item.toBuyCountFloatPart | toFloat }}<br />
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  name: "AllocationTab",
  props: ["curList", "currentPosition"],
  data: function () {
    return {
      isLoading: true,
      allocationEditing: undefined,
      lastDailyPrice: {},
      equities: {},
      holdingParameters: {},
      newAllocation: undefined,
      showSnackbar: false,
      snackbarText: undefined,
      snackbarColor: "success",
      sumToAllocate: undefined,
      sumAllocated: undefined,
      fakeToForceDataRecompute: 0,
    };
  },
  watch: {
    sumToAllocate: _.debounce(function (val) {
      if (!val || isNaN(val)) return;
      this.calculateNewAllocation(parseFloat(val));
    }, 1000),
    currentPosition: function (val) {
      if (val && val.lines) {
        this.getData();
      }
    },
  },
  computed: {
    tableHeaders() {
      let headers = [{ text: "Ticker", value: "yticker" }];
      if (this.curList.trackerPortfolio) {
        headers.push({ text: "Custom", value: "customName" });
      }
      headers = headers.concat([
        { text: "Name", value: "name" },
        { text: "Qte", value: "quantity" },
        { text: "Invest", value: "invest", align: "right", class: "blue" },
        {
          text: "LC VU",
          value: "lastCloseValue",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC Valo",
          value: "lastCloseValo",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC +/- %",
          value: "lastClosePMPercent",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "LC alloc",
          value: "lastCloseAllocation",
          align: "right",
          class: "orange darken-2",
        },
        {
          text: "Target alloc",
          value: "targetAllocation",
          align: "right",
          class: "success",
          width: "120px",
        },

        {
          text: "To buy",
          value: "toBuyCount",
          align: "right",
        },
      ]);

      return headers;
    },
    totalTargetAttribution() {
      let result = this.currentPosition.lines.reduce((previous, current) => {
        let rawValue =
          !this.holdingParameters[current.equityUuid] ||
          !this.holdingParameters[current.equityUuid].targetAllocation ||
          isNaN(this.holdingParameters[current.equityUuid].targetAllocation)
            ? 0
            : parseFloat(
                this.holdingParameters[current.equityUuid].targetAllocation
              );
        let newResult = previous + rawValue;
        return newResult;
      }, 0);
      return result;
    },
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return this.curList.currency;
    },

    totalLCValue: function () {
      let result = this.currentPosition.lines.reduce((previous, current) => {
        let newResult = previous + this.getLastCloseValo(current);
        return newResult;
      }, 0);
      return result;
    },

    linesByUuid: function () {
      let result = this.currentPosition.lines.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      return result;
    },

    dataTableRows() {
      if (this.isLoading) {
        return [];
      }

      let result = [...this.currentPosition.lines];

      this.fakeToForceDataRecompute;

      result.forEach((positionLine) => {
        positionLine.id = positionLine.equityUuid;
        positionLine.name = this.equities[positionLine.equityUuid].name;
        positionLine.customName =
          this.equities[positionLine.equityUuid].customName;
        positionLine.equityUuid = this.equities[positionLine.equityUuid].id;
        positionLine.isin = this.equities[positionLine.equityUuid].isin;
        positionLine.yticker = this.equities[positionLine.equityUuid].yticker;
        positionLine.invest = this.getInvestedValue(positionLine);
        positionLine.lastCloseDate =
          this.lastDailyPrice[positionLine.equityUuid].date;
        positionLine.lastCloseValue =
          this.lastDailyPrice[positionLine.equityUuid].price;
        positionLine.lastCloseValo = this.getLastCloseValo(positionLine);
        positionLine.targetAllocation = this.getTargetAllocation(
          positionLine.equityUuid
        );

        positionLine.lastClosePMPercent =
          this.getLCPlusMinusPercent(positionLine);
        positionLine.lastCloseAllocation = this.getLCAllocation(positionLine);
      });
      return result;
    },
  },
  methods: {
    getTargetAllocation(equityUuid) {
      let holdingParams = this.holdingParameters[equityUuid];
      return holdingParams ? holdingParams.targetAllocation : undefined;
    },
    calculateNewAllocation(sumToAllocate) {
      this.sumAllocated = 0;

      let targetTotal = this.totalLCValue + sumToAllocate;

      // 1ere passe: on ne prend que les titres avec un écart > 1 entier
      this.currentPosition.lines.forEach((positionLine) => {
        if (!positionLine.targetAllocation) {
          return;
        }
        positionLine.newTargetValo =
          (targetTotal * positionLine.targetAllocation) / 100;
        positionLine.newTargetEquityCount =
          positionLine.newTargetValo / positionLine.lastCloseValue;

        positionLine.toBuyCount = 0;
        positionLine.toBuyCountFloatPart = 0;
        if (positionLine.newTargetEquityCount > positionLine.quantity) {
          positionLine.toBuyCount = Math.floor(
            positionLine.newTargetEquityCount - positionLine.quantity
          );
          this.sumAllocated +=
            positionLine.toBuyCount * positionLine.lastCloseValue;
          positionLine.toBuyCountFloatPart =
            positionLine.newTargetEquityCount -
            positionLine.quantity -
            positionLine.toBuyCount;
        }
      });

      if (this.sumAllocated < sumToAllocate) {
        // 2 eme passe (s'il reste de la place) : on trie sur les stock avec le plus faible écart et on ajoute tant qu'il y a de la place
        this.currentPosition.lines
          .filter((oneLine) => {
            return oneLine.toBuyCountFloatPart > 0;
          })
          .sort((line1, line2) => {
            return line1.toBuyCountFloatPart === line2.toBuyCountFloatPart
              ? 0
              : line1.toBuyCountFloatPart > line2.toBuyCountFloatPart
              ? -1
              : 1;
          })
          .forEach((oneLine) => {
            if (oneLine.lastCloseValue + this.sumAllocated < sumToAllocate) {
              oneLine.toBuyCount++;
              this.sumAllocated += oneLine.lastCloseValue;
              console.log("bonus qty on ", oneLine);
            }
          });
      }
      this.fakeToForceDataRecompute++;
    },
    switchEdit(item) {
      this.linesByUuid[item.id].editTargetAllocation =
        !this.linesByUuid[item.id].editTargetAllocation;
    },
    getInvestedValue(item) {
      return Math.round(item.ucp * item.quantity * 100) / 100;
    },
    // Last close value of specified position
    getLastCloseValo(item) {
      return (
        Math.round(
          this.lastDailyPrice[item.equityUuid].price * item.quantity * 100
        ) / 100
      );
    },
    getClass: function (value, threshold = 0) {
      if (value > threshold) {
        return "success--text text-subtitle-2";
      } else if (value < threshold) {
        return "error--text text-subtitle-2";
      } else {
        return "text-subtitle-2";
      }
    },

    // Last close plus/minus value of specified position
    getLCPlusMinusValue: function (positionLine) {
      let buyVal = this.getInvestedValue(positionLine);
      let lcVal = this.getLastCloseValo(positionLine);
      return lcVal - buyVal;
    },
    // Last close plus/minus percentage of specified position
    getLCPlusMinusPercent: function (positionLine) {
      let buyVal = this.getInvestedValue(positionLine);

      return this.getLCPlusMinusValue(positionLine) / buyVal;
    },
    // Last close allocation percentage of specified position
    getLCAllocation: function (positionLine) {
      return this.getLastCloseValo(positionLine) / this.totalLCValue;
    },
    updateTargetAllocation(positionLine) {
      // @apinote ListAPI
      this.$http
        .post(
          "lists/" +
            this.curList.id +
            "/equities/" +
            positionLine.equityUuid +
            "/target-allocation",
          {
            targetAllocation: this.newAllocation,
          }
        )
        .then(() => {
          positionLine.targetAllocation = this.newAllocation;
          this.newAllocation = undefined;
          this.allocationEditing = false;
          this.snackbarText = "Allocation mise à jour avec succès !";
          this.showSnackbar = true;
          this.getData();
        });
    },
    // ******************************* API methods
    getData() {
      this.isLoading = true;
      var p = Promise.resolve();

      let equityIds = this.currentPosition.lines.map(
        (oneLine) => oneLine.equityUuid
      );
      let urlPrefix = this.curList.trackerPortfolio ? "trackers" : "stocks";
      p = p.then(() => {
        // @apinote StockBatchAPI/TrackerBatchAPI
        return this.$http
          .post(urlPrefix + "/by-batch", equityIds)
          .then((response) => {
            this.equities = response.data;
          });
      });
      p = p.then(() => {
        // @apinote StockBatchAPI/TrackerBatchAPI
        return this.$http
          .post(urlPrefix + "/by-batch/last-daily-price", equityIds)
          .then((response) => {
            this.lastDailyPrice = response.data;
          });
      });
      p = p.then(() => {
        // @apiNote ListAPI
        return this.$http
          .get("lists/" + this.curList.id + "/holding-parameters")
          .then((response) => {
            this.holdingParameters = response.data;
          });
      });
      p.then(() => {
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
