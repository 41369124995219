<template>
  <v-autocomplete
    v-model="selectedStock"
    :items="stocks"
    :item-text="getItemText"
    item-value="id"
    auto-select-first
    clearable
    dense
    label="Chercher une action"
    @change="switchToStock"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.yticker }} - {{ data.item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.isin }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "StockSwitcher",

  data: () => ({
    stocks: [],
    selectedStock: undefined,
  }),
  watch: {},
  methods: {
    getItemText(item) {
      return item.yticker + " " + item.name;
    },
    switchToStock() {
      if (this.selectedStock != null) {
        // console.log("switchToStock : ", this.selectedStock);

        if (
          this.$router.currentRoute.name === "StockDetail" &&
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.uuid &&
          this.$router.currentRoute.params.uuid === this.selectedStock
        ) {
          console.log("This stock is already displayed !!!");
        } else {
          this.$router
            .push({
              name: "StockDetail",
              params: { uuid: this.selectedStock },
            })
            .then(() => {
              this.selectedStock = null;
            })
            .finally(() => {
              this.selectedStock = null;
            });
        }
      }
    },
    getStocks() {
      // @apinote StockAPI
      this.$http.get("stocks/light").then((response) => {
        this.stocks = response.data;
      });
    },
  },
  mounted() {
    this.getStocks();
  },
};
</script>
