<template>
  <v-container v-if="equity">
    <v-row>
      <v-col cols="8">
        <h1>
          {{ equity.name }}
        </h1>
        <v-btn
          fab
          small
          target="_blank"
          title="Go to Yahoo"
          :href="'https://finance.yahoo.com/quote/' + equity.yticker"
          ><img src="@/assets/yahoo_logo.png"
        /></v-btn>
        <v-btn
          fab
          small
          target="_blank"
          title="Go to Yahoo Chart"
          :href="'https://finance.yahoo.com/chart/' + equity.yticker"
          ><v-icon class="deep-purple--text text--lighten-1"
            >mdi-chart-line</v-icon
          ></v-btn
        >
        <v-btn
          fab
          small
          v-if="equity.isin"
          target="_blank"
          title="Go to JustETF Chart"
          :href="
            'https://www.justetf.com/fr/etf-profile.html?isin=' + equity.isin
          "
        >
          <img src="@/assets/justetf_logo.png" />
        </v-btn>
      </v-col>
      <v-col cols="4" align="right">
        <v-btn
          color="error"
          :loading="syncLoading"
          :disabled="syncLoading"
          @click="deleteOne"
          title="Delete this tracker"
        >
          <v-icon dark>mdi-delete-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab href="#tab-1">Overview</v-tab>
          <v-tab href="#tab-2">X</v-tab>
          <v-tab href="#tab-3">Y</v-tab>
          <v-tab href="#tab-4">Z</v-tab>
          <v-tab-item value="tab-1">
            <OverviewTab
              :equity="equity"
              @reloadEquity="getData"
              ref="overviewTab"
            ></OverviewTab>
          </v-tab-item>
          <v-tab-item value="tab-2"> x tab </v-tab-item>
          <v-tab-item value="tab-3"> y tab </v-tab-item>
          <v-tab-item value="tab-4"> z tab </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import OverviewTab from "@/components/tracker-detail/OverviewTab.vue";

export default {
  name: "TrackerDetail",
  props: ["uuid"],
  watch: {
    uuid: function () {
      this.getData();
    },
  },
  data: () => ({
    tab: null,
    equity: undefined,
    syncLoading: false,
    showSnackbar: false,
    snackbarText: undefined,
  }),
  methods: {
    getData() {
      // @apiNote TrackerAPI
      this.$http.get("trackers/" + this.uuid).then((response) => {
        this.equity = response.data;
      });
    },

    deleteOne() {
      this.syncLoading = true;
      // @apiNote TrackerAPI
      this.$http.delete("trackers/" + this.uuid).then(
        () => {
          this.$router.push("/trackers");
          this.syncLoading = false;
        },
        (error) => {
          console.error("ERROR : ", error.response.data);
          this.snackbarText = error.response.data;
          this.showSnackbar = true;
          this.syncLoading = false;
        }
      );
    },
  },
  filters: {},
  mounted() {
    // console.log("TrackerDetails mounted");
    this.getData();
  },
  components: { OverviewTab },
};
</script>
