var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('line-growth-graph',{attrs:{"title":_vm.graphTitle,"attributeValue":_vm.graphAttribute,"displayGrowth":_vm.graphDisplayGrowth,"periodType":_vm.dataPeriod,"dataset":_vm.graphDataset},model:{value:(_vm.showLineGrowthGraphDialog),callback:function ($$v) {_vm.showLineGrowthGraphDialog=$$v},expression:"showLineGrowthGraphDialog"}}),_c('bar-graph',{attrs:{"title":_vm.graphTitle,"attributeValue":_vm.graphAttribute,"periodType":_vm.dataPeriod,"dataset":_vm.graphDataset,"isPercentage":_vm.graphIsPercentage},model:{value:(_vm.showBarGraphDialog),callback:function ($$v) {_vm.showBarGraphDialog=$$v},expression:"showBarGraphDialog"}}),_c('tr',{staticClass:"blue lighten-2"},[_c('td',{attrs:{"width":"200"}}),_c('td',{attrs:{"width":"120"}},[_vm._v("Obj")]),_vm._l((_vm.dates),function(date){return _c('td',{key:date},[_vm._v(" "+_vm._s(_vm._f("toDate")(date))+" ")])})],2),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('capexRatio'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'CAPEX / Net income',
            'capexRatio',
            _vm.stock.fundamentalIndicators,
            (_vm.isPercentage = true)
          )}}},[_vm._v(" CAPEX / Net income ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" < 25% ")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.cashFlowAttPerDateFn('stockIssuance'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'Stock issuance',
            'stockIssuance',
            _vm.stock.cashFlow,
            (_vm.isPercentage = false)
          )}}},[_c('tooltip-comment',{attrs:{"label":"Stock issuance"}},[_vm._v(" Si < 0, indique que l'entreprise a racheté de ses actions. ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" < 0 ")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('fcf', (_vm.isPercentage = false)),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openLineGrowthGraphDialog(
            'Free Cash Flow',
            'fcf',
            true,
            _vm.stock.fundamentalIndicators
          )}}},[_vm._v("Free Cash Flow ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" > 0 ")]},proxy:true}])}),_c('financial-tab-data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('fcfPerShare', (_vm.isPercentage = false)),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){_vm.openBarGraphDialog(
            'FCF per share',
            'fcfPerShare',
            _vm.stock.fundamentalIndicators,
            (_vm.isPercentage = false)
          )}}},[_vm._v("FCF per share ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" > 0 ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }