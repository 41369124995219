<template>
  <v-data-table
    v-if="curList"
    dense
    :headers="headers"
    :items="dataTableRows"
    :items-per-page="-1"
    :hide-default-footer="true"
    :loading="isLoading"
    sort-by="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <manage-stop-loss-dialog
        v-model="showManageStopLossDialog"
        :holdingParameters="currentHoldingParameters"
        :portfolioId="curList.id"
        :equities="equities"
        @reloadPortfolio="reloadPortfolio"
      >
      </manage-stop-loss-dialog>
    </template>

    <!-- Name column -->
    <template v-slot:[`item.name`]="{ item }">
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'StockDetail',
          params: { uuid: item.equityUuid },
        }"
      >
        {{ item.name }}
      </router-link>
    </template>

    <!-- LC column -->
    <template v-slot:[`item.lastClosePrice`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.lastClosePrice | toFloat(1, " " + currencySymbol) }}
          </span>
        </template>
        <span>
          Max : {{ item.maxPrice | toFloat(1, " " + currencySymbol) }} ({{
            item.maxPriceDate | toDate
          }})
        </span>
      </v-tooltip>
    </template>

    <!-- LC diff %column -->
    <template v-slot:[`item.lastClosePMPercent`]="{ item }">
      <span :class="getClass(item.lastClosePMPercent)">{{
        item.lastClosePMPercent | toFloat(100, " %")
      }}</span>
    </template>

    <!-- UCP column -->
    <template v-slot:[`item.ucp`]="{ item }">
      {{ item.ucp | toFloat(1, " " + currencySymbol) }}
    </template>

    <!-- IV columns -->
    <template v-slot:[`item.intrinsicValue`]="{ item }">
      {{ item.intrinsicValue | toFloat(1, " " + currencySymbol) }}
    </template>
    <template v-slot:[`item.diffIV`]="{ item }">
      <span :class="getClass(item.diffIV)">
        {{ item.diffIV | toFloat(100, " %") }}
      </span>
    </template>

    <!-- Fundamental Quote column -->
    <template v-slot:[`item.fundamentalScore`]="{ item }">
      <div
        :class="getDecreasingClass(item.fundamentalScore, 50, 60)"
        v-if="item.fundamentalScore"
      >
        {{ item.fundamentalScore | toFloat(1, " %", 0) }}
      </div>
    </template>

    <!-- Valuation Quote column -->
    <template v-slot:[`item.valuationQuote`]="{ item }">
      <div :class="getDecreasingClass(item.valuationQuote, 3, 4)">
        {{ item.valuationQuote }}/6
      </div>
    </template>

    <!-- ------------------------------------------------------------------------------------->
    <!-- StopLoss column -->
    <template v-slot:[`item.stopLoss`]="{ item }">
      <!-- Manual mode -->
      <span
        v-if="
          item.holdingParameters &&
          item.holdingParameters.stopLossMode === 'manual'
        "
      >
        <v-icon small>mdi-gesture-tap</v-icon>

        <span
          :class="
            getIncreasingClass(item.holdingParameters.stopLossDiff, 0, 0.05)
          "
        >
          {{ item.holdingParameters.stopLossDiff | toFloat(100, "%") }}</span
        >
        pour atteindre
        {{ item.holdingParameters.stopLoss | toFloat(1, " " + currencySymbol) }}
      </span>

      <!-- Auto mode -->
      <span
        v-if="
          item.holdingParameters &&
          item.holdingParameters.stopLossMode === 'auto'
        "
      >
        <v-icon small>mdi-auto-fix</v-icon>

        <span
          :class="
            getDecreasingClass(
              item.holdingParameters.stopLossAutoMaxPriceDiff,
              -0.01 * item.holdingParameters.stopLossAutoRatio,
              -0.009 * item.holdingParameters.stopLossAutoRatio
            )
          "
        >
          {{
            item.holdingParameters.stopLossAutoMaxPriceDiff | toFloat(100, "%")
          }}
        </span>
        p/r
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{
                item.holdingParameters.stopLossAutoMaxPrice
                  | toFloat(1, " " + currencySymbol)
              }}
            </span>
          </template>
          <span>
            Le
            {{ item.holdingParameters.stopLossAutoMaxPriceDate | toDate }}
          </span>
        </v-tooltip>

        <i
          class="grey--text text--lighten-2 caption"
          v-if="item.holdingParameters.stopLossMode === 'auto'"
        >
          (SL:
          {{ item.holdingParameters.stopLoss | toFloat(1, currencySymbol) }})
        </i>
      </span>

      <!-- Icon to update SL conditions -->
      <a
        class="float-right"
        @click="
          setCurrentHoldingParameters(item);
          showManageStopLossDialog = true;
        "
      >
        <v-icon small>mdi-pencil</v-icon>
      </a>
      <!-- Icon to view SL comments -->
      <v-tooltip
        bottom
        v-if="item.holdingParameters && item.holdingParameters.stopLossComment"
      >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="float-right">
            &nbsp;&nbsp;<v-icon small>mdi-information-outline</v-icon>
          </span>
        </template>
        <span v-html="item.holdingParameters.stopLossComment"> </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>


<!-- ------------------------------------------------------------------------------------------------------- -->
<!-- ------------------------------------------------------------------------------------------------------- -->
<!-- ------------------------------------------------------------------------------------------------------- -->
<!-- ------------------------------------------------------------------------------------------------------- -->
<!-- ------------------------------------------------------------------------------------------------------- -->
<!-- ------------------------------------------------------------------------------------------------------- -->
<!-- ------------------------------------------------------------------------------------------------------- -->

<script>
import ManageStopLossDialog from "@/components/portfolio-detail/ManageStopLossDialog.vue";

export default {
  name: "CurrentPositionTab",
  props: ["curList"],
  watch: {},
  data: function () {
    return {
      isLoading: true,
      showManageStopLossDialog: false,
      currentHoldingParameters: null, // HoldingParameters shown in dedicated dialog
      currentPosition: null,
      equities: {},
      holdingParameters: {},
      lastPrices: {},
      customIndicators: {},
      fundamentalScores: {},
      headers: [
        { text: "Name", value: "name" },
        { text: "UCP", value: "ucp" },
        { text: "LC", value: "lastClosePrice" },
        {
          text: "LC +/- %",
          value: "lastClosePMPercent",
          align: "right",
        },
        {
          text: "IV",
          value: "intrinsicValue",
          align: "right",
          class: "blue",
        },
        {
          text: "Safety",
          value: "diffIV",
          align: "right",
          class: "blue",
        },
        {
          text: "Fundamental score",
          value: "fundamentalScore",
          align: "center",
          class: "orange darken-2",
        },
        {
          text: "Valuation quote",
          value: "valuationQuote",
          align: "center",
          class: "green darken-2",
        },

        {
          text: "Stop loss",
          value: "stopLoss",
          class: "red darken-3",
        },
      ],
    };
  },
  computed: {
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return "???";
    },

    dataTableRows() {
      if (this.isLoading) {
        return [];
      }
      let result = [...this.currentPosition.lines];

      result.forEach((positionLine) => {
        positionLine.name = this.equities[positionLine.equityUuid].name;
        positionLine.isin = this.equities[positionLine.equityUuid].isin;
        positionLine.lastClosePrice =
          this.lastPrices[positionLine.equityUuid].price;
        positionLine.lastClosePMPercent =
          this.getLCPlusMinusPercent(positionLine);
        positionLine.intrinsicValue =
          this.customIndicators[positionLine.equityUuid].intrinsicValue;
        positionLine.diffIV = this.getDiffIV(positionLine);
        positionLine.valuationQuote =
          this.customIndicators[positionLine.equityUuid].valuationScore;
        positionLine.fundamentalScore =
          this.fundamentalScores[positionLine.equityUuid].score;

        positionLine.holdingParameters = this.holdingParameters[
          positionLine.equityUuid
        ]
          ? this.holdingParameters[positionLine.equityUuid]
          : null;
      });

      return result;
    },
  },
  methods: {
    getDiffIV(positionLine) {
      if (
        !this.lastPrices[positionLine.equityUuid].price ||
        !this.customIndicators[positionLine.equityUuid].intrinsicValue
      ) {
        return undefined;
      }

      let iv = this.customIndicators[positionLine.equityUuid].intrinsicValue;
      let result = iv / this.lastPrices[positionLine.equityUuid].price - 1;
      return result;
    },
    setCurrentHoldingParameters(positionLine) {
      this.currentHoldingParameters = this.getHoldingParameters(positionLine);
    },
    getHoldingParameters(positionLine) {
      let result = {
        equityUuid: positionLine.equityUuid,
        stockName: this.equities[positionLine.equityUuid].name,
      };
      if (this.holdingParameters[positionLine.equityUuid]) {
        result = this.holdingParameters[positionLine.equityUuid];
      }
      return result;
    },
    reloadPortfolio() {
      this.$emit("reloadPortfolio");
      this.initTab();
    },

    // Last close value of specified position
    getLCValue: function (positionLine) {
      let lcVal =
        positionLine.quantity * this.lastPrices[positionLine.equityUuid].price;
      return lcVal;
    },
    // Last close plus/minus value of specified position
    getLCPlusMinusValue: function (positionLine) {
      let buyVal = positionLine.quantity * positionLine.ucp;
      let lcVal = this.getLCValue(positionLine);
      return lcVal - buyVal;
    },
    // Last close plus/minus percentage of specified position
    getLCPlusMinusPercent: function (positionLine) {
      let buyVal = positionLine.quantity * positionLine.ucp;

      return this.getLCPlusMinusValue(positionLine) / buyVal;
    },
    /**
     * Return CSS class to apply depending of value
     * Value of error MUST be below Value of Warning
     * valError < valWarning
     */
    getDecreasingClass(
      val,
      valError,
      valWarning,
      classError = "error--text text-subtitle-2",
      classWarning = "warning--text text-subtitle-2",
      classOther = ""
    ) {
      return val < valError
        ? classError
        : val < valWarning
        ? classWarning
        : classOther;
    },
    /**
     * Return CSS class to apply depending of value
     * Value of error MUST be above Value of Warning
     * valError > valWarning
     */
    getIncreasingClass(
      val,
      valError,
      valWarning,
      classError = "error--text text-subtitle-2",
      classWarning = "warning--text text-subtitle-2",
      classOther = ""
    ) {
      return val > valError
        ? classError
        : val > valWarning
        ? classWarning
        : classOther;
    },
    getClass: function (value) {
      if (value > 0) {
        return "success--text text-subtitle-2";
      } else if (value < 0) {
        return "error--text text-subtitle-2";
      } else {
        return "text-subtitle-2";
      }
    },

    /** *******************************************************************************
     * API calls to initiate data required by the tab
     */

    initTab() {
      this.isLoading = true;
      // @apinote ListAPI
      this.$http
        .get("lists/" + this.curList.id + "/current-position")
        .then((response) => {
          this.currentPosition = response.data;

          let equities = this.currentPosition.lines.map(
            (oneLine) => oneLine.equityUuid
          );

          var p = Promise.resolve();

          p = p.then(() => {
            // @apinote StockBatchAPI
            return this.$http
              .post("stocks/by-batch", equities)
              .then((response) => {
                this.equities = response.data;
              });
          });
          p = p.then(() => {
            // @apinote StockBatchAPI
            return this.$http
              .post("stocks/by-batch/last-daily-price", equities)
              .then((response) => {
                this.lastPrices = response.data;
              });
          });
          p = p.then(() => {
            // @apinote StockBatchAPI
            return this.$http
              .post("stocks/by-batch/last-fundamental-scores", equities)
              .then((response) => {
                this.fundamentalScores = response.data;
              });
          });
          p = p.then(() => {
            // @apinote StockBatchAPI
            return this.$http
              .post("stocks/by-batch/custom-indicators", equities)
              .then((response) => {
                this.customIndicators = response.data;
              });
          });

          p = p.then(() => {
            // @apiNote ListAPI
            return this.$http
              .get("lists/" + this.curList.id + "/holding-parameters")
              .then((response) => {
                this.holdingParameters = response.data;
              });
          });

          p.then(() => {
            // console.log("all data retrieved !");
            this.isLoading = false;
          });
        });
    },
  },
  mounted() {
    this.initTab();
  },
  components: {
    ManageStopLossDialog,
  },
};
</script>
