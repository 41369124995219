<template>
  <v-chart :option="options" class="chart" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
]);

export default {
  name: "PortfolioPerfPerMonthECharts",
  props: ["curList"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      initOptions: {
        renderer: "canvas",
      },
      options: this.getChartOptions(),
    };
  },
  computed: {},
  methods: {
    getChartOptions() {
      return {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Performance par mois",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            return (
              "" +
              params[0].data.monthStr +
              "<br/><b>" +
              this.$options.filters.toFloat(params[0].data.perf, 100, "%") +
              "</b>"
            );
          },
        },
        dataset: this.getDataset(),
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (param) => {
              return 100 * param + "%";
            },
          },
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              color: (seriesIdx) => {
                return seriesIdx.value.color;
              },
            },
          },
        ],
      };
    },

    getDataset() {
      let months = [];
      for (let oneMonth in this.curList.performance.perfByMonth) {
        months.push(oneMonth);
      }
      months.sort();

      let result = [];
      for (let oneMonth of months) {
        let d = new Date(oneMonth);
        let monthStr = d.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "short",
        });
        monthStr = monthStr.charAt(0).toUpperCase() + monthStr.slice(1);

        let oneData = {
          month: oneMonth,
          monthStr: monthStr,
          perf: this.curList.performance.perfByMonth[oneMonth],
          color:
            this.curList.performance.perfByMonth[oneMonth] >= 0
              ? "green"
              : "red",
        };

        result.push(oneData);
      }

      return {
        dimensions: ["monthStr", "perf"],
        source: result,
      };
    },
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>