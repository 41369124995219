<template>
  <div class="home">
    Home view

    <VueTradingView :options="options" />
  </div>
</template>

<script>
import VueTradingView from "vue-trading-view/src/vue-trading-view";
export default {
  name: "HomePage",
  watch: {},
  data: () => ({
    options: {
      symbol: "NASDAQ:GOOGL",
      // symbol: "EURONEXT:BIM",
      theme: "dark",
    },
  }),
  methods: {},
  mounted() {},
  components: { VueTradingView },
};
</script>
