<template>
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list dense>
          <v-list-item link to="/">
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/portfolios">
            <v-list-item-action>
              <v-icon>mdi-briefcase-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Portfolios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/watchlists">
            <v-list-item-action>
              <v-icon>mdi-binoculars</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Watchlists</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/stocks">
            <v-list-item-action>
              <v-icon>mdi-alpha-s-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Stocks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/trackers">
            <v-list-item-action>
              <v-icon>mdi-alpha-t-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Trackers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/events">
            <v-list-item-action>
              <v-icon>mdi-calendar-clock</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Events</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/management">
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/about">
            <v-list-item-action>
              <v-icon>mdi-information-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <stock-switcher></stock-switcher>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="6" align="center">
                  <add-new-stock-dialog />
                </v-col>
                <v-col cols="6" align="center">
                  <add-new-tracker-dialog />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="red" dark dense>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Portfolio manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="switchtoV3Url()">
          <v-icon>mdi-arrow-right-bottom</v-icon>
          v3
        </v-btn>
      </v-app-bar>

      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
    </v-app>
  </v-app>
</template>
<script>
import StockSwitcher from "@/components/StockSwitcher.vue";
import AddNewStockDialog from "@/components/AddNewStockDialog.vue";
import AddNewTrackerDialog from "@/components/AddNewTrackerDialog.vue";

export default {
  name: "App",

  data: () => ({
    drawer: null,
    //
  }),
  methods: {
    switchtoV3Url() {
      let curLoc = window.location;
      window.open(
        "https://stock-screener-v3.danylecoq.net" + curLoc.pathname,
        "_blank"
      );
    },
  },
  components: {
    StockSwitcher,
    AddNewStockDialog,
    AddNewTrackerDialog,
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>
