<template>
  <v-chart
    :init-options="initOptions"
    :option="options"
    class="chart"
    :loading="loading"
    :loadingOptions="loadingOptions"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
]);

export default {
  name: "PortfolioHistoryECharts",
  props: ["curList"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      loading: true,
      loadingOptions: {
        text: "Loading...",
        color: "red",
        maskColor: "rgba(255, 255, 255, 0.4)",
      },
      rawHistory: [],
      initOptions: {
        renderer: "canvas",
      },
      options: {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Portfolio history",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
          formatter: (args) => {
            let d = new Date(args[0].data.date);
            let dateStr = d.toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            });

            let tooltip = dateStr + "<br/>";
            tooltip +=
              args[0].marker +
              " " +
              args[0].seriesName +
              ": <b>" +
              this.$options.filters.toFloat(
                args[0].data.invested,
                1,
                this.getCurrencySymbol(this.curList)
              ) +
              "</b><br/>";
            tooltip +=
              args[1].marker +
              " " +
              args[1].seriesName +
              ": <b>" +
              this.$options.filters.toFloat(
                args[1].data.value,
                1,
                this.getCurrencySymbol(this.curList)
              ) +
              "</b><br/>";

            return tooltip;
          },
        },

        dataZoom: [
          {
            type: "slider",
          },
          {
            type: "inside",
          },
        ],
        xAxis: {
          type: "time",
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "line",
            name: "Investi",
            id: "invested",
            encode: {
              y: "invested",
            },
            showSymbol: false,
          },
          {
            type: "line",
            name: "Valeur",
            id: "value",
            showSymbol: false,
            encode: {
              y: "value",
            },
          },
        ],
        dataset: {
          dimensions: ["date", "invested", "value"],
          source: [],
        },
      },
    };
  },
  computed: {},
  methods: {
    getCurrencySymbol(curList) {
      if (curList.currency === "EUR") {
        return "€";
      } else if (curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
    getData() {
      this.loading = true;
      // @apiNote ListAPI
      this.$http
        .get("lists/" + this.curList.id + "/history")
        .then((response) => {
          this.options.dataset.source = response.data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>