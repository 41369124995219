<template>
  <v-chart
    :init-options="initOptions"
    :option="options"
    class="chart"
    :loading="loading"
    :loadingOptions="loadingOptions"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
]);

export default {
  name: "PortfolioPerformanceECharts",
  props: ["curList"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      loading: true,
      loadingOptions: {
        text: "Loading...",
        color: "red",
        maskColor: "rgba(255, 255, 255, 0.4)",
      },
      rawHistory: [],
      initOptions: {
        renderer: "canvas",
      },
      options: {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Portfolio performance",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          formatter: (args) => {
            let d = new Date(args[0].data.date);
            let dateStr = d.toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            });

            let tooltip = dateStr + "<br/><b>";
            tooltip +=
              this.$options.filters.toFloat(args[0].data.performance, 1, " %") +
              "</b>";

            return tooltip;
          },
        },

        xAxis: {
          type: "time",
          axisLabel: {
            rotate: 30,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (param) => {
              return param + "%";
            },
          },
        },
        series: [
          {
            type: "line",
            name: "Perf PF",
            id: "performance",
            encode: {
              y: "performance",
            },
            showSymbol: false,
          },
        ],
        dataset: {
          dimensions: ["date", "performance", "value"],
          source: [],
        },
      },
    };
  },
  computed: {},
  methods: {
    getData() {
      this.loading = true;
      // @apiNote ListAPI
      this.$http
        .get("lists/" + this.curList.id + "/history")
        .then((response) => {
          let dataset = response.data;
          if (dataset.length > 0) {
            let valueInit = dataset[0].value;
            let investedInit = dataset[0].invested;
            dataset.forEach((oneHistory) => {
              let diffInvested = oneHistory.invested - investedInit;
              let curDiff = oneHistory.value - valueInit - diffInvested;
              oneHistory.performance =
                (100 * curDiff) / (valueInit + diffInvested);
            });
          }
          this.options.dataset.source = dataset;
          // console.log("dataset: ", dataset);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>