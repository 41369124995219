<template>
  <v-chart
    :init-options="initOptions"
    :option="options"
    class="chart"
    :loading="loading"
    :loadingOptions="loadingOptions"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
]);

export default {
  name: "PortfolioDividendPerMonthECharts",
  props: ["curList"],
  provide: {
    [THEME_KEY]: "dark",
  },
  data: function () {
    return {
      loading: true,
      transactions: [],
      equities: {},
      loadingOptions: {
        text: "Loading...",
        color: "red",
        maskColor: "rgba(0, 0, 0, 0)",
      },
      rawHistory: [],
      initOptions: {
        renderer: "canvas",
      },
      options: {
        backgroundColor: "rgba(0, 0, 0, 0)", // Fond transparent
        title: {
          text: "Dividende par mois",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let equities = Object.keys(params[0].data.equities)
              .sort()
              .map((oneE) => {
                return (
                  oneE +
                  " (" +
                  this.$options.filters.toFloat(
                    params[0].data.equities[oneE],
                    1,
                    this.currencySymbol
                  ) +
                  ")"
                );
              });
            // console.log("equities: ", equities);
            let tooltip =
              "<b>" +
              params[0].data.monthStr +
              ": " +
              this.$options.filters.toFloat(
                params[0].data.dividend,
                1,
                this.currencySymbol
              ) +
              "</b>";
            if (equities && equities.length > 0) {
              tooltip +=
                "<br/><i><ul><li>" +
                equities.join("</li><li>") +
                "</li></ul></i>";
            }
            return tooltip;
          },
        },

        xAxis: {
          type: "time",
          axisLabel: {
            rotate: 30,
            formatter: "{MMM} {yyyy}",
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
          },
        ],
        dataset: {
          dimensions: ["date", "dividend"],
          source: [],
        },
      },
    };
  },
  computed: {
    currencySymbol() {
      if (this.curList.currency === "EUR") {
        return "€";
      } else if (this.curList.currency === "USD") {
        return "$";
      }
      return "???";
    },
  },
  methods: {
    calculateDataset() {
      // Get dividend tx
      let dividendTx = this.transactions.sort((t1, t2) => {
        return t1.date < t2.date ? -1 : t1.date > t2.date ? 1 : 0;
      });

      let curDate = new Date(dividendTx[0].date); // Date de la premiere Tx de dividend
      curDate.setDate(1); // On met au 1er du mois
      let today = new Date();

      // Init dividendTxPerMonth with one entry per month
      let dividendTxPerMonth = {};
      while (curDate < today) {
        let monthStr = curDate.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "long",
        });
        dividendTxPerMonth[monthStr] = {
          date: new Date(curDate),
          dividend: 0,
          monthStr: monthStr,
          equities: {},
        };
        curDate.setMonth(curDate.getMonth() + 1);
      }

      // fill dividendTxPerMonth with Tx
      dividendTx.forEach((oneTx) => {
        let txDate = new Date(oneTx.date);
        if (txDate > today) {
          return;
        }
        let monthStr = txDate.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "long",
        });

        dividendTxPerMonth[monthStr].dividend += oneTx.total;
        let equityName = this.equities[oneTx.equityUuid].name;
        if (dividendTxPerMonth[monthStr].equities[equityName]) {
          dividendTxPerMonth[monthStr].equities[equityName] += oneTx.total;
        } else {
          dividendTxPerMonth[monthStr].equities[equityName] = oneTx.total;
        }
      });
      // console.log("dividendTxPerMonth: ", dividendTxPerMonth);

      // Construct final structure and sort it
      let result = Object.values(dividendTxPerMonth).sort((t1, t2) => {
        return t1.date < t2.date ? -1 : t1.date > t2.date ? 1 : 0;
      });
      // console.log("result: ", result);

      this.options.dataset.source = result;

      this.loading = false;

      return result;
    },
    getEquities(equityIds) {
      let url = this.curList.trackerPortfolio ? "trackers" : "stocks";
      // @apinote StockBatchAPI/TrackerBatchAPI
      this.$http.post(url + "/by-batch", equityIds).then((response) => {
        this.equities = response.data;
        this.calculateDataset();
      });
    },

    getData() {
      this.loading = true;
      // @apinote TransactionAPI
      this.$http
        .get("lists/" + this.curList.id + "/transactions?type=DIVIDEND")
        .then((response) => {
          this.transactions = response.data;
          let equityIds = this.transactions.map(
            (oneLine) => oneLine.equityUuid
          );
          this.getEquities(equityIds);
        });
    },
  },
  mounted() {
    this.getData();
  },
  components: { VChart },
};
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>