<template>
  <v-card>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      outlined
      timeout="2000"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-card-text v-if="stock">
      <v-row>
        <v-col cols="2">
          <v-card tile>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Sector / Industry</v-list-item-title>
                  <v-list-item-subtitle>{{
                    stock.sector
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    :title="stock.industry"
                    class="text-caption"
                  >
                    {{ stock.industry }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Capitalisation </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      (stock.shareOutstanding * stock.lastPrice.price)
                        | toFloat(0.000001, " M" + stock.currency, 0)
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Exchange </v-list-item-title>
                  <v-list-item-subtitle>
                    <editable-field
                      v-model="stockExchange"
                      @input="updateStockExchange"
                    ></editable-field>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <a
                      style="text-decoration: none"
                      :href="stock.investingUrl + '-earnings'"
                      target="_blank"
                      >Earning date</a
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Last: {{ stock.lastEarningDate | toDate }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Next: {{ stock.nextEarningDate | toDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Dividend</v-list-item-title>
                  <v-list-item-subtitle>
                    <a
                      v-if="stock.tradingViewId"
                      style="text-decoration: none"
                      :href="
                        'https://www.tradingview.com/symbols/' +
                        stock.tradingViewId +
                        '/financials-dividends/'
                      "
                      target="_blank"
                    >
                      {{
                        stock.currentRatios.dividendYield | toFloat(100, "%")
                      }}
                    </a>
                    <span v-if="!stock.tradingViewId">
                      {{
                        stock.currentRatios.dividendYield | toFloat(100, "%")
                      }}
                    </span>
                    <i
                      title="Payout ratio"
                      v-if="stock.currentRatios.payoutRatio"
                    >
                      ({{
                        stock.currentRatios.payoutRatio | toFloat(100, "%")
                      }})
                    </i>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-title>Description</v-card-title>
            <v-card-text v-if="stockDesc">
              <span v-html="stockDesc"></span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="4">
          <!-- Comment field -->
          <v-tiptap
            v-model="stockComment"
            :dark="dark"
            :toolbar="[
              'bold',
              'italic',
              'underline',
              'strike',
              'color',
              '|',
              'h1',
              'h2',
              'h3',
              '|',
              'emoji',
            ]"
            dense="true"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<!-- --------------------------------------------------------------------------------->
<!-- SCRIPT --(----------------------------------------------------------------------->
<!-- --------------------------------------------------------------------------------->
<script>
import _ from "lodash";
import EditableField from "@/components/commons/EditableField.vue";

export default {
  name: "OverviewTab",
  props: ["uuid", "refresh"],
  watch: {
    uuid: function () {
      this.initTab();
    },
    refresh: function () {
      this.initTab();
    },
    stockComment: _.debounce(function () {
      this.updateStockComment();
    }, 1000),
    stockExchange: _.debounce(function () {
      this.updateStockExchange();
    }, 1000),
  },
  data: function () {
    return {
      stock: null,
      dark: true,
      stockComment: null,
      stockExchange: null,
      isinHover: false,
      showSnackbar: false,
      snackbarText: undefined,
      snackbarColor: "success",
      syncInProgress: false,
    };
  },
  computed: {
    stockDesc: function () {
      return this.stock && this.stock.description
        ? this.stock.description.replaceAll("\n", "<br>")
        : null;
    },
  },
  methods: {
    initTab() {
      this.getData();
    },
    getData() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid).then((response) => {
        this.stock = response.data;
        document.title = this.stock.name;

        this.initStockComment();
        this.initStockExchange();
      });
    },

    updateStockComment: function () {
      // Avoid to save if it doesn't change
      if (this.stock.comment === this.stockComment) {
        // console.log("Not necessary to send stock comment to backend");
        return;
      }
      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/comment", {
          newComment: this.stockComment,
        })
        .then(() => {
          this.$emit("reloadEquity");
        });
    },
    updateStockExchange: function () {
      // Avoid to save if it doesn't change
      if (this.syncInProgress || this.stock.exchange === this.stockExchange) {
        return;
      }
      this.syncInProgress = true;
      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/exchange", {
          newExchange: this.stockExchange,
        })
        .then(() => {
          this.$emit("reloadEquity");
        })
        .finally(() => {
          this.syncInProgress = false;
        });
    },

    initStockComment() {
      this.stockComment =
        this.stock && this.stock.comment ? this.stock.comment : "";
    },
    initStockExchange() {
      this.stockExchange =
        this.stock && this.stock.exchange ? this.stock.exchange : "";
    },
  },
  components: { EditableField },

  mounted() {
    this.initTab();
  },
};
</script>
<style lang="scss" scoped>
// To get complete list of available variables https://github.com/vuetifyjs/vuetify/issues/11783
::v-deep .theme--dark.v-btn.v-btn--icon {
  color: var(--v-secondary-lighten3);
}
</style>

