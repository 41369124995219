<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Liens</span>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="(oneLink, index) in stock.links" :key="index">
            <v-list-item-icon v-if="oneLink.icon">
              <v-icon v-if="oneLink.icon == 'default'">mdi-link</v-icon>
              <img v-else :src="require(`../../assets/` + oneLink.icon)" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ oneLink.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ oneLink.url }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="deleteLink(index)">
                <v-icon color="red lighten-1">mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  dense
                  placeholder="Url"
                  v-model="newLinkUrl"
                ></v-text-field>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="saveNewLink">
                <v-icon color="green lighten-1">mdi-plus-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-row>
          <v-col cols="12" class="red darken-1 text-center" v-if="errMessage">
            <span class="white--text">
              Error adding link : {{ errMessage }}.
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" plain @click="show = false"> Fermer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ManageStockLinksDialog",
  props: ["value", "stock"],

  data: () => ({
    newLinkUrl: null,
    newLinkName: null,
    newLinkIcon: null,
    errMessage: null,
    logos: [
      "default",
      "tradingview.png",
      "zonebourse.png",
      "morningstar_logo.png",
    ],
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/" + pic);
    },
    saveNewLink: function () {
      if (!this.newLinkUrl) {
        this.errMessage = "Url field is mandatory !";
        return;
      }

      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/links", {
          newLinkUrl: this.newLinkUrl,
          newLinkName: this.newLinkName,
          newLinkIcon: this.newLinkIcon,
        })
        .then(
          () => {
            this.newLinkName = null;
            this.newLinkUrl = null;
            this.newLinkIcon = null;
            this.$emit("reloadEquity");
          },
          () => {
            // TODO: Manage error
          }
        );
    },
    deleteLink: function (linkIdx) {
      // @apiNote StockAPI
      this.$http.delete("stocks/" + this.stock.id + "/links/" + linkIdx).then(
        () => {
          this.$emit("reloadEquity");
        },
        () => {
          // TODO: Manage error
        }
      );
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.v-select {
  max-width: 60px;
}
</style>