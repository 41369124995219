<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Critère</th>
          <th
            class="text-center"
            v-for="(item, i) in scores"
            :key="i"
            :title="item.date"
          >
            {{ item.date | toDate }}
            <v-btn
              x-small
              color="red"
              icon
              @click="deleteScore(item)"
              title="Supprimer"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </th>
        </tr>
        <tr>
          <th class="text-left"></th>
          <th class="text-center" v-for="(item, i) in scores" :key="i">
            <h2>
              {{ item.score | toFloat(1, "%", 0) }}
            </h2>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Croissance ----------------------------------------------------->
        <tr>
          <td class="blue lighten-2" :colspan="scores.length + 1">
            <b>Croissance</b>
          </td>
        </tr>
        <!-- Croissance du CA ----------------------------------------------------->
        <tr>
          <td title="> 10%">
            Croissance du CA TTM/<span class="blue--text">5Y</span>
          </td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.SALE_GROWTH_TTM)">
              {{ item.details.SALE_GROWTH_TTM.value | toFloat(100, "%") }}
            </v-chip>
            <v-chip :class="getChipClass(item.details.SALE_GROWTH_5Y)">
              {{ item.details.SALE_GROWTH_5Y.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Croissance du résultat net ----------------------------------------------------->
        <tr>
          <td title="> 10%">Croissance du résultat net TTM/5Y</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip
              x-small
              :class="getChipClass(item.details.NET_INCOME_GROWTH_TTM)"
            >
              {{ item.details.NET_INCOME_GROWTH_TTM.value | toFloat(100, "%") }}
            </v-chip>
            <v-chip
              x-small
              :class="getChipClass(item.details.NET_INCOME_GROWTH_5Y)"
            >
              {{ item.details.NET_INCOME_GROWTH_5Y.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Croissance du FCF ----------------------------------------------------->
        <tr>
          <td title="> 10%">Croissance du FCF 5Y</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip :class="getChipClass(item.details.FCF_GROWTH_AVG)">
              {{ item.details.FCF_GROWTH_AVG.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Marges ----------------------------------------------------->
        <tr>
          <td class="blue lighten-2" :colspan="scores.length + 1">
            <b>Marges</b>
          </td>
        </tr>
        <!-- Marge opérationnelle ----------------------------------------------------->
        <tr>
          <td title="> 7%">Marge opérationnelle TTM/5Y</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip
              x-small
              :class="getChipClass(item.details.OPERATING_MARGIN_TTM)"
            >
              {{ item.details.OPERATING_MARGIN_TTM.value | toFloat(100, "%") }}
            </v-chip>
            <v-chip
              x-small
              :class="getChipClass(item.details.OPERATING_MARGIN_5Y)"
            >
              {{ item.details.OPERATING_MARGIN_5Y.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Marge nette ----------------------------------------------------->
        <tr>
          <td title="> 5%">Marge nette TTM/5Y</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.NET_MARGIN_TTM)">
              {{ item.details.NET_MARGIN_TTM.value | toFloat(100, "%") }}
            </v-chip>
            <v-chip x-small :class="getChipClass(item.details.NET_MARGIN_5Y)">
              {{ item.details.NET_MARGIN_5Y.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Marge FCF ----------------------------------------------------->
        <tr>
          <td title="> 5%">Marge FCF <span class="blue--text">5Y</span></td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip
              v-if="item.details.FCF_MARGIN_AVG"
              :class="getChipClass(item.details.FCF_MARGIN_AVG)"
            >
              {{ item.details.FCF_MARGIN_AVG.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Rentabilité ----------------------------------------------------->
        <tr>
          <td class="blue lighten-2" :colspan="scores.length + 1">
            <b>Rentabilité</b>
          </td>
        </tr>
        <!-- ROE ----------------------------------------------------->
        <tr>
          <td title="> 10%">ROE TTM/<span class="blue--text">5Y</span></td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.ROE_TTM)">
              {{ item.details.ROE_TTM.value | toFloat(100, "%") }}
            </v-chip>
            <v-chip :class="getChipClass(item.details.ROE_5Y)">
              {{ item.details.ROE_5Y.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- ROIC ----------------------------------------------------->
        <tr>
          <td title="> 15%">ROIC TTM/<span class="blue--text">5Y</span></td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.ROIC_TTM)">
              {{ item.details.ROIC_TTM.value | toFloat(100, "%") }}
            </v-chip>
            <v-chip :class="getChipClass(item.details.ROIC_5Y)">
              {{ item.details.ROIC_5Y.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Endettement ----------------------------------------------------->
        <tr>
          <td class="blue lighten-2" :colspan="scores.length + 1">
            <b>Endettement</b>
          </td>
        </tr>
        <!-- Dette sur FCF ----------------------------------------------------->
        <tr>
          <td title="< 4">
            <span class="blue--text">Dette nette sur FCF</span>
          </td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip
              v-if="item.details.NET_DEBT_TO_FCF"
              :class="getChipClass(item.details.NET_DEBT_TO_FCF)"
            >
              {{ item.details.NET_DEBT_TO_FCF.value | toFloat }}
            </v-chip>
          </td>
        </tr>
        <!-- Dette sur equity ----------------------------------------------------->
        <tr>
          <td title="< 100%">Dette sur equity</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.DEBT_TO_EQUITY)">
              {{ item.details.DEBT_TO_EQUITY.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Dividende ----------------------------------------------------->
        <tr>
          <td class="blue lighten-2" :colspan="scores.length + 1">
            <b>Dividende</b>
          </td>
        </tr>
        <!-- Yield ----------------------------------------------------->
        <tr>
          <td title="> 2%">Yield</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.DIVIDEND_YIELD)">
              {{ item.details.DIVIDEND_YIELD.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Payout ratio ----------------------------------------------------->
        <tr>
          <td title="< 75%">Payout ratio</td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip x-small :class="getChipClass(item.details.DIVIDEND_PAYOUT)">
              {{ item.details.DIVIDEND_PAYOUT.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
        <!-- Divers ----------------------------------------------------->
        <tr>
          <td class="blue lighten-2" :colspan="scores.length + 1">
            <b>Divers</b>
          </td>
        </tr>
        <!-- Stock issuance ----------------------------------------------------->
        <tr>
          <td title="< 0">Stock issuance <span class="blue--text">5Y</span></td>
          <td class="text-center" v-for="(item, i) in scores" :key="i">
            <v-chip :class="getChipClass(item.details.STOCK_ISSUANCE_AVG)">
              {{ item.details.STOCK_ISSUANCE_AVG.value | toFloat(100, "%") }}
            </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "QualityTab",
  props: ["uuid", "refresh", "curStockName"],
  watch: {
    uuid: function () {
      this.initTab();
    },
    refresh: function () {
      this.initTab();
    },
  },
  data: function () {
    return {
      scores: [],
    };
  },
  computed: {},
  methods: {
    getChipClass(item) {
      let clazz = "mr-1";

      clazz += item.score === 0 ? " red" : " green";

      if (item.updated) {
        clazz += " white--text";
      } else {
        clazz += " text--lighten-3 v-chip--outlined";
        clazz += item.score === 0 ? " red--text" : " green--text";
      }

      return clazz;
    },
    deleteScore(score) {
      // @apiNote FundamentalScoreAPI
      this.$http
        .delete("stocks/" + this.uuid + "/fundamental-scores/" + score.id)
        .then(() => {
          this.initTab();
        });
    },
    initTab() {
      document.title = this.curStockName;
      // @apiNote StockAPI
      this.$http
        .get("stocks/" + this.uuid + "/fundamental-scores")
        .then((response) => {
          this.scores = response.data;
          for (let i = 0; i < this.scores.length; i++) {
            if (i !== this.scores.length - 1) {
              for (let item in this.scores[i].details) {
                let curVal = this.scores[i].details[item].value;
                let prevVal = this.scores[i + 1].details[item]
                  ? this.scores[i + 1].details[item].value
                  : null;
                if (
                  (curVal && !prevVal) ||
                  (!curVal && prevVal) ||
                  (curVal && prevVal && curVal !== prevVal)
                ) {
                  this.scores[i].details[item].updated = true;
                }
              }
            }
          }
        });
    },
  },
  components: {},

  mounted() {
    this.initTab();
  },
};
</script>
<style lang="scss" scoped>
</style>

