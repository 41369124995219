<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-form>
        <v-card-title>
          <span class="headline" v-if="!curList"> Nouvelle watchlist </span>
          <span class="headline" v-if="curList">
            Mise à jour de la watchlist
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="listLabel"
                  label="Libellé"
                  autofocus
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="refIndex"
                  multiple
                  label="Index de reference"
                  dense
                  chips
                  deletable-chips
                  :items="trackers"
                  item-value="id"
                  item-text="name"
                  :filter="customFilter"
                  :menu-props="{ closeOnContentClick: true }"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.yticker }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="12"
                class="red darken-1 text-center"
                v-if="errMessage"
              >
                <span class="white--text">
                  Error adding watchlist : {{ errMessage }}.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false" :disabled="syncLoading">
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="syncLoading"
            depressed
            @click="save()"
          >
            <span v-if="curList">Modifier</span>
            <span v-if="!curList">Ajouter</span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditWatchlistDialog",
  props: ["dialogExt", "curList"],

  data: () => ({
    listLabel: "",
    dialog: false,
    syncLoading: false,
    errMessage: null,
    refIndex: [],
    trackers: [],
  }),
  watch: {
    dialogExt(newV) {
      if (newV) {
        if (this.curList) {
          this.listLabel = this.curList.label;
          this.refIndex = this.curList.indexEquities;
        }
        this.dialog = true;
      }
    },
    dialog(newV) {
      if (!newV) {
        this.reset();
      }
    },
  },
  computed: {},
  methods: {
    customFilter(item, queryText) {
      let strToQuery = item.name ? item.name.toUpperCase() : "";
      strToQuery += item.yticker ? " " + item.yticker.toUpperCase() : "";
      strToQuery += item.customName ? " " + item.customName.toUpperCase() : "";
      strToQuery += item.longName ? " " + item.longName.toUpperCase() : "";
      const searchText = queryText.toUpperCase();

      return strToQuery.indexOf(searchText) > -1;
    },
    reset() {
      this.listLabel = "";
      this.errMessage = null;
      this.refIndex = [];
    },
    save: function () {
      if (this.listLabel === "") {
        this.errMessage = "Le libellé ne peut pas être vide";
        return;
      }
      this.syncLoading = true;
      let stockListObj = {
        label: this.listLabel,
        indexEquities: this.refIndex,
      };
      if (this.curList) {
        stockListObj.id = this.curList.id;
      }
      // @apinote WatchlistAPI
      this.$http.post("watchlists", stockListObj).then(
        (newList) => {
          // console.log("newList : ", newList);
          this.syncLoading = false;
          this.$router.push("/watchlists/" + newList.data.id);
          this.dialog = false;
        },
        (error) => {
          console.error("ERROR : ", error.response.data);
          this.errMessage = error.response.data;
          this.syncLoading = false;
        }
      );
    },
    loadTrackers() {
      // @apinote TrackerAPI
      this.$http.get("trackers").then((response) => {
        this.trackers = response.data;
      });
    },
  },
  mounted() {
    this.loadTrackers();
  },
};
</script>
