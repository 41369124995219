<template>
  <v-container fluid>
    <v-row justify="space-around">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn-toggle mandatory v-model="dataPeriod">
          <v-btn
            :color="dataPeriod === 'ANNUAL' ? 'blue-grey' : ''"
            value="ANNUAL"
          >
            Annual
          </v-btn>
          <v-btn
            :color="dataPeriod === 'QUARTERLY' ? 'blue-grey' : ''"
            value="QUARTERLY"
          >
            Quarterly
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-simple-table class="mt-2 financial-tab" v-if="stock" dense>
      <template v-slot:default>
        <!------------------------------------------------------------------------------------------------------->
        <!-- Compte de resultats -------------------------------------------------------------------------------->
        <!------------------------------------------------------------------------------------------------------->
        <tbody>
          <tr class="primary">
            <td :colspan="2 + dates.length" class="bottom-white-line">
              <h3>
                Compte de résultats&nbsp;
                <up-down-chevron v-model="showIncomeStatements" />
              </h3>
            </td>
          </tr>
        </tbody>
        <financial-tab-income-statement
          v-if="showIncomeStatements"
          :stock="stock"
          :dataPeriod="dataPeriod"
        >
        </financial-tab-income-statement>

        <!------------------------------------------------------------------------------------------------------->
        <!-- Bilan -------------------------------------------------------------------------------->
        <!------------------------------------------------------------------------------------------------------->
        <tbody>
          <tr class="primary">
            <td :colspan="2 + dates.length" class="bottom-white-line">
              <h3>
                Bilan&nbsp;
                <up-down-chevron v-model="showBalanceSheet" />
              </h3>
            </td>
          </tr>
        </tbody>

        <financial-tab-balance-sheet
          v-if="showBalanceSheet"
          :stock="stock"
          :dataPeriod="dataPeriod"
        >
        </financial-tab-balance-sheet>

        <!------------------------------------------------------------------------------------------------------->
        <!-- Cash flow -------------------------------------------------------------------------------->
        <!------------------------------------------------------------------------------------------------------->
        <tbody>
          <tr class="primary">
            <td :colspan="2 + dates.length" class="bottom-white-line">
              <h3>
                Cash flow&nbsp; <up-down-chevron v-model="showCashFlow" />
              </h3>
            </td>
          </tr>
        </tbody>

        <financial-tab-cash-flow
          v-if="showCashFlow"
          :stock="stock"
          :dataPeriod="dataPeriod"
        >
        </financial-tab-cash-flow>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import UpDownChevron from "@/components/stock-detail/financial/UpDownChevron.vue";
import FinancialTabIncomeStatement from "@/components/stock-detail/financial/IncomeStatement";
import FinancialTabBalanceSheet from "@/components/stock-detail/financial/BalanceSheet";
import FinancialTabCashFlow from "@/components/stock-detail/financial/CashFlow";

export default {
  name: "FinancialTab",
  props: ["uuid", "refresh"],
  watch: {
    uuid: function () {
      this.getData();
    },
    refresh: function () {
      this.getData();
    },
  },
  data: function () {
    return {
      dataPeriod: "ANNUAL",
      showFinancialScoreDialog: false,
      showIncomeStatements: true,
      showBalanceSheet: true,
      showCashFlow: true,
      showPiotroski: true,
      stock: null,
    };
  },
  computed: {
    dates() {
      return Object.keys(this.fundamentalIndicators).sort().reverse();
    },
    fundamentalIndicators() {
      let result = this.stock.fundamentalIndicators
        .filter((incStmt) => incStmt.type === this.dataPeriod)
        .reduce((obj, item) => {
          obj[item.date] = item;
          return obj;
        }, {});
      return result;
    },
  },
  methods: {
    getData() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid).then((response) => {
        this.stock = response.data;
        document.title = this.stock.name;
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    UpDownChevron,
    FinancialTabIncomeStatement,
    FinancialTabBalanceSheet,
    FinancialTabCashFlow,
  },
};
</script>
<style lang="scss" >
.last {
  color: #616161;
  font-variant-position: super;
}
.industry {
  color: #616161;
  font-size: 11px;
}
.bottom-white-line {
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
}
</style>
