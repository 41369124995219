var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('line-growth-graph',{attrs:{"title":_vm.graphTitle,"attributeValue":_vm.graphAttribute,"displayGrowth":_vm.graphDisplayGrowth,"periodType":_vm.dataPeriod,"dataset":_vm.graphDataset},model:{value:(_vm.showLineGrowthGraphDialog),callback:function ($$v) {_vm.showLineGrowthGraphDialog=$$v},expression:"showLineGrowthGraphDialog"}}),_c('bar-graph',{attrs:{"title":_vm.graphTitle,"attributeValue":_vm.graphAttribute,"periodType":_vm.dataPeriod,"dataset":_vm.graphDataset,"isPercentage":_vm.graphIsPercentage},model:{value:(_vm.showBarGraphDialog),callback:function ($$v) {_vm.showBarGraphDialog=$$v},expression:"showBarGraphDialog"}}),_c('tr',{staticClass:"blue lighten-2"},[_c('td',{attrs:{"width":"200"}}),_c('td',{attrs:{"width":"120"}},[_vm._v("Obj")]),_vm._l((_vm.dates),function(date){return _c('td',{key:date},[_vm._v(" "+_vm._s(_vm._f("toDate")(date))+" ")])})],2),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.incStmtAttPerDateFn('revenue'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openGraphDialog(
            'Chiffre d\'affaire',
            'revenue',
            true,
            _vm.stock.incomeStatement
          )}}},[_vm._v("Revenue (M) ")])]},proxy:true},{key:"rowTarget",fn:function(){return undefined},proxy:true}])}),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.incStmtAttPerDateFn('netIncome'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openGraphDialog(
            'Bénéfice',
            'netIncome',
            true,
            _vm.stock.incomeStatement
          )}}},[_vm._v(" Net income (M) ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("> 0")]},proxy:true}])}),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('grossMargin'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openBarGraphDialog(
            'Marge brute',
            'grossMargin',
            _vm.stock.fundamentalIndicators
          )}}},[_vm._v(" Gross margin ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v(" > 40% ")]},proxy:true}])}),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('operatingMargin'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openBarGraphDialog(
            'Marge opérationnelle',
            'operatingMargin',
            _vm.stock.fundamentalIndicators
          )}}},[_vm._v(" Operating margin ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("> 10%")]},proxy:true}])}),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('netMargin'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openBarGraphDialog(
            'Marge nette',
            'netMargin',
            _vm.stock.fundamentalIndicators
          )}}},[_vm._v(" Net margin ")])]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("> 5%")]},proxy:true}])}),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('sgaRatio'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openBarGraphDialog(
            'Capacity to attract new customers',
            'sgaRatio',
            _vm.stock.fundamentalIndicators
          )}}},[_c('tooltip-comment',{attrs:{"label":"Capacity to attract new customers"}},[_vm._v(" Si > à 100% alors l'entreprise doit rogner sur sa marge pour attirer de nouveaux clients = compliqué dans la durée. (SGA expenses / Gross profit) ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("< 30%")]},proxy:true}])}),_c('data-per-date',{attrs:{"dates":_vm.dates,"dateDataFn":_vm.fundAttPerDateFn('rdRatio'),"applyColorClass":false},scopedSlots:_vm._u([{key:"rowTitle",fn:function(){return [_c('span',{on:{"click":function($event){return _vm.openBarGraphDialog(
            'R&D cost',
            'rdRatio',
            _vm.stock.fundamentalIndicators
          )}}},[_c('tooltip-comment',{attrs:{"label":"R&D cost"}},[_vm._v(" Si > à 30% alors attention, il faudra connaître le secteur. (R&D expenses / Gross profit) ")])],1)]},proxy:true},{key:"rowTarget",fn:function(){return [_vm._v("< 30%")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }