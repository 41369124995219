import Vue from "vue"

/**
 * Transform a string with date YYYY-MM-DD to local date
 */
Vue.filter("toDate", value => {
    if (!value) {
        return "";
    }
    let d = new Date(value);
    // console.log('toDate : in : ', value, ' cur : ', d, ' out : ', d.toLocaleDateString('fr-FR', { dateStyle: 'short' }));
    return d.toLocaleDateString('fr-FR', { dateStyle: 'short' });
});

/**
 * Transform a string with date YYYY-MM-DD to local time
 */
Vue.filter("toDateTime", value => {
    if (!value) {
        return "";
    }
    let d = new Date(value);
    return d.toLocaleDateString('fr-FR', { dateStyle: 'short' }) + ' ' + d.toLocaleTimeString('fr-FR', { timeStyle: 'short' });
});

/**
 * Transform a string with date YYYY-MM-DD to year
 */
Vue.filter("toYear", value => {
    if (!value) {
        return "";
    }
    let d = new Date(value);
    return d.getFullYear();
});

/**
 * Transform a float to localized string
 */
Vue.filter("toFloat", (value, multi = 1, suffix = "", nbDigits = 2) => {
    if (value === undefined || value === null) {
        return "";
    }
    let val = value * multi;
    val = val > 0 && val < 0.01 ? 0 : val;
    val = val < 0 && val > -0.01 ? 0 : val;
    return (
        val.toLocaleString("fr-FR", {
            minimumFractionDigits: nbDigits,
            maximumFractionDigits: nbDigits,
        }) + suffix
    );
});