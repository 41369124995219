var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.curList)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.dataTableRows,"items-per-page":-1,"hide-default-footer":true,"loading":_vm.isLoading,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('manage-stop-loss-dialog',{attrs:{"holdingParameters":_vm.currentHoldingParameters,"portfolioId":_vm.curList.id,"equities":_vm.equities},on:{"reloadPortfolio":_vm.reloadPortfolio},model:{value:(_vm.showManageStopLossDialog),callback:function ($$v) {_vm.showManageStopLossDialog=$$v},expression:"showManageStopLossDialog"}})]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'StockDetail',
        params: { uuid: item.equityUuid },
      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.lastClosePrice`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.lastClosePrice,1, " " + _vm.currencySymbol))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Max : "+_vm._s(_vm._f("toFloat")(item.maxPrice,1, " " + _vm.currencySymbol))+" ("+_vm._s(_vm._f("toDate")(item.maxPriceDate))+") ")])])]}},{key:`item.lastClosePMPercent`,fn:function({ item }){return [_c('span',{class:_vm.getClass(item.lastClosePMPercent)},[_vm._v(_vm._s(_vm._f("toFloat")(item.lastClosePMPercent,100, " %")))])]}},{key:`item.ucp`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.ucp,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.intrinsicValue`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("toFloat")(item.intrinsicValue,1, " " + _vm.currencySymbol))+" ")]}},{key:`item.diffIV`,fn:function({ item }){return [_c('span',{class:_vm.getClass(item.diffIV)},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.diffIV,100, " %"))+" ")])]}},{key:`item.fundamentalScore`,fn:function({ item }){return [(item.fundamentalScore)?_c('div',{class:_vm.getDecreasingClass(item.fundamentalScore, 50, 60)},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.fundamentalScore,1, " %", 0))+" ")]):_vm._e()]}},{key:`item.valuationQuote`,fn:function({ item }){return [_c('div',{class:_vm.getDecreasingClass(item.valuationQuote, 3, 4)},[_vm._v(" "+_vm._s(item.valuationQuote)+"/6 ")])]}},{key:`item.stopLoss`,fn:function({ item }){return [(
        item.holdingParameters &&
        item.holdingParameters.stopLossMode === 'manual'
      )?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-gesture-tap")]),_c('span',{class:_vm.getIncreasingClass(item.holdingParameters.stopLossDiff, 0, 0.05)},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.holdingParameters.stopLossDiff,100, "%")))]),_vm._v(" pour atteindre "+_vm._s(_vm._f("toFloat")(item.holdingParameters.stopLoss,1, " " + _vm.currencySymbol))+" ")],1):_vm._e(),(
        item.holdingParameters &&
        item.holdingParameters.stopLossMode === 'auto'
      )?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-auto-fix")]),_c('span',{class:_vm.getDecreasingClass(
            item.holdingParameters.stopLossAutoMaxPriceDiff,
            -0.01 * item.holdingParameters.stopLossAutoRatio,
            -0.009 * item.holdingParameters.stopLossAutoRatio
          )},[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.holdingParameters.stopLossAutoMaxPriceDiff,100, "%"))+" ")]),_vm._v(" p/r "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("toFloat")(item.holdingParameters.stopLossAutoMaxPrice,1, " " + _vm.currencySymbol))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Le "+_vm._s(_vm._f("toDate")(item.holdingParameters.stopLossAutoMaxPriceDate))+" ")])]),(item.holdingParameters.stopLossMode === 'auto')?_c('i',{staticClass:"grey--text text--lighten-2 caption"},[_vm._v(" (SL: "+_vm._s(_vm._f("toFloat")(item.holdingParameters.stopLoss,1, _vm.currencySymbol))+") ")]):_vm._e()],1):_vm._e(),_c('a',{staticClass:"float-right",on:{"click":function($event){_vm.setCurrentHoldingParameters(item);
        _vm.showManageStopLossDialog = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),(item.holdingParameters && item.holdingParameters.stopLossComment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"float-right"},'span',attrs,false),on),[_vm._v("   "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.holdingParameters.stopLossComment)}})]):_vm._e()]}}],null,true)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }