<template>
  <v-container>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      outlined
      timeout="2000"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-row v-if="ivDto" dense>
      <!-- --------------------------------------------------------------------------------------------- -->
      <!-- Colonne des métriques PER & EV/EBITDA -->
      <v-col cols="4">
        <v-row dense>
          <v-col cols="1">
            <v-icon @click="showValuationScoreDialog = true">
              mdi-information-outline
            </v-icon>
            <valuation-score-dialog v-model="showValuationScoreDialog">
            </valuation-score-dialog>
          </v-col>
          <v-col cols="11">
            <h2>Valuation indicators</h2>
          </v-col>
          <v-col cols="6">
            <!-- PER --------------------------------------------->
            <v-card outlined>
              <v-card-title>
                <span
                  title="Inférieur à son PER historique"
                  :class="
                    getBetweenClass(
                      0,
                      historicalPE,
                      stock.currentRatios.peTTM,
                      'green px-3',
                      'red px-3'
                    )
                  "
                >
                  PER
                </span>
              </v-card-title>
              <v-card-text>
                <v-expansion-panels accordion dark>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col>
                          <div class="text-h5 text-center">
                            {{ stock.currentRatios.peTTM }}
                            <span
                              class="text-subtitle-1 grey--text"
                              title="Industry PE"
                            >
                              ({{ stock.currentRatios.peTTM_industry }})
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        v-model="historicalPE"
                        label="Historical PE (from ZB)"
                        tpe="number"
                      ></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- EV/EBITDA --------------------------------------------->
            <v-card outlined>
              <v-card-title>
                <span
                  title="Inférieur à son EV/EBITDA historique"
                  :class="
                    getBetweenClass(
                      0,
                      historicalEvToEbitda,
                      stock.myCustomIndicators.evToEbitda,
                      'green px-3',
                      'red px-3'
                    )
                  "
                >
                  EV / EBITDA
                </span>
              </v-card-title>
              <v-card-text>
                <v-expansion-panels accordion dark>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col>
                          <div class="text-h5 text-center">
                            {{ stock.myCustomIndicators.evToEbitda | toFloat }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        v-model="historicalEvToEbitda"
                        label="Historical EV to EBITDA (from ZB)"
                        tpe="number"
                      ></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- PEG --------------------------------------------->
            <v-card outlined>
              <v-card-title>
                <span
                  title="Entre 0 et 2"
                  :class="
                    getBetweenClass(
                      0,
                      2,
                      stock.myCustomIndicators.peg,
                      'green px-3',
                      'red px-3'
                    )
                  "
                >
                  PEG
                </span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="text-h5 text-center">
                      {{ stock.myCustomIndicators.peg | toFloat }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- P/B --------------------------------------------->
            <v-card outlined>
              <v-card-title>
                <span
                  title="&lt; à 2"
                  :class="
                    getBetweenClass(
                      0,
                      2,
                      stock.currentRatios.priceToBookMRQ,
                      'green px-3',
                      'red px-3'
                    )
                  "
                >
                  P/B
                </span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="text-h5 text-center">
                      {{ stock.currentRatios.priceToBookMRQ | toFloat }}
                      <span
                        class="text-subtitle-1 grey--text"
                        title="Industry PE"
                      >
                        ({{ stock.currentRatios.priceToBookMRQ_industry }})
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <!-- P/CF --------------------------------------------->
            <v-card outlined>
              <v-card-title>
                <span
                  title="&lt; à 7"
                  :class="
                    getBetweenClass(
                      0,
                      7,
                      stock.currentRatios.priceToCashFlowMRQ,
                      'green px-3',
                      'red px-3'
                    )
                  "
                >
                  P/CF
                </span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="text-h5 text-center">
                      {{ stock.currentRatios.priceToCashFlowMRQ | toFloat }}
                      <span
                        class="text-subtitle-1 grey--text"
                        title="Industry PE"
                      >
                        ({{ stock.currentRatios.priceToCashFlowMRQ_industry }})
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Colonne du calcul auto de l'IV -->
      <v-col cols="4">
        <v-row dense>
          <v-col cols="12">
            <h2>Calculette de valeur intrinsèque</h2>
          </v-col>
          <v-col cols="12">
            <iv-calculator
              :uuid="uuid"
              :currentPrice="stock.lastPrice.price"
              :currency="stock.currency"
            ></iv-calculator>
          </v-col>
        </v-row>
      </v-col>
      <!-- Colonne de saisie de l'IV -->
      <v-col cols="4">
        <v-row dense>
          <v-col cols="12">
            <h2>Valeur intrinsèque de MorningStar</h2>
          </v-col>

          <v-col cols="10">
            <!-- Intrinsic value to consider-->
            <v-card outlined>
              <v-list>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      Dernière valeur
                      <span v-if="ivDto.intrinsicValueLastUpdate">
                        mise à jour le
                        {{ ivDto.intrinsicValueLastUpdate | toDate }}
                      </span>
                      <v-btn
                        class="ma-2"
                        icon
                        color="blue lighten-2"
                        title="Forcer la mise à jour"
                        @click="refreshIntrinsicValue"
                        v-if="!syncInProgress"
                      >
                        <v-icon> mdi-refresh </v-icon>
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        icon
                        v-if="syncInProgress"
                        color="blue lighten-2"
                      >
                        <v-progress-circular
                          indeterminate
                          :width="2"
                          :size="20"
                        ></v-progress-circular>
                      </v-btn>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ ivDto.intrinsicValue | toFloat(1, " €") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-chip
              large
              :class="
                getClassComplete(
                  0.000001,
                  ivDto.diffIntrinsicValue,
                  'success ma-2',
                  'error -ma-2'
                )
              "
            >
              {{ ivDto.diffIntrinsicValue | toFloat(100, " %") }}
            </v-chip>
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col>
                    <!-- Comment field -->

                    <v-tiptap
                      v-model="valuationComment"
                      :dark="dark"
                      :toolbar="[
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'color',
                        '|',
                        'h1',
                        'h2',
                        'h3',
                        '|',
                        'emoji',
                      ]"
                      dense="true"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card> </v-col
        ></v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import ValuationScoreDialog from "@/components/stock-detail/valuation/ValuationScoreDialog.vue";
import IvCalculator from "@/components/stock-detail/valuation/IvCalculator.vue";

export default {
  name: "ValuationTab",
  props: ["uuid", "refresh"],
  watch: {
    uuid: function () {
      this.initTabForCurrentStock();
    },
    refresh: function () {
      this.initTabForCurrentStock();
    },

    historicalPE: _.debounce(function () {
      this.updateHistoricalPE();
    }, 1000),
    historicalEvToEbitda: _.debounce(function () {
      this.updateHistoricalEvToEbitda();
    }, 1000),
    valuationComment: _.debounce(function () {
      this.updateValuationComment();
    }, 1000),
  },
  data: function () {
    return {
      dark: true,
      valuationComment: null,
      ivDto: undefined,
      historicalPE: null,
      historicalEvToEbitda: null,
      showValuationScoreDialog: false,
      syncInProgress: false,
      showSnackbar: false,
      snackbarText: undefined,
      snackbarColor: "success",
      stock: null,
    };
  },
  computed: {},
  methods: {
    getClassComplete(val1, val2, lessThanClass, greaterThanClass) {
      if (!val1 || !val2) {
        return;
      }
      if (val1 <= val2) {
        return lessThanClass;
      } else {
        return greaterThanClass;
      }
    },
    getBetweenClass(valMin, valMax, val, betweenClass, outsideClass) {
      if (!valMax || !val) {
        return;
      }
      if (val < valMin) {
        return outsideClass;
      } else if (val > valMax) {
        return outsideClass;
      } else {
        return betweenClass;
      }
    },

    updateHistoricalPE: function () {
      // Avoid to save if it doesn't change
      if (
        this.stock.myCustomIndicators.historicalPe &&
        this.stock.myCustomIndicators.historicalPe === this.historicalPE
      ) {
        console.log("Not necessary to send historicalPE to backend");
        return;
      }
      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/historical-pe", {
          newHistoricalPE: this.historicalPE,
        })
        .then(
          () => {
            // console.log("updateHistoricalPE : OK !");
            this.$emit("reloadEquity");
          },
          (errorResponse) => {
            console.warn("updateHistoricalPE Err : ", errorResponse);
          }
        );
    },
    updateHistoricalEvToEbitda: function () {
      // Avoid to save if it doesn't change
      if (
        this.stock.myCustomIndicators.historicalEvToEbitda &&
        this.stock.myCustomIndicators.historicalEvToEbitda ===
          this.historicalEvToEbitda
      ) {
        console.log("Not necessary to send historicalEvToEbitda to backend");
        return;
      }
      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/historical-ev-ebitda", {
          newHistoricalEvToEbitda: this.historicalEvToEbitda,
        })
        .then(
          () => {
            // console.log("updateHistoricalEvToEbitda : OK !");
            this.$emit("reloadEquity");
          },
          (errorResponse) => {
            console.warn("updateHistoricalEvToEbitda Err : ", errorResponse);
          }
        );
    },

    refreshIntrinsicValue: function () {
      if (this.syncInProgress) {
        return;
      }

      this.syncInProgress = true;

      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/intrinsic-value/refresh")
        .then(
          (response) => {
            // console.log("updateIntrinsicValue : OK !");
            this.ivDto = response.data;
            this.valuationComment = this.ivDto.comment;
            this.intrinsicValue = this.ivDto.intrinsicValue;
            this.snackbarText = "Valeur intrinsèque mise à jour !";
            this.showSnackbar = true;
            this.snackbarColor = "success";
          },
          (error) => {
            console.log("Error refreshing IV : ", error);
            this.snackbarText = error.response.data;
            this.showSnackbar = true;
            this.snackbarColor = "error";
          }
        )
        .finally(() => {
          this.syncInProgress = false;
        });
    },
    updateValuationComment: function () {
      // Avoid to save if it doesn't change
      if (
        this.stock.myCustomIndicators.comment &&
        this.stock.myCustomIndicators.comment === this.valuationComment
      ) {
        console.log("Not necessary to send valuation comment to backend");
        return;
      }
      // @apiNote StockAPI
      this.$http
        .post("stocks/" + this.stock.id + "/valuationcomment", {
          newValuationComment: this.valuationComment,
        })
        .then(() => {
          // console.log("updateValuationComment : OK !");
        });
    },
    getIntrinsicValue() {
      // @apiNote StockAPI
      this.$http
        .get("stocks/" + this.stock.id + "/intrinsic-value")
        .then((response) => {
          // console.log("getIntrinsicValue : OK !");
          this.ivDto = response.data;
          this.estimatedGrowthRate = this.ivDto.estimatedGrowthRate;
          this.intrinsicValue = this.ivDto.intrinsicValue;
          this.valuationComment = this.ivDto.comment;
        });
    },

    initHistoricalPE() {
      this.historicalPE = this.stock.myCustomIndicators.historicalPe
        ? this.stock.myCustomIndicators.historicalPe
        : null;
    },
    initHistoricalEvToEbitda() {
      this.historicalEvToEbitda = this.stock.myCustomIndicators
        .historicalEvToEbitda
        ? this.stock.myCustomIndicators.historicalEvToEbitda
        : null;
    },
    initTabForCurrentStock() {
      // @apiNote StockAPI
      this.$http.get("stocks/" + this.uuid).then((response) => {
        this.stock = response.data;
        document.title = this.stock.name;

        this.initHistoricalPE();
        this.initHistoricalEvToEbitda();
        this.getIntrinsicValue();
      });
    },
  },

  mounted() {
    this.initTabForCurrentStock();
  },
  components: {
    ValuationScoreDialog,

    IvCalculator,
  },
};
</script>

<style lang="scss" scoped>
// To get complete list of available variables https://github.com/vuetifyjs/vuetify/issues/11783
::v-deep .theme--dark.v-btn.v-btn--icon {
  color: var(--v-secondary-lighten3);
}
</style>
